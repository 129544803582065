import React, {useEffect} from 'react'
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import { Card, CardMedia, CardContent, Typography, Grid } from '@material-ui/core';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import ImageSliderComponent from './ImageSliderComponent';
import { height } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function PosterCardLarge(props) {
  const isMobileView = useMediaQuery('(max-width:500px)');
  const isTabletView = useMediaQuery('(min-width:900px)');



  return (
    <div className='image-slider'>
              <Card style={{ 

                width: isMobileView ? '100vw':isTabletView?"38vw":"32vw",
                marginLeft: isMobileView ? '0vw':"240px",
                border: 'none',        // Added this line
                boxShadow: 'none', 

                
                }} >
              <CardContent>
              <ImageSliderComponent
              
                  imageDisplayUrls={props.imageDisplayUrls}
                />

                <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                </FormControl>
              </Box>
              </CardContent>


            </Card>
    </div>
  )
}
