import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/ImageSliderComponent.css';
import useMediaQuery from '@mui/material/useMediaQuery';


const ImageSliderComponent = (props) => {

  const isMobileView = useMediaQuery('(max-width:600px)');
  const isTabletView = useMediaQuery('(min-width:900px)');

  const handleContextMenu = (event) => {
    event.preventDefault(); // prevent the default context menu from appearing
  };
  
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  const [settingsFor, setSettingsFor] = useState(null);
  const [settingsNav, setSettingsNav] = useState(null);

  useEffect(() => {
    
    const settingsForTemp = {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,

      fade: true,
      centerMode: true,
      asNavFor: slider2.current
    };
    const settingsNavTemp = {
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: slider1.current,
      dots: true,
      centerMode: true,
      focusOnSelect: true,
      slideMargin: 10, // Adjust this value to adjust the space between navbar images
      position: "relative",
      left: "0px"
    };

    setSettingsFor(settingsForTemp)
    setSettingsNav(settingsNavTemp)

}, [slider1, slider2]);


  const mainImageHeight = isMobileView?420 : isTabletView? 420:420; 
  const navImageHeight = isMobileView?100 : isTabletView? 75:100; 


  const images =[
    {"signedUrl":props.imageDisplayUrls[0].signedUrl},
    {"signedUrl":props.imageDisplayUrls[0].imageWallUrls[0]},
    {"signedUrl":props.imageDisplayUrls[0].imageWallUrls[1]}
  ];
  return (
    <div className="image-slider-container">
    <div className="image-slider-wrapper"
    
    style={{
      position: "relative",
      top: isTabletView?"5vh":"0px"
    }}
    >

      <Slider {...settingsFor} ref={slider1}>
        {images.map((imageData, index) => (
          <div key={index} id="imageMainDiv" style={{width: '100%', height: '100%'}}>
            <img id="imageMain" 
                src={imageData.signedUrl} 
                style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: 'contain',
                }}
                alt={`Slide ${index + 1}`} 
                onContextMenu={handleContextMenu} 
            />
        </div>

        ))}
      </Slider>
      <Slider
      style={{

      }}
      {...settingsNav} ref={slider2}>
        {images.map((imageData, index) => (
          <div key={index}
          >
            <img id="imageNavbar" src={imageData.signedUrl} alt={`Slide ${index + 1}`} onContextMenu={handleContextMenu}
                 style={{
                   height: `${navImageHeight}px`,
                   objectFit: 'cover',
                 }} />
          </div>
        ))}
      </Slider>
    </div>
    </div>
  );
};

export default ImageSliderComponent;

