import React from 'react'
import { Container, Typography, makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '60vw',
    margin: '0 auto',
    [theme.breakpoints.down(600)]: {
      maxWidth: '100vw',
    },
  },
}));


export default function DeliveryInformation() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Typography
      style={{
        marginBottom: "50px"
      }}
      variant="h4" align="center" gutterBottom>
        Frakt och Leverans
      </Typography>
      <Typography variant="body1" paragraph>
        Leveranstiden för beställningar från Postertopia är vanligtvis 1-3 arbetsdagar från beställningsdatumet. Vårt mål är att skicka dina produkter senast dagen efter att du har lagt din beställning. Observera att en fördröjning på 1-2 dagar kan uppstå under perioder med hög volym eller när ett stort antal beställningar har mottagits.
      </Typography>
      <Typography variant="body1" paragraph>
        Om din beställning inte anländer inom den utlovade leveranstiden, dubbelkolla gärna informationen du angav vid beställningen. Om det är korrekt, vänligen kontakta vår kundtjänst inom 30 arbetsdagar från beställningsdatumet, och vi hjälper dig.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Vilka leveransalternativ erbjuder vi?
      </Typography>
      <Typography variant="body1" paragraph>
        Vi använder olika leveransbolag beroende på storleken på din beställning. När du har lagt dina produkter i din kundkorg och gått till kassan, kan du se vilket bolag din beställning levereras med.
      </Typography>
      <Typography variant="body1" paragraph>
        Fraktkostnaden läggs till på beställningar under 349 kr, vilket blir 49 kr. Om din beställning är 349 kr eller mer är frakten gratis.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Hur kan jag spåra mitt paket?
      </Typography>
      <Typography variant="body1" paragraph>
        Din beställning kommer att packas och skickas så snart som möjligt. Så snart din beställning har hämtats av leveransbolaget, aktiverar vi ditt spårningsnummer, och du får en e-postbekräftelse som innehåller din spårningslänk. Om du inte får din spårningslänk, vänligen kontakta oss via vårt kontaktformulär.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Vad händer om jag inte hämtar ut mitt paket?
      </Typography>
      <Typography variant="body1" paragraph>
        För varor som returneras till oss på grund av oouthämtade paket, tar vi ut en avgift på 180 kr. Denna avgift täcker de dubbla fraktkostnaderna samt kostnaden för packning och uppackning av varor.
      </Typography>
    </Container>
  )
}
