import React from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '60vw',
    margin: '0 auto',
    [theme.breakpoints.down(600)]: {
      maxWidth: '100vw',
    },
  },
}));


export default function IntegrityPolicy() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Typography
            style={{
              marginBottom: "50px",
            }}
      variant="h4" align="center" gutterBottom>
        Integritetspolicy för Postertopia
      </Typography>
      <Typography paragraph>
        Din personliga integritet är viktig för Postertopia. I denna policy vill vi informera dig om hur vi behandlar dina personuppgifter och rättigheter. Personuppgifter är information som kan bli sammankopplad med dig som privatperson, till exempel ditt namn och din kontaktinformation.
      </Typography>
      <Typography paragraph>
        Postertopia samlar inte in eller lagrar någon personlig information. Vår webbplats kan besökas anonymt, och vi sparar inte några specifika uppgifter om våra besökare. Vårt huvudsyfte är att tillhandahålla en säker och effektiv online-upplevelse utan att samla in eller lagra personlig information.
      </Typography>
      <Typography paragraph>
        Detta betyder att vi inte har några personuppgifter att dela, sälja, leasa eller på annat sätt tillhandahålla till tredje part. Vi samlar inte in personuppgifter om dig när du besöker vår webbplats, såvida du inte själv väljer att tillhandahålla dessa uppgifter till oss.
      </Typography>
      <Typography paragraph>
        Om du har några frågor angående vår Integritetspolicy, vänligen kontakta oss.
      </Typography>
    </Container>
  );
}
