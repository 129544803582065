import React, { useState } from 'react';
import {
    Snackbar,
    Button,
    SnackbarContent,
    Typography,
    IconButton,
    Box
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import CookieIcon from '@mui/icons-material/Cookie'; // Import the cookie icon
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles({
    snackbarContent: {
        backgroundColor: 'rgba(0,0,0,0.9)',
        color: 'white',
        justifyContent: 'center',
        borderRadius: '5px',
        position: 'fixed',
    },
    settingsButton: {
        border: '1px solid rgba(255,255,255,0.7)', 
        backgroundColor: 'rgba(0,0,0,0.2)',  
        color: 'white',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.1)', 
        }
    },
    acceptButton: {
        backgroundColor: 'rgba(255,255,255,0.9)',
        color: 'black',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.7)',
        }
    }
});

const CookieSnackbar = ({ cookieConsentGiven, setCookieConsentGiven, setIsConsentDialogOpen,setAnalyticsConsent, analyticsConsent,openCookieBar,setOpenCookieBar }) => {
    const classes = useStyles();
    const [showConsentDialog, setShowConsentDialog] = useState(false);
    const isMobileView = useMediaQuery('(max-width:800px)');
    
    const handleAcceptAll = () => {
        localStorage.setItem('cookieConsentGiven', 'true');
        localStorage.setItem('cookieAnalyticsConsent', analyticsConsent)

        setCookieConsentGiven(true);
        setAnalyticsConsent(analyticsConsent)
        console.log('openCookieBar',openCookieBar)
        console.log('cookieConsentGiven',cookieConsentGiven)
        console.log(openCookieBar)
        setOpenCookieBar(false);
        console.log(openCookieBar)
    };

    const handleSettings = () => {
        setIsConsentDialogOpen(true);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                open={cookieConsentGiven===false}
            >
                <SnackbarContent
                    className={classes.snackbarContent}
                    style={{
                        top: isMobileView ? '65%' : '75%',
                        width: isMobileView ? '80vw' : '60vw',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        padding: '16px',
                    }}
                    message={
                        <Box display="flex" flexDirection="column" alignItems="center"
                        style={{
                        }}
                        > {/* Adjusted the alignment here */}
                            <Typography variant="h6" component="div" style={{ display: 'flex', alignItems: 'center', position:'relative' }}>
                                <CookieIcon style={{ marginRight: '8px' }} />  {/* Cookie icon */}
                                Cookies
                            </Typography>
                            <Typography variant="body2" style={{ marginTop: '8px', textAlign: 'center' }}>
                                Postertopia använder cookies och liknande tekniker för att kunna förbättra din användarupplevelse. Du kan här välja att acceptera samtliga cookies eller anpassa vilka cookies du accepterar eller inte.
                            </Typography>
                        </Box>
                    }
                    action={
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            gap: '16px',
                            position: 'relative',
                            left: isMobileView ? "-5vw" : '-10vw',
                        }}>
                            <Button 
                                color="primary"
                                size="small"
                                onClick={handleSettings}
                                className={classes.settingsButton}
                                style={{ width: isMobileView ? '35vw' : '20vw', height: "50px" }}
                            >
                                INSTÄLLNINGAR
                            </Button>
                            <Button 
                                color="secondary"
                                size="small"
                                onClick={handleAcceptAll}
                                className={classes.acceptButton}
                                style={{ width: isMobileView ? '35vw' : '20vw', height: "50px" }}
                            >
                                STÄNG OCH GODKÄNN
                            </Button>
                        </div>
                    }
                />
            </Snackbar>
        </>
    );
}

export default CookieSnackbar;
