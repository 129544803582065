import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, Link, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from "react-router-dom";
import axios from 'axios';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';  // Import the checked logo
import { Card, CardContent, CardHeader } from '@material-ui/core';
import EmailIcon from '@mui/icons-material/Email';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '60vw',
    margin: '0 auto',
    [theme.breakpoints.down(600)]: {
      maxWidth: '100vw',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
  },
  submitButton: {
    marginTop: theme.spacing(2),
    backgroundColor: "black"
  },
  infoText: {
    marginTop: theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    },
    emailIcon: {
        fontSize: '7rem',  // Adjust this value to your desired size
        color: 'black',
        marginBottom: theme.spacing(2),
    },

}));


export default function ContactComponent() {
  const classes = useStyles();


  const [submitted, setSubmitted] = useState(false);   // State to manage form submission
  const [loading, setLoading] = useState(false);       // State to manage loading spinner

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const data = {
      name: event.target.name.value,
      email: event.target.email.value,
      orderNummer: event.target.orderNummer.value,
      question: event.target.question.value
    };
  
    try {
      const response = await axios.post('https://backend-photo-gallery-hfzyyicqga-lz.a.run.app/contact', data);
      console.log('Response:', response.data);
      setSubmitted(true);
      // You can add logic to handle the response here (e.g., show a success message)
    } catch (error) {
      console.error('Error sending contact data:', error);
      // Handle error appropriately (e.g., show an error message to the user)
    }
    setLoading(false);
  };
  



  return (
    <Container className={classes.root}>
      <Typography variant="h4" align="center" gutterBottom>
        KONTAKTA OSS
      </Typography>
      <Typography variant="body1" className={classes.infoText}>
        Vår kundtjänst hanterar dina ärenden måndag till fredag mellan 08:00 till 17:00. Vi strävar efter att svara inom 24 timmar på vardagar och inom 48 timmar på helger.
        <br /><br />
        FAQ: Du kan hitta svaren till våra mest ställda frågor i vår <RouterLink to="/1-vanliga-fragor">INFO</RouterLink>.
        <br /><br />
        AI CHATT: Vår AI Chat är alltid tillgänglig för att svara på vanliga frågor och ge dig råd om vilken poster-stil som passar bäst för dig.
      </Typography>
  
      {submitted ? (
        <Card>
            <CardContent className={classes.cardContent}>
                <EmailIcon className={classes.emailIcon} />
                <Typography variant="h6" align="center">
                    Tack för dit meddelande, vi kommer att höra av oss så fort som möjligt.
                </Typography>
            </CardContent>
        </Card>

      ) : (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Ditt namn"
            name="name"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="orderNummer"
            label="Ordernummer"
            name="orderNummer"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="question"
            label="Din fråga"
            id="question"
            multiline
            rows={4}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submitButton}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="secondary" /> : "Skicka"}
          </Button>
        </form>
      )}
    </Container>
  );
  


}
