import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import Gallery from "./Gallery";
import {
  Switch,
  FormControlLabel,
  Drawer,
  Divider,
  IconButton,
  Button,
  Grid
} from "@material-ui/core";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import SyncIcon from "@mui/icons-material/Sync";
import ColorFilter from "./ColorFilter";
import ShapeFilter from "./ShapeFilter";
import SizeFilter from "./SizeFilter";
import SortingFilter from "./SortingFilter";
import FilterListIcon from '@mui/icons-material/FilterList';
import TuneIcon from '@mui/icons-material/Tune';
import CustomOverlayDrawer from "./CustomOverlayDrawer";
import { useInView } from "react-intersection-observer";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { motion } from 'framer-motion';
import SubSectionsCard from '../SubSectionsCard';
import BottomSection from '../BottomSection';
import BottomSectionMobile from "../BottomSectionMobile";
import Countdown from "../Countdown";
import { Link } from 'react-router-dom';

import ImageTheme1 from '../images/dropdown_image.png'
import ImageThemeFantasy from '../images/theme_fantasy.png'
import ImageThemePopArt from '../images/theme_popart.png'
import ImageThemePhoto from '../images/theme_photo.png'
import ImageThemeFuture from '../images/theme_future.png'
import ImageThemeBohem from '../images/theme_bohem.png'
import ImageThemeHumans from '../images/theme_humans.png'
import ImageThemeAbstract from '../images/theme_abstract.png'
import ImageThemeAnimal from '../images/theme_animal.png'
import ImageThemeNature from '../images/theme_nature.png'
import ImageThemeNews from '../images/theme_news.png'
import ImageThemeTop from '../images/theme_topp.png'
import ImageThemePaket from '../images/theme_paket.png'
import ImageThemeViechle from '../images/theme_viechle.png'
import ImageThemeSale from '../images/theme_sale.png'
import ImageThemeIllustration from '../images/theme_illustration.png'
import ContactComponent from "../link_components/ContactComponent";
import CommonQuestions from "../link_components/CommonQuestions";
import PurchaseConditions from "../link_components/PurchaseConditions";
import DeliveryInformation from "../link_components/DeliveryInformation";
import ThankYou from "../link_components/ThankYou";
import Cookiepolicy from "../link_components/Cookiepolicy";
import IntegrityPolicy from "../link_components/IntegrityPolicy";
import AboutUs from "../link_components/AboutUs";
import PosterInfo from "../link_components/PosterInfo";
import PageUnderConstruction from "../link_components/PageUnderConstruction";
import { useMediaQuery } from '@material-ui/core';
import LoadingOverlay from "./LoadingOverlay";
import PaymentFailed from "../link_components/PaymentFailed";
import { maxWidth } from "@mui/system";

gsap.registerPlugin(ScrollTrigger);

const GalleryPage = ({ signedUrls, setCookieConsentGiven, loadCount, setLoadCount, setImageDisplayUrls,discountPercentage, isMobileView,isTabletView,previousLocation, scrollPosition, setScrollPosition, currentLocation, setFavoritePoster, favoritePoster, onImageClick, searchValue, imageDisplayUrl, imageDisplayUrls, searchResult, setOpenSidebar,setOpenCookieBar }) => {
  const fixedIconsRef = useRef(null);
  const filterButtonRef = useRef(null);
  const sortButtonRef = useRef(null);
  const [showFixedIcons, setShowFixedIcons] = useState(false);
  const { style, category, imageId } = useParams();
  const [toggleState, setToggleState] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerMode, setDrawerMode] = useState("filter");
  const elementRef = useRef(null);
  const [displayFixedButtons, setDisplayFixedButtons] = useState(true)
  const buttonRef = useRef(null);
  const boxRef = useRef(null);
  const isMobileView2 = useMediaQuery('(max-width:790px)');
  const isTabletView2 = useMediaQuery('(max-width:810px)');
  const [displayButtons, setDisplayButtons] = useState("visible")
  const [showComponent, setShowComponent] = useState(() => ContactComponent);
  const [showComponents, setShowComponents] = useState(false);

  const handleScrollPosition = () => {
    // Store current scroll position in the parent's state

    const currentScrollPos = window.scrollY;
    console.log(currentScrollPos)
    setScrollPosition(currentScrollPos);
  
  };

  // useEffect(() => {
  //   if(scrollPosition!==0){}
  // }, []);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: buttonRef.current,
      start: 'top 100px',
      end: 'top -600px', // fires when the bottom of the button is at the top of the viewport

      onEnter: () => {
        
        setDisplayFixedButtons(false)
        
      },
      onLeave: () => {
        
        setDisplayFixedButtons(true)
        
      },
      onEnterBack: () => {
        
        setDisplayFixedButtons(false)
        
      },
      onLeaveBack: () => {
        
        setDisplayFixedButtons(true)
        
      },
    });
    

    gsap.from(boxRef.current, {
      autoAlpha: 0,
      y: 50,
      ease: 'none',
      scrollTrigger: {
        trigger: buttonRef.current,
        start: 'top center',
        toggleActions: 'play none none reverse',
      },
    });
  }, []);


  const [visible, setVisible] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0,
  });


  
  const [openFrameMenu, setOpenFrameMenu] = useState(false);

  const toggleDrawerFrame = () => {
    setOpenFrameMenu(!openFrameMenu);
  };


  const checkScrollPosition = () => {
    if (elementRef.current) {
      const elementTop = elementRef.current.getBoundingClientRect().top;
      
      // If the elementTop is less than or equal to 0, it means the element has been scrolled past the top of the viewport.
      if (elementTop <= 0) {
        
      } else {
        
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            setShowFixedIcons(true);
            
          } else {
            setShowFixedIcons(false);
            
          }
        });
      },
      { threshold: 0 }
    );
  
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
  
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);
  
  const handleToggleChange = () => {
    setToggleState(!toggleState);
  };

  const openDrawer = (mode) => {
    setDrawerMode(mode);
    setDrawerOpen(true);
    
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  const [selectedSortOn, setSelectedSortOn] = useState("")

  const [displayThemeData, setDisplayThemeData] = useState(
    {

      title: "Topplista",
      image: ImageTheme1,
      category: '',
      text: "posters för alla sorter"
    }
  )



  useEffect(()=>{
    
    if (style==="rabatt"){
      setDisplayThemeData(
        {

          title: "Rabatt",
          image: ImageThemeSale,
          category: category,
          text: "Utforska vår rabattförsäljning på alla sorters posters! Oavsett om du är intresserad av abstrakta illustrationer av gorillor, fotorealistiska skildringar av bilar eller bohemiska fantasibilder, har vi något för dig."
        }
      )
    }
    if (style==="abstract"){
      setDisplayThemeData(
        {

          title: "Abstract",
          image: ImageThemeAbstract,
          category: category,
          text: "Abstrakt konst bjuder in till tolkning och uppmuntrar till en djupare förståelse. Varje abstrakt verk kan presentera geometriska former, slumpmässiga penseldrag, och mycket mer."
        }
      )
    }
    if (style==="bohemian"){
      setDisplayThemeData(
        {

          title: "Bohemiskt",
          image: ImageThemeBohem,
          category: category,
          text: "Bohemian-posters är karaktäristiska för sin livliga och ofta eklektiska design. De inkluderar ofta naturliga element, etniska mönster, vintage-inspirerade tryck och levande färger."
        }
      )
    }
    if (style==="futuristic"){
      setDisplayThemeData(
        {

          title: "Framtid",
          image: ImageThemeFuture,
          category: category,
          text: "Futuristiska posters erbjuder en blick in i framtidens värld, med sofistikerade teknologiska innovationer, avantgardiska arkitektoniska strukturer och ofta en titt på det okända. "
        }
      )
    }

    if (style==="illustration"){
      setDisplayThemeData(
        {

          title: "Illustration",
          image: ImageThemeIllustration,
          category: category,
          text: "Illustrationsposters är mycket varierande, från söta och lekfulla bilder till sofistikerade skisser och teckningar. De kan visa allt från vardagsscener, vackra landskap, porträtt till abstrakta motiv."
        }
      )
    }

    if (style==="animals-1"){
      setDisplayThemeData(
        {

          title: "Djur",
          image: ImageThemeAnimal,
          category: category,
          text: "Fantasy-posters tar betraktaren till en annan värld full av mytiska varelser, magiska landskap, hjältar och äventyr."
        }
      )
    }

    if (style==="humans-1"){
      setDisplayThemeData(
        {

          title: "Människor",
          image: ImageThemeHumans,
          category: category,
          text: "Fantasy-posters tar betraktaren till en annan värld full av mytiska varelser, magiska landskap, hjältar och äventyr."
        }
      )
    }
    if (style==="vehicle-1"){
      setDisplayThemeData(
        {

          title: "Fordon",
          image: ImageThemeViechle,
          category: category,
          text: "Fantasy-posters tar betraktaren till en annan värld full av mytiska varelser, magiska landskap, hjältar och äventyr."
        }
      )
    }
    if (style==="posterpaket"){
      setDisplayThemeData(
        {

          title: "Posterpaket",
          image: ImageThemePaket,
          category: category,
          text: "Fantasy-posters tar betraktaren till en annan värld full av mytiska varelser, magiska landskap, hjältar och äventyr."
        }
      )
    }
    if (style==="topplista"){
      setDisplayThemeData(
        {

          title: "Topplista",
          image: ImageThemeTop,
          category: category,
          text: "Topplista-posters representerar de mest älskade och populära designerna. Dessa posters är valda av vår community för deras enastående kreativitet, skönhet och förmåga att engagera och inspirera."
        }
      )
    }
    if (style==="nyheter"){
      setDisplayThemeData(
        {

          title: "Nyheter",
          image: ImageThemeNews,
          category: category,
          text: "Utforska de senaste tillskotten i vår samling med Nyheter-posters. Här finner du de nyaste trenderna, färska konstnärliga uttryck och innovativa designidéer som precis har landat i vår butik."
        }
      )
    }
    if (style==="nature-1"){
      setDisplayThemeData(
        {

          title: "Natur",
          image: ImageThemeNature,
          category: category,
          text: "Denna naturposter kollektion representerar en mångfald av landskap, element och scener som vår vackra planet har att erbjuda. Presenterad i alla unika stilar som Postertopia tillhandahåller, varje bild erbjuder en ny synvinkel på den naturliga världen och fångar dess skönhet och mångsidighet."
        }
      )
    }
    if (style==="fantasy"){
      setDisplayThemeData(
        {

          title: "Fantasy",
          image: ImageThemeFantasy,
          category: category,
          text: "Fantasy-posters tar betraktaren till en annan värld full av mytiska varelser, magiska landskap, hjältar och äventyr."
        }
      )
    }
    if (style==="pop-art"){
      setDisplayThemeData(
        {

          title: "Pop-Art",
          image: ImageThemePopArt,
          category: category,
          text: "Pop-Art-posters ger ett modigt och levande uttryck. Med färgglada och ofta kontrasterande färger, kändisporträtt, allmänna varumärken och vardagliga föremål, är dessa verk ett statement i sig."
        }
      )
    }
    if (style==="photography"){
      setDisplayThemeData(
        {

          title: "Fotografi",
          image: ImageThemePhoto,
          category: category,
          text: "Fotografi posters kan föreviga alla typer av motiv: från vackra landskap, fascinerande stadsbilder, djurliv till stilla liv och mer. "
        }
      )
    }



      else if (style === "abstract" && category === "nature") {
        setDisplayThemeData({
          title: "Abstrakt",
          image: ImageThemeAbstract,
          category: category,
          text: "Abstrakt naturkonst bjuder på ett nytt, tolkningsbart sätt att se på våra omgivningar. Varje penseldrag, varje färgton, kan föreställa en aspekt av naturen som vi inte sett förut, och det är just den mystiken som gör dessa verk så spännande."
        });
      } else if (style === "abstract" && category === "animals") {
        setDisplayThemeData({
          title: "Abstrakt",
          image: ImageThemeAbstract,
          category: category,
          text: "Djuren får nytt liv i dessa abstrakta verk, där färg och form skildrar deras essens. Varje konstverk är en djupdykning i djurets själ, en resa som skildrar dess karaktär på ett visst och ofta oväntat sätt."
        });
      } else if (style === "abstract" && category === "humans") {
        setDisplayThemeData({
          title: "Abstrakt",
          image: ImageThemeAbstract,
          category: category,
          text: "Abstrakta människoskildringar utmanar oss att se bortom det bokstavliga, och in i själva essensen av mänskligt liv och erfarenheter. Genom att bryta ner människokroppen till abstrakta former, erbjuder dessa konstverk en inre blick in i mänskliga känslor, tankar och drömmar."
        });
      } else if (style === "abstract" && category === "creatures") {
        setDisplayThemeData({
          title: "Abstrakt",
          image: ImageThemeAbstract,
          category: category,
          text: "Abstrakta varelsemotiv låter fantasin löpa fritt, med suggestiva former och färger som skapar ett mysterium kring varelsens natur. Dessa konstverk tar oss med till en fantasivärld, där mytiska varelser tar form och skapar berättelser genom sin abstrakta representation."
        });
      } else if (style === "abstract" && category === "architecture") {
        setDisplayThemeData({
          title: "Abstrakt",
          image: ImageThemeAbstract,
          category: category,
          text: "Abstrakt arkitekturkonst transformerar byggnader och strukturer till visuellt stimulerande former och mönster. Genom att förenkla och abstrahera arkitekturens linjer och former, blir dessa konstverk till en hyllning till de geometriska mönster och designprinciper som ligger till grund för vår byggda miljö."
        });
      } else if (style === "bohemian" && category === "nature") {
        setDisplayThemeData({
          title: "Bohemiskt",
          image: ImageThemeBohem,
          category: category,
          text: "Bohemiska naturposter förmedlar en känsla av frihet och vildhet, precis som naturen själv. De är präglade av livliga och ofta eklektiska design, ofta med naturliga element och levande färger."
        });
      } else if (style === "bohemian" && category === "animals") {
        setDisplayThemeData({
          title: "Bohemiskt",
          image: ImageThemeBohem,
          category: category,
          text: "Dessa bohemiska djurposters bjuder på en levande och eklektisk mix av design. Varje djur presenteras i en unik och karaktäristisk stil, som kombinerar naturliga element med djärva färger och fantasifulla mönster"
        });
      } else if (style === "bohemian" && category === "humans") {
        setDisplayThemeData({
          title: "Bohemiskt",
          image: ImageThemeBohem,
          category: category,
          text: "Bohemiska människoposter firar människans unika karaktär och självuttryck. Varje porträtt är en hyllning till individens frihet och autenticitet, ofta framhävda genom levande färger och eklektiska mönster."
        });
      } else if (style === "bohemian" && category === "creatures") {
        setDisplayThemeData({
          title: "Bohemiskt",
          image: ImageThemeBohem,
          category: category,
          text: "Bohemiska varelserskildringar tar inspiration från mystik, folklore och natur. Dessa bilder kombinerar traditionella mytiska varelser med djärva färger, eklektiska mönster och handgjorda detaljer, vilket ger varje varelse en särskild bohemisk känsla."
        });
      } else if (style === "bohemian" && category === "architecture") {
        setDisplayThemeData({
          title: "Bohemiskt",
          image: ImageThemeBohem,
          category: category,
          text: "Bohemisk arkitektur är en fusion av traditionella designelement och konstnärliga uttryck. Byggnaderna och strukturerna i dessa bilder präglas av naturliga material, lekfulla mönster och handgjorda detaljer, vilket ger varje landskap en känsla av värme och frihet."
        });
      } else if (style === "futuristic" && category === "nature") {
        setDisplayThemeData({
          title: "Framtid",
          image: ImageThemeFuture,
          category: category,
          text: "Framtidens natur avbildar en verklighet där teknologi och miljö sammansmälter. Dessa skildringar visar ofta landskap som är både bekanta och utomvärldsliga, med ljuvliga skogar som lever sida vid sida med teknologiska underverk."
        });
      } else if (style === "futuristic" && category === "animals") {
        setDisplayThemeData({
          title: "Framtid",
          image: ImageThemeFuture,
          category: category,
          text: "Framtida djurbilder föreställer hur evolutionen och teknologin kan påverka djurlivet. Från bio-mekaniska djur till genetiskt modifierade varelser, dessa avbildningar erbjuder en tankeväckande titt på vår planets möjliga framtid."
        });
      } else if (style === "futuristic" && category === "humans") {
        setDisplayThemeData({
          title: "Framtid",
          image: ImageThemeFuture,
          category: category,
          text: "Bilder av framtida människor ger en inblick i hur teknologiska framsteg kan forma mänsklig evolution, kultur och identitet. Dessa avbildningar kan omfatta allt från cybernetiska implantat till interstellära resenärer, reflekterande av människans strävan efter framsteg."
        });
      } else if (style === "futuristic" && category === "creatures") {
        setDisplayThemeData({
          title: "Framtid",
          image: ImageThemeFuture,
          category: category,
          text: "Framtidens varelser kombinerar det bekanta med det fantastiska. Med en blandning av biologi och teknologi, dessa skapelser är en vild spekulation om de sorters livsformer som kan existera i en avlägsen framtid."
        });
      } else if (style === "futuristic" && category === "architecture") {
        setDisplayThemeData({
          title: "Framtid",
          image: ImageThemeFuture,
          category: category,
          text: "Framtidens arkitektur avbildar en värld där byggnader inte bara är strukturer, utan levande, andande enheter. Genom att förena teknologi och design, dessa skildringar visar städer som är lika ekologiska som de är teknologiska, och bostäder som är integrerade med naturen på sätt vi bara kan drömma om."
        });
      } else if (style === "illustration" && category === "nature") {
        setDisplayThemeData({
          title: "Illustration",
          image: ImageThemeIllustration,
          category: category,
          text: "Illustrationer av naturen bjuder på en förskönad tolkning av vår omgivning. De varierar från detaljerade landskapsbilder till abstrakta skildringar av naturliga motiv, alla gjorda med en konstnärlig finess som ger dem liv och djup."
        });
      } else if (style === "illustration" && category === "animals") {
        setDisplayThemeData({
          title: "Illustration",
          image: ImageThemeIllustration,
          category: category,
          text: "Djurillustrationer spänner över en mängd olika stilar, från detaljerade skisser till lekfulla bilder. Varje illustration ger en unik och personlig hyllning till djurens karaktär och skönhet, ofta med en djupare symbolisk betydelse."
        });
      } else if (style === "illustration" && category === "humans") {
        setDisplayThemeData({
          title: "Illustration",
          image: ImageThemeIllustration,
          category: category,
          text: "Människoillustrationer är lika varierande som människorna de skildrar. Från minimalistiska porträtt till detaljerade skisser, dessa verk är djupgående studier av mänsklig form, uttryck och känsla."
        });
      } else if (style === "illustration" && category === "creatures") {
        setDisplayThemeData({
          title: "Illustration",
          image: ImageThemeIllustration,
          category: category,
          text: "Varelseillustrationer tar oss med på en resa genom fantasiens rikedom. Med varje penseldrag avslöjas en värld av mytiska varelser, fantasifulla skapelser, och sagolika monster, alla skapade med en omsorg för detalj och kreativitet."
        });
      } else if (style === "illustration" && category === "architecture") {
        setDisplayThemeData({
          title: "Illustration",
          image: ImageThemeIllustration,
          category: category,
          text: "Arkitekturillustrationer skildrar den konstnärliga tolkningen av byggnader, strukturer och städer. Från historiska monument till framtidens visioner, dessa bilder ger en djupgående insikt i arkitektens drömmande värld."
        });
      } else if (style === "fantasy" && category === "nature") {
        setDisplayThemeData({
          title: "Fantasy",
          image: ImageThemeFantasy,
          category: category,
          text: "Fantasy-naturposters erbjuder en resa till imaginära världar fulla av övernaturliga landskap och mystiska fenomen. Varje bild skildrar en plats där naturens lagar böjs och formar på de mest fantastiska sätt."
        });
      } else if (style === "fantasy" && category === "animals") {
        setDisplayThemeData({
          title: "Fantasy",
          image: ImageThemeFantasy,
          category: category,
          text: "Fantasy-djurposters innehåller en mängd mytiska och magiska varelser. Varje bild tar oss till en värld där djuren kan ha mänskliga egenskaper, magiska förmågor eller helt enkelt vara skapelser av ren fantasi."
        });
      } else if (style === "fantasy" && category === "humans") {
        setDisplayThemeData({
          title: "Fantasy",
          image: ImageThemeFantasy,
          category: category,
          text: "Fantasy-människoposters presenterar oss för karaktärer från en annan värld. Dessa bilder kan innehålla allt från riddare och trollkarlar till älvor och andra övernaturliga varelser, alla rikt detaljerade för att framkalla en stark känsla av fantasi."
        });
      } else if (style === "fantasy" && category === "creatures") {
        setDisplayThemeData({
          title: "Fantasy",
          image: ImageThemeFantasy,
          category: category,
          text: "Fantasy-varelsesposters dyker djupt in i den föreställda världens mystik. Dessa bilder skildrar mytiska varelser som drakar, fenixar, och troll, alla rikt detaljerade för att framkalla känslor av äventyr och undran."
        });
      } else if (style === "fantasy" && category === "architecture") {
          setDisplayThemeData({
            title: "Fantasy",
            image: ImageThemeFantasy,
            category: category,
            text: "Fantasy-arkitekturposters tar oss till otroliga byggnader och strukturer i en annan värld. Varje bild presenterar en plats där arkitekturen inte är begränsad av verklighetens lagar, men formad av konstnärens fantasi."
          });
        } else if (style === "photography" && category === "nature") {
          setDisplayThemeData({
            title: "Fotografi",
            image: ImageThemePhoto,
            category: category,
            text: "Naturfotoposters visar upp den orörda skönheten i vår värld, från majestätiska berg till tysta skogar. De belyser jordens underverk och bjuder in tittaren att uppskatta den naturliga skönheten omkring oss."
          });
        } else if (style === "photography" && category === "animals") {
          setDisplayThemeData({
            title: "Fotografi",
            image: ImageThemePhoto,
            category: category,
            text: "Djurfotoposters fångar den vilda andan hos världens mest fascinerande varelser. Från exotiska djur i avlägsna djungler till familjära husdjur i våra hem, dessa bilder firar djurens mångfald och den speciella plats de har i våra hjärtan."
          });
        } else if (style === "photography" && category === "humans") {
          setDisplayThemeData({
            title: "Fotografi",
            image: ImageThemePhoto,
            category: category,
            text: "Människofotoposters är djupgående skildringar av människolivet. Varje bild fångar en aspekt av mänsklig erfarenhet, från porträtt till gatufotografi, och erbjuder en inblick i våra liv och kulturer."
          });
        } else if (style === "photography" && category === "creatures") {
          setDisplayThemeData({
            title: "Fotografi",
            image: ImageThemePhoto,
            category: category,
            text: "Fotografi av varelser skildrar en fascinerande värld av mystik och fantasi. Dessa bilder fångar upp allt från mytiska varelser till fantasifulla skapelser, ofta med en rik detaljrikedom som lockar ögat att utforska."
          });
        } else if (style === "photography" && category === "architecture") {
          setDisplayThemeData({
            title: "Fotografi",
            image: ImageThemePhoto,
            category: category,
            text: "Arkitekturfotoposters fångar byggnadskonstens estetik på ett unikt sätt. Varje bild presenterar en byggnad eller struktur med ett konstnärligt öga, vilket framhäver linjer, former och ljusspel."
          });
        } else if (style === "pop-art" && category === "nature") {
          setDisplayThemeData({
            title: "Pop-Art",
            image: ImageThemePopArt,
            category: category,
            text: "Pop-Art naturmotiv kombinerar naturens skönhet med popkonstens livliga uttryck. Varje konstverk är en fest för ögat, med djärva färger och starka kontraster som gör naturen mer levande än någonsin."
          });
        } else if (style === "pop-art" && category === "animals") {
          setDisplayThemeData({
            title: "Pop-Art",
            image: ImageThemePopArt,
            category: category,
            text: "Pop-Art djurposter är en explosion av färg och stil. Varje verk tar ett djurmotiv och förvandlar det till ett levande uttryck av popkonst, med djärva färger, starka kontraster och ofta en touch av humor."
          });
        } else if (style === "pop-art" && category === "humans") {
          setDisplayThemeData({
            title: "Pop-Art",
            image: ImageThemePopArt,
            category: category,
            text: "Pop-Art människoporträtt är ett ikoniskt uttryck för samtida konst. De kombinerar ofta kända ansikten med djärva färger och grafiska former, skapar en omedelbar visuell attraktion som är karaktäristisk för popkonst."
          });
        } else if (style === "pop-art" && category === "creatures") {
          setDisplayThemeData({
            title: "Pop-Art",
            image: ImageThemePopArt,
            category: category,
            text: "Pop-Art varelseskonst tar fantasivarelser och förvandlar dem till levande, färgstarka konstverk. Med starka linjer, överdrivna former och djärva färgpaletter, dessa konstverk fångar ögat och skapar en oförglömlig visuell upplevelse."
      });
        } else if (style === "pop-art" && category === "architecture") {
          setDisplayThemeData({
            title: "Pop-Art",
            image: ImageThemePopArt,
            category: category,
            text: "Pop-Art arkitekturposter framhäver byggnadskonstens skönhet på ett unikt och samtida sätt. Genom att använda sig av ljusa färger, starka kontraster och ofta geometriska mönster."
      });
        }
  },[style, category]);


  const [selectedColor, setSelectedColor] = useState("")
  const [selectedShape, setSelectedShape] = useState("")
  const [selectedSize, setSelectedSize] = useState("")

  const [filteredUrlsDisplay, setFilteredUrlsDisplay] = useState([]);

  function convertCategory(category){
    if (category==="nature"){
      return "Natur"
    }
    if (category==="animals"){
      return "Djur"
    }
    if (category==="humans"){
      return "Människor"
    }
    if (category==="creatures"){
      return "Varelser"
    }
    if (category==="nature"){
      return "Natur"
    }
    if (category==="architecture"){
      return "Natur"
    }
    return ""
  }

  

  useEffect(() => {
    // Filters color, shape, size is all '' from the beginning, if any of the variable is not '' then it should filter on this too
    
    
    
  
    // Array with json which should be filtered
    
  
    // If signedUrls is defined, proceed with the filtering
    if (signedUrls) {
      const filteredUrls = signedUrls.filter((item) => {
  
        let colorMatch = true;
        let shapeMatch = true;
        let sizeMatch = true;
  
        // Check if a color is selected and if the item has that color
        if (selectedColor !== '') {
          colorMatch = item.colors.includes(selectedColor);
        }
  
        // Check if a shape is selected and if the item has that shape
        // Check if a size is selected and if the item has that size
        // Assuming size corresponds to aspectRatio
        if (selectedShape !== '') {
          shapeMatch = item.aspectRatio === selectedShape;
        }
  
        // If the item matches all selected filters, include it in the filtered array
        //return colorMatch && shapeMatch && sizeMatch;
        return colorMatch && shapeMatch && sizeMatch;
      });
  
      // If no items were found, use the original array
      if (filteredUrls.length === 0) {
        
        
      } else {
        
        
        setFilteredUrlsDisplay(filteredUrls)
        
      }
    } else {
      
    }
  
  }, [selectedColor, selectedShape, selectedSize, signedUrls]);
  


  const [frameType, setFrameType] = useState("no_margin");
  const [frameCode, setFrameCode] = useState("f2");
  const framedImageIndex = (frameCode, frameType) => {
    let frameIndex = 0; // Define frameIndex here before the if statement
  
    if (frameCode === "f1") {
      // Your logic for f1
      frameIndex = 0;
    } else if (frameCode === "f2") {
      // Your logic for f2
      frameIndex = 1;
    } else if (frameCode === "f3") {
      // Your logic for f3
      frameIndex = 2;
    } else if (frameCode === "f4") {
      // Your logic for f4
      frameIndex = 3;
    } else if (frameCode === "f5") {
      // Your logic for f5
      frameIndex = 4;
    } else if (frameCode === "f6") {
      // Your logic for f6
      frameIndex = 5;
    } else if (frameCode === "f7") {
      // Your logic for f7
      frameIndex = 6;
    } else if (frameCode === "f8") {
      // Your logic for f8
      frameIndex = 7;
    }
  
    if(frameType==="margin"){
      frameIndex += 8;
    }
    return frameIndex;
  };





  const drawerContent = () => {
    return (



      <div>
        <Divider />
        {drawerMode === "filter" ? (
          <div>
            <div
            style={{
              marginBottom:"40px",

            }}
            >
              <ColorFilter
              isMobileView={isMobileView}
              setSelectedColor={setSelectedColor}
              selectedColor={selectedColor}
              />
            </div>
            <div
            style={{
              marginBottom:"40px"
            }}
            >
              <ShapeFilter
              setSelectedShape={setSelectedShape}
              selectedShape={selectedShape}
              isMobileView={isMobileView}
              />
            </div>
            <div>
              <SizeFilter
              isMobileView={isMobileView}
              setSelectedSize={setSelectedSize}
              selectedSize={selectedSize}
              />
            </div>
          </div>
        ) : (
          <div>
            <SortingFilter
            isMobileView={isMobileView}
            setSelectedSortOn={setSelectedSortOn}
            />
          </div>
        )}
      </div>
    );
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }
  const scrollToTopSmooth = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const scrollToPosition = (scrollPosition) => {

    window.scrollTo(0, scrollPosition);
  };


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            setShowFixedIcons(true);
          } else {
            setShowFixedIcons(false);
          }
        });
      },
      { threshold: 0 }
    );

    if (filterButtonRef.current && sortButtonRef.current) {
      observer.observe(filterButtonRef.current);
      observer.observe(sortButtonRef.current);
    }

    return () => {
      if (filterButtonRef.current && sortButtonRef.current) {
        observer.unobserve(filterButtonRef.current);
        observer.unobserve(sortButtonRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (fixedIconsRef.current) {
      if (showFixedIcons) {
        gsap.to(fixedIconsRef.current, {
          duration: 0.5,
          y: 0,
          autoAlpha: 1,
        });
      } else {
        gsap.to(fixedIconsRef.current, {
          duration: 0.5,
          y: -50,
          autoAlpha: 0,
        });
      }
    }
  }, [showFixedIcons]);


  useEffect(() => {
    console.log("style:",style)
    console.log("category:",category)

      if(scrollPosition!==0){
        scrollToPosition(scrollPosition)

        
        //setScrollPosition(0)
      }
      else{
        if(style!=="" && category===undefined){
          scrollToTopSmooth()
    
        }
        if(typeof style === "string" && typeof category === "string"){
          scrollToTopSmooth();
        }
      }
   
  }, [style, category]);

// useEffect(() => {

//   scrollToPosition(scrollPosition);
  
// }, [scrollPosition]);



useEffect(() => {
  if(scrollPosition !== 0){
    console.log("Should scroll down now");
    setTimeout(() => {
      scrollToPosition(scrollPosition);
      setScrollPosition(0)
    }, 20); // Delay of 100ms
  }
}, []);


useEffect(() => {
  scrollToTopSmooth();
  
}, [searchResult]);

 

  useEffect(() => {
    if((style)===undefined){

    }
    else{
      if(style?.[0] === "1"){
        setDisplayButtons("hidden")
  
      }
      else{
        setDisplayButtons("visible")
      }
    }


  }, [style]);

  return (
    
    <div>
      {displayThemeData.category !== "" && style?.[0] !== "1"? (
<Link to={`/rabatt`} style={{ textDecoration: 'none' }}>
  <div  style={{ display: "flex", flexDirection: "column",  position: 'relative',
    height: '80px',
    width: isMobileView ? '100%':'100%',
    background: '#fe8627',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: "0px",
    marginTop: "-12px",
    left: isMobileView ? "0px": "0px",
    marginBottom: "12px",
    color: "white" }}>
        <Grid container justify="center" alignItems="center"
        style={{
        }}
        >
          <Grid item>
            <h3
              style={{
                top: "30px",
                position: "relative",
                fontSize: "20px",
                fontWeight: "bold",
                paddingLeft: isMobileView? "0px":"9vw"
              }}
            >30% rabatt på posters</h3>
          </Grid>
        </Grid>
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <p
            style={{
              top: "-6px",
              position: "relative",
              paddingLeft: isMobileView? "0px":"12vw"
            }}
            >*Gäller t.o.m. 16/02</p>
          </Grid>
        </Grid>
        <Grid container justify="center" alignItems="center">
          <Grid 
          style={{
            position: "relative",
            top: "-38px",
            paddingLeft: isMobileView? "0px":"9vw"
          }}
          item>
            <Countdown endDate={"2024-02-16"}/>
          </Grid>
        </Grid>
      </div>
      </Link>):null}
    <div>


{displayThemeData.category !== "" && (!style || (style && style[0] !== "1")) ? (
<div
  style={{
    position: 'relative',
    width: isMobileView ?'90%':isTabletView?"90%": "70%",
    left: "20px",
    height: '300px',
    marginLeft: isMobileView ?'%':isTabletView?"1%":"22%",
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden', 
    marginBottom: '30px',
    marginTop: "10px",
  }}
>

  <img 
    style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    }}
    src={displayThemeData.image}
    alt='Your description here'
  />

<div
  style={{
    position: 'relative',
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '10px',
    zIndex: 0,
    maxHeight: "80%",
    border: '1px solid #ede8da',
    boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: "90%",
    maxWidth: "600px"
  }}
>
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '0px' }}>
    <h4 style={{ margin: 0, marginLeft: "10px", fontSize: "15px" }}>Posters ▸ {displayThemeData.title}</h4>
    {displayThemeData.category && <h4 style={{ margin: 0, marginLeft: "10px", fontSize: "15px"  }}> ▸ {convertCategory(displayThemeData.category)}</h4>}
  </div>
  <hr style={{ width: '100%' }} />
  <div 
  style={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    marginTop:'-25px',
    justifyContent: 'center',
    alignSelf: 'center'  // added this line
  }}
>
{displayThemeData.category !== "" ? (
  <h3 style={{ fontWeight: "bold" }}>{convertCategory(displayThemeData.category)}</h3>
) : (
  <p>Something else, </p>
)}

</div>
  <p style={{ margin: 0 , top: "-20px", position: "relative"}}>{displayThemeData.text}</p>
</div>

</div>
):(null)
}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "40px",
        }}
      >
      {
        displayFixedButtons &&
        <motion.div
          initial={{ opacity: 0, y: "-20px" }} // Start from opacity 0 and half the screen height above the current position
          animate={{ opacity: 1, y: 0 }} // Animate to opacity 1 and the current position
          transition={{ duration: 0.5 }} //
          style={{
            position: "fixed",
            top:  "17%",
            left: isMobileView?"30%":"50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "center",
            opacity: 1,
            backgroundColor: "white",
            width: "320px",
            height: "50px",
            zIndex: 2,
            borderRadius: "20px",
            boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 3px 1px rgba(0, 0, 0, 0.22)', // Updated this line
            visibility: displayButtons,
            width: "170px"
          }}
        >
          <Button
            onClick={() => openDrawer("filter")}
            variant="text"
            color="white"
            style={{
              
              backgroundColor: "white",
              borderRadius: "20px",
              position: "relative",
              right: "-10px",
            
            }}
          >
            <IconButton color="primary" aria-label="delete">
              <TuneIcon />
            </IconButton>
          </Button>

          <Button
          variant="text"
          color="white"
          onClick={(event) => {
            event.stopPropagation();
            toggleDrawerFrame();
          }}
          style={{
            backgroundColor: "white",
            borderRadius: "20px",
            position: "relative",
          }}
          >
          <IconButton color="primary" aria-label="delete">
            <WallpaperIcon />
          </IconButton>
          </Button>
          <Button
            onClick={() => openDrawer("sort")}
            variant="text"
            color="white"
            style={{
              backgroundColor: "white",
              borderRadius: "20px",
              position: "relative",
              right: "10px"
              
            }}
          >
            <IconButton color="primary" aria-label="delete">
              <FilterListIcon />
            </IconButton>
          </Button>
        </motion.div>
          }

        <div
          ref={fixedIconsRef}
          style={{
            position: "relative",
            top: 0,
            justifyContent: "center",
            zIndex: 100,
            opacity: 0,
          }}
        >

          <Button
            onClick={() => openDrawer("sort")}
            variant="outlined"
            color="white"
            style={{
              backgroundColor: "white",
              height: "40px",
              marginLeft: "30px",
              borderRadius: "10px",
              width: isMobileView?"30px":"130px",
              marginTop: "10px",
            }}
          >
            <p
              style={{
                position: "relative",
                marginLeft:isMobileView?"12px": "15px",
                marginRight:isMobileView? "-12px": "0px",
                fontWeight: "bold",
                fontSize: isMobileView?"12px":"14px",
              }}
            >
              SORTERA
            </p>
            <IconButton color="primary" aria-label="delete">
              <FilterListIcon />
            </IconButton>
          </Button>
        </div>
  
        <Button
          onClick={() => openDrawer("filter")}
          variant="contained"
          color="white"
          style={{
            position: "relative",
            marginLeft: "-80px",
            backgroundColor: "white",
            height: "40px",
            marginRight: "38px",
            borderRadius: "10px",
            width: isMobileView?"100px":"150px",
            marginTop: "10px",
            visibility:displayButtons

          }}
        >
          <p
            style={{
              position: "relative",
              marginLeft:isMobileView?"10px": "15px",
              marginRight:isMobileView? "-12px": "0px",
              fontSize: "15px",
              fontWeight: "bold",
              fontSize: isMobileView?"12px":"14px",
            }}
          >
            FILTRERA
          </p>
  
          <IconButton color="primary" aria-label="delete">
            <TuneIcon />
          </IconButton>
        </Button>

        <div style={{ display: "flex", alignItems: "center" }}>
  <FormControlLabel
    control={
      <Switch
        checked={toggleState}
        onChange={handleToggleChange}
        color="primary"
      />
    }
    style={{ marginRight: "-36px", position: "relative", top: "6px",visibility:displayButtons }}
  />
  <div
    onClick={(event) => {
      event.stopPropagation();
      toggleDrawerFrame();
    }}
    style={{ display: "flex", alignItems: "center", visibility:displayButtons }}
  >
    <Button
      variant="contained"
      color="white"
      style={{
        backgroundColor: "white",
        height: "40px",
        marginLeft: isMobileView?"-12px":"33px",
        borderRadius: "10px",
        width: isMobileView?"40px":"100px",
        marginTop: "10px",
        
      }}
          >
          <p
          style={{
            position: "relative",
            marginLeft:isMobileView?"12px": "15px",
            marginRight:isMobileView? "-12px": "0px",
            fontWeight: "bold",
            fontSize: isMobileView?"12px":"14px",
          }}
          >
            RAM
          </p>
  
          <IconButton color="primary" aria-label="delete" >
            <WallpaperIcon />
          </IconButton>
          </Button>
          </div>

        </div>
        <Button
          style={{
            backgroundColor: "white",
            height: "40px",
            marginLeft: "30px",
            borderRadius: "10px",
            width: isMobileView?"96px":"150px",
            marginTop: "10px",
            visibility:displayButtons
          }}
          onClick={() => openDrawer("sort")}
          variant="contained"
          color="white"
        >
          <p
            style={{
              position: "relative",
              marginLeft:isMobileView?"12px": "15px",
              marginRight:isMobileView? "-12px": "0px",
              fontWeight: "bold",
              fontSize: isMobileView?"12px":"14px",
            }}
          >
            SORTERA
          </p>
          <IconButton color="primary" aria-label="delete">
            <FilterListIcon />
          </IconButton>
        </Button>
      </div>


      
      <Gallery

        loadCount={loadCount}
        setLoadCount={setLoadCount}


        discountPercentage={discountPercentage}
        handleScrollPosition={handleScrollPosition}
        setScrollPosition={setScrollPosition}
        searchResult={searchResult}
        imageDisplayUrls={imageDisplayUrls}
        imageDisplayUrl={imageDisplayUrl}
        searchValue={searchValue}
        favoritePoster={favoritePoster}
        setFavoritePoster={setFavoritePoster}
        frameCode={frameCode}
        frameType={frameType}
        frameIndex={framedImageIndex(frameCode, frameType)}
        isMobileView={isMobileView}
        signedUrls={filteredUrlsDisplay}
        setImageDisplayUrls={setImageDisplayUrls}
        selectedStyle={style || ""}
        selectedCategory={category || ""}
        toggleState={toggleState}
        onImageClick={onImageClick}
      />
      <div
      style={{
        position: "relative",
        left: isMobileView? "20px": "10%",
        width: "90vw"
      }}
      >
      {style === '1-kontakta-oss' && <ContactComponent />}
      {style === '1-vanliga-fragor' && <CommonQuestions />}
      {style === '1-kopvillkor' && <PurchaseConditions />}
      {style === '1-leverans' && <DeliveryInformation />}
      {style === '1-cookiepolicy' && <Cookiepolicy />}
      {style === '1-integritetspolicy' && <IntegrityPolicy />}
      {style === '1-om-oss' && <AboutUs />}
      {style === '1-posters' && <PosterInfo />}
      {style === '1-tack' && <ThankYou />}
      {style === '1-misslyckad-betalning' && <PaymentFailed />}
      {style === '1-tips-till-tavelvaggen' && <PageUnderConstruction />}
      {style === '1-affischer-planscher' && <PageUnderConstruction />}
      {style === '1-poster-store' && <PageUnderConstruction />}
      {style === 'posterpaket' && <PageUnderConstruction />}
      </div>


    <CustomOverlayDrawer
    isMobileView={isMobileView}
    setFrameType={setFrameType}
    setFrameCode={setFrameCode}
    openFrameMenu={openFrameMenu}
    handleToggleChange={toggleDrawerFrame}
    setToggleState={setToggleState}
    />

      
  
  <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
  <divDs
    style={{
      width: isMobileView ? "75vw" : "36vw",
      padding: "20px",
      position: "relative",
    }}
  >
    <IconButton
      onClick={() => setDrawerMode(drawerMode === "filter" ? "sort" : "filter")}
      style={{
        position: "absolute",
        top: "5px",
        right: isMobileView?"0px":"5px",
      }}
    >
      <SyncIcon />
    </IconButton>
    {drawerContent()}
  </divDs>

</Drawer>
<div
style={{
  marginLeft: isTabletView2?"0px":"10vw"
}}
>
        <div
        style={{
          marginLeft: isMobileView2?"0px":"160px"
        }}>
        <SubSectionsCard
        isMobileView={isMobileView2}/>
        </div>
        <div
        style={{

        }}
        >
        {isTabletView2?<BottomSectionMobile setCookieConsentGiven={setCookieConsentGiven} setOpenCookieBar={setOpenCookieBar} />:<BottomSection setCookieConsentGiven={setCookieConsentGiven} setOpenCookieBar={setOpenCookieBar}/>}


        </div>

        </div>


        </div>

        {scrollPosition!==0 && isMobileView?<LoadingOverlay duration={700}/>:null}
        </div>
);
};

export default GalleryPage;

  



