import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Popper, Paper, ClickAwayListener, MenuList,Button, Typography, Grid, AppBar, Toolbar, Divider, InputBase, Container, Card, CardMedia, CardActions, Footer, CardContent,Menu, MenuItem  } from '@material-ui/core';
import { PhotoCamera } from '@material-ui/icons';
import ImageMain from './images/landing_main2.jpg'
import { Box } from '@material-ui/core';

import BottomSectionMobile from './BottomSectionMobile';
import BottomSection from './BottomSection';
import { ArrowAnimation } from './ArrowAnimation';
import SubSectionsCard from './SubSectionsCard';
import ScrollToTopButton from './ScrollToTopButton';
import { motion } from "framer-motion";
import useMediaQuery from '@mui/material/useMediaQuery';
import AmarilloRegular from './styles/DancingScript-VariableFont_wght.ttf';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconSwe from './static/svg/Flag_of_Sweden.png'
import FavoriteIcon from '@mui/icons-material/Favorite';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Countdown from './Countdown';
import { useNavigate } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';


import { Link } from 'react-router-dom';


import image1 from './images/landing_page/image1.png'
import image2 from './images/landing_page/image2.jpeg'
import image3 from './images/landing_page/image33.jpg'


import image11 from './images/landing_page/image11.png'
import image12 from './images/landing_page/image12.png'
import image13 from './images/landing_page/image13.png'


import imageFavorite1 from './images/landing_page/favorite/img1/1.jpg'
import imageFavorite1_1 from './images/landing_page/favorite/img1/image1f.jpg'
import imageFavorite2 from './images/landing_page/favorite/img2/1.jpg'
import imageFavorite1_2 from './images/landing_page/favorite/img2/image1f.jpg'
import imageFavorite3 from './images/landing_page/favorite/img3/1.jpg'
import imageFavorite1_3 from './images/landing_page/favorite/img3/image1f.jpg'
import imageFavorite4 from './images/landing_page/favorite/img4/1.jpg'
import imageFavorite1_4 from './images/landing_page/favorite/img4/image1f.jpg'
import imageFavorite5 from './images/landing_page/favorite/img5/1.jpg'
import imageFavorite1_5 from './images/landing_page/favorite/img5/image1f.jpg'
import imageFavorite6 from './images/landing_page/favorite/img6/1.jpg'
import imageFavorite1_6 from './images/landing_page/favorite/img6/image1f.jpg'
import imageFavorite7 from './images/landing_page/favorite/img7/1.jpg'
import imageFavorite1_7 from './images/landing_page/favorite/img7/image1f.jpg'
import imageFavorite8 from './images/landing_page/favorite/img8/1.jpg'
import imageFavorite1_8 from './images/landing_page/favorite/img8/image1f.jpg'
import imageFavorite9 from './images/landing_page/favorite/img9/1.jpg'
import imageFavorite1_9 from './images/landing_page/favorite/img9/image1f.jpg'
import imageFavorite10 from './images/landing_page/favorite/img10/1.jpg'
import imageFavorite1_10 from './images/landing_page/favorite/img10/image1f.jpg'
import imageFavorite11 from './images/landing_page/favorite/img11/1.jpg'
import imageFavorite1_11 from './images/landing_page/favorite/img11/image1f.jpg'
import imageFavorite12 from './images/landing_page/favorite/img12/1.jpg'
import imageFavorite1_12 from './images/landing_page/favorite/img12/image1f.jpg'



import imageNews1 from './images/landing_page/news/img1/1.jpg';
import imageNews1_1 from './images/landing_page/news/img1/image1f.jpg';

import imageNews2 from './images/landing_page/news/img2/1.jpg';
import imageNews1_2 from './images/landing_page/news/img2/image1f.jpg';

import imageNews3 from './images/landing_page/news/img3/1.jpg';
import imageNews1_3 from './images/landing_page/news/img3/image1f.jpg';

import imageNews4 from './images/landing_page/news/img4/1.jpg';
import imageNews1_4 from './images/landing_page/news/img4/image1f.jpg';

import imageNews5 from './images/landing_page/news/img5/1.jpg';
import imageNews1_5 from './images/landing_page/news/img5/image1f.jpg';

import imageNews6 from './images/landing_page/news/img6/1.jpg';
import imageNews1_6 from './images/landing_page/news/img6/image1f.jpg';

import imageNews7 from './images/landing_page/news/img7/1.jpg';
import imageNews1_7 from './images/landing_page/news/img7/image1f.jpg';

import imageNews8 from './images/landing_page/news/img8/1.jpg';
import imageNews1_8 from './images/landing_page/news/img8/image1f.jpg';

import imageNews9 from './images/landing_page/news/img9/1.jpg';
import imageNews1_9 from './images/landing_page/news/img9/image1f.jpg';

import imageNews10 from './images/landing_page/news/img10/1.jpg';
import imageNews1_10 from './images/landing_page/news/img10/image1f.jpg';

import imageNews11 from './images/landing_page/news/img11/1.jpg';
import imageNews1_11 from './images/landing_page/news/img11/image1f.jpg';

import imageNews12 from './images/landing_page/news/img12/1.jpg';
import imageNews1_12 from './images/landing_page/news/img12/image1f.jpg';


const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'New Font, sans-serif',
  },
  appBar: {
    height: 90,
    backgroundColor: '#E0E8EA !important',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  appBar2: {
    height: 45,
    backgroundColor: '#E0E8EA !important',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
  },
  appBarLinks: {
    top: "26px",
    right: "0vw"
  },
  saleDiv:{
    position: 'absolute',
    height: '80px',
    width: '100%',
    background: '#fe8627',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: "23px",
    marginTop: "3px",
    color: "white"
  },
  container: {
    marginTop: 64,
  },
  cardMedia: {
    width: '95vw',
    height: '100%',
    top: '0px',

    marginLeft: '2.5vw'
  },
  heading: {
    fontSize:"30px",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  sectionDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  paragraph: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    
  },
  searchInput: {
    top:"-2px",
    height:"30px",
    right:"340px",
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius, // Use theme's default border radius

    // Optional: Add padding if you want some space between the border and the text
    padding: theme.spacing(1),
  },
  typography: {
    position: "absolute",
    bottom:"14px",
    left:"60px",
    fontSize: "24px",
    fontFamily: [
      'Dancing Script',
      'cursive'
    ].join(','),
  },
  veckansTitle: {
    position:"relative",
    marginTop: "50px",
    fontSize: '26px', // Adjust this value to set the desired font size
    marginBottom: theme.spacing(2), 
  },
  linkButton: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  customDropdown: {
    position: 'fixed',
    top: '90px',
    left: '0',
    background: 'white',
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2),
    zIndex: theme.zIndex.appBar + 1,
    width: '100%',
  },
  customDropdownVisible: {
    display: 'block',
  },
  popper: {
    zIndex: theme.zIndex.appBar + 1,
    width: '100%',
  },
  fullWidthGrid: {
    width: '100%',
  },
  appBarDivider: {
    borderTop: '1px solid #ccc', // Change the color and thickness as desired
    width: '100%',
  },
  appBarTextInfo: {
    fontSize: "13px",
    color: "black"
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [AmarilloRegular],
      },
    },
  },
}));





const topplista = [
  { imageUrl: imageFavorite1, hoverImageUrl: imageFavorite1_1, title: 'Rökfyllda Drömmar', price: 10, imageId: '/pop-art/humans/ed28f9247a8e4eb4a21c5a316d6776b8' },
  { imageUrl: imageFavorite8, hoverImageUrl: imageFavorite1_8, title: 'Kaktusprinsessan', price: 60, imageId: '/pop-art/nature/89862336aa624257b80095a428e5284e' },
  { imageUrl: imageFavorite2, hoverImageUrl: imageFavorite1_2, title: 'Kattens Mystiska Blick', price: 20, imageId: '/illustration/animals/f5d2a1054bf54288bb58da89880d7b4e' },
  { imageUrl: imageFavorite3, hoverImageUrl: imageFavorite1_3, title: 'Vild och Fri', price: 30, imageId: '/abstract/animals/b3ba63a4172749b8b0787ccbb1b5be88' },
  { imageUrl: imageFavorite4, hoverImageUrl: imageFavorite1_4, title: 'Katt med Stil', price: 40, imageId: '/pop-art/animals/e7bb73023943454780c97d422f53b55c' },
  { imageUrl: imageFavorite5, hoverImageUrl: imageFavorite1_5, title: 'Capybarans Stadsäventyr', price: 50, imageId: '/photography/animals/f5e48d8772f8497d871df5a25ac20304' },
  { imageUrl: imageFavorite6, hoverImageUrl: imageFavorite1_6, title: 'Citrusens Strålande Ljus', price: 60, imageId: '/abstract/nature/cb53f8b35735496dbdbb599616088f4f' },
  { imageUrl: imageFavorite7, hoverImageUrl: imageFavorite1_7, title: 'Bohemisk Citrus Oas', price: 60, imageId: '/bohemian/nature/efcc541c04794c3e94ad1f63218af39c' },
  { imageUrl: imageFavorite9, hoverImageUrl: imageFavorite1_9, title: 'Jupiters Stormande Symfoni', price: 60, imageId: '/abstract/nature/a20faa1d68424dfe9fdb78509bb56c86' },
  { imageUrl: imageFavorite10, hoverImageUrl: imageFavorite1_10, title: 'Vattenmelons Värld', price: 60, imageId: '/abstract/nature/3f6089ecaeb945ecb8064d0730f6cabe' },
  { imageUrl: imageFavorite11, hoverImageUrl: imageFavorite1_11, title: 'Rosa Rosens Skönhet', price: 60, imageId: '/bohemian/nature/4122e7302e1e48d3bee47db118a8e0bc' },
  { imageUrl: imageFavorite12, hoverImageUrl: imageFavorite1_12, title: 'Kaktus med Vit Krona', price: 60, imageId: '/bohemian/nature/13acf683faf840a89580d0fe4d07113e' },
];


const nyheter = [
  { imageUrl: imageNews1, hoverImageUrl: imageNews1_1, title: 'Tropisk Skymningsljus', price: 10, imageId: '/pop-art/nature/c8a7025ff1ab4a518e92a7d5ab30d595' },
  { imageUrl: imageNews2, hoverImageUrl: imageNews1_2, title: 'Citrusskådespel: Färgernas Fest', price: 10, imageId: '/abstract/nature/2b50c70be4264b6daba7e5bad0c1abd1' },
  { imageUrl: imageNews3, hoverImageUrl: imageNews1_3, title: 'Krets Hamster Kraft', price: 10, imageId: '/futuristic/animals/dab09894e4c04b389dd7238b219047eb' },
  { imageUrl: imageNews4, hoverImageUrl: imageNews1_4, title: 'Framtidens Cybergroda', price: 10, imageId: '/futuristic/animals/29a02a3eefd94f3baa7fb954483466ba' },
  { imageUrl: imageNews5, hoverImageUrl: imageNews1_5, title: 'Panterns Ögon bakom Glasen', price: 10, imageId: '/pop-art/animals/9998c890f6694e1f9cd808fe2baced84' },
  { imageUrl: imageNews6, hoverImageUrl: imageNews1_6, title: 'Rikedomens Färgpalett', price: 10, imageId: '/pop-art/humans/1b961c9d73134346881fbb32d078122a' },
  { imageUrl: imageNews7, hoverImageUrl: imageNews1_7, title: 'Kanin Konstverk', price: 10, imageId: '/pop-art/animals/fa2b914d35b04065bae896dcdc7a2722' },
  { imageUrl: imageNews8, hoverImageUrl: imageNews1_8, title: 'Chic Hund med Glasögon', price: 10, imageId: '/pop-art/animals/af9cfef560e0454e955e616649f8558a' },
  { imageUrl: imageNews9, hoverImageUrl: imageNews1_9, title: 'Björnens Spår', price: 10, imageId: '/abstract/animals/5010533b453c4681b14a799229a3354a' },
  { imageUrl: imageNews10, hoverImageUrl: imageNews1_10, title: 'Urbana Valpens Stil', price: 10, imageId: '/photography/animals/53e647479a954199bc783b6ab461d8d6' },
  { imageUrl: imageNews11, hoverImageUrl: imageNews1_11, title: 'Katt med Attityd', price: 10, imageId: '/photography/animals/d7b3c2df60f64c40a349539b69ffb193' },
  { imageUrl: imageNews12, hoverImageUrl: imageNews1_12, title: 'Den Ädla Purr', price: 10, imageId: '/fantasy/animals/2320179a059d45dd91ea0b2d95e4e71e' },
];





const middleSectionImages = [
  { imageUrl: image11, title: 'New Section 11', price: 10, imageId: 'm1', style: "fantasy", alt:"Fantasy Poster Tema" },
  { imageUrl: image12, title: 'New Section 12', price: 20, imageId: 'm2', style: "pop-art", alt:"Pop-Art Poster Tema"  },
  { imageUrl: image13, title: 'New Section 12', price: 20, imageId: 'm2', style: "futuristic", alt:"Framtida Poster Tema"  },
];


const newSectionImages = [
  { imageUrl: image1, title: 'New Section 1', price: 10, imageId: 'n1', style: "abstract", alt:"Abstrakt Poster Tema" },
  { imageUrl: image2, title: 'New Section 2', price: 20, imageId: 'n2', style: "photography", alt:"Foto Poster Tema"},
];

const newSectionImageLarge = [
  { imageUrl: image3, title: 'New Section Large', price: 30, imageId: 'n3',style: "illustration", alt:"Illustration Poster Tema" },
];





const LandingPage = (props) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery('(max-width:660px)');
  const [anchorEl, setAnchorEl] = useState(null);

  const [openDropDownDivPoster, setOpenDropDownDivPoster] = useState("hidden")
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledTopplista = shuffle([...topplista]);


  const renderParagraphs = () => (
    <Grid container spacing={isMobileView?0:6} justifyContent="center">
      <Grid item  md={6}>
        <Typography variant="body1" align="justify" gutterBottom 
        style={{
          marginBottom: "0px",
          fontSize: isMobileView?"14px":"15px"
        }}
        className={classes.paragraph}>
        <strong>STILFULLA POSTERS OCH KONSTVERK FÖR ALLA RUM</strong> &nbsp;


        Elegant design behöver inte kosta mycket. På Postertopia har vi prisvärda posters i olika stilar, från stora konstverk till mindre fotografier och illustrationer. Våra posters är inspirerade av internationella trender och passar i många hem.
        
        </Typography>
        <Typography variant="body1" align="justify" gutterBottom className={classes.paragraph}
         style={{
          marginBottom: "0px",
          fontSize: isMobileView?"14px":"15px"
        }}
        > 
        <strong>SKAPA EN HARMONISK TAVELVÄGG MED VÅRA POSTERS</strong> &nbsp;

        En tavelvägg låter dig kombinera olika posters för att skapa en unik atmosfär. Med vårt brett sortiment kan du inreda kostnadseffektivt och enligt trend. Skapa en estetisk tavelvägg med våra abstrakta, futuristiska eller fotografiska posters.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body1" align="justify" gutterBottom className={classes.paragraph}
         style={{
          marginBottom: "0px",
          fontSize: isMobileView?"14px":"15px"
        }}
        >
        <strong>UTFORSKA OLIKA STILAR OCH FORMAT FÖR DIN TAVELVÄGG</strong> &nbsp;
          
          
        Kombinera posters i olika storlekar och stilar för att skapa en dynamisk tavelvägg. Vi erbjuder posters i flera format. Utforska vårt sortiment på vår inspirationssida och tagga din poster på Instagram med @Postertopia!


        </Typography>
        <Typography variant="body1" align="justify" gutterBottom className={classes.paragraph}
         style={{
          marginBottom: "0px",
          fontSize: isMobileView?"14px":"15px"
        }}
        >
        <strong>MOTIV MED MÄNNISKOR, DJUR, NATUR, ARKITEKTUR OCH MER</strong> &nbsp;
        I vårt sortiment hittar du motiv som inkluderar människor, djur, natur och mer. Vi strävar efter att erbjuda en varierad samling konstverk för att skapa en unik atmosfär i ditt hem. Utforska vårt utbud och ge ditt hem en personlig touch med våra inspirerande konstverk!
        </Typography>
      </Grid>
    </Grid>
  );
  
  const navigate = useNavigate();
  const [opacity, setOpacity] = useState(0);
  const classes = useStyles();


  const [hoveredIndex, setHoveredIndex] = useState(null);
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const [open, setOpen] = React.useState(false);

  const [isDropdownVisible, setDropdownVisible] = React.useState(false);


const handleDropdownOpen = () => {
  
  setOpenDropDownDivPoster("visible")

};

const handleDropdownClose = () => {
  
  setOpenDropDownDivPoster("hidden")
};
  
  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  

  const Carousel = ({ title, images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const handleChangeIndex = (index, indexLatest) => {
      if (index > indexLatest && index >= (images.length / 2)) {
        setCurrentIndex(0);
      } else if (index < indexLatest && index === -1) {
        setCurrentIndex((images.length / 2) - 1);
      } else {
        setCurrentIndex(index);
      }
    };
  
    return (        
    <div>


    <Typography variant="h5" align="center" gutterBottom
    style={{
      position:"relative",
      top:"4px",
      fontWeight: "bolder",
      marginBottom: "20px",
      fontSize: "30px"
    }}
    >
      {title}
    </Typography>
    <div className={classes.sectionDivider} />
      <SwipeableViews 
        enableMouseEvents 
        index={currentIndex} 
        onChangeIndex={handleChangeIndex}
      >
        {Array(Math.ceil(images.length / 2)).fill().map((_, index) => {
          const firstImage = images[2 * index];
          const secondImage = images[2 * index + 1];
          return (

            <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
              
              <Link to={`${firstImage.imageId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img loading="lazy" src={firstImage.imageUrl} alt={firstImage.title} style={{ width: '90%', height: 'auto' }}/>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '6px' }}>
                
                  <p1 style={{ top:"5px" }}>{firstImage.title}</p1>

                  <div style={{ marginTop: "6px" }}>
                    <p1>Från 149 kr </p1><span style={{ textDecoration: 'line-through', color: 'red' }}>200kr</span>
                  </div>
                </div>
              </div>
              </Link>

  
              {secondImage && (
                <Link to={`${secondImage.imageId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img loading="lazy" src={secondImage.imageUrl} alt={secondImage.title} style={{ width: '90%', height: 'auto' }}/>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '6px' }}>
                    
                    <p1 style={{ top:"5px" }}>{secondImage.title}</p1>

                    <div style={{ marginTop: "6px" }}>
                      <p1>Från 149 kr </p1><span style={{ textDecoration: 'line-through', color: 'red' }}>200kr</span>
                    </div>
                  </div>
                </div>
                </Link>
              )}
              
            </div>
          );
        })}
      </SwipeableViews>
      </div>
    );
  };
  
  
  const renderImageSection = (title, images, heightScale = 1, widthScale = 1, show=1) => (
    <>
    {show===1 && (
      <>
        <Typography variant="h5" align="center" gutterBottom
        style={{
          position:"relative",
          top:"4px",
          fontWeight: "bolder",
          fontSize: "29px"
        }}
        >
          {title}
        </Typography>
        <div className={classes.sectionDivider} />
      </>
    )}
      <Grid container spacing={1}>
        {images.map((image, index) => (
          <Grid item key={index} xs={12 / widthScale}>
            <motion.Card
            >
              
              {show === 1 ? (
          <motion.div whileHover={{ scale: 1.0 }}>
              <Link to={`${image.imageId}`}>   {/* assuming 'path' is your intended route */}
            <motion.img

              whileHover={{ scale: 1.0 }}
              whileTap={{ scale: 0.98 }}
              src={hoveredIndex === title+index ? image.hoverImageUrl : image.imageUrl}
              height={300 / heightScale}
              style={{ width: isMobileView ? "85%":'90%',
              height: isMobileView ? "85%":'100%',
              marginTop: isMobileView ? "20px":'0px',
              objectFit: 'cover' }}
              alt={`${image.alt} Image ${index}`}
              onMouseEnter={() => handleMouseEnter(title+index)}
              onMouseLeave={() => handleMouseLeave}
            />
          </Link>
          {/* <motion.img
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.98 }}
            src={hoveredIndex === title+index ? image.hoverImageUrl : image.imageUrl}
            height={300 / heightScale}
            style={{ width: isMobileView ? "85%":'90%',
            height: isMobileView ? "85%":'100%',
            marginTop: isMobileView ? "20px":'0px',
            objectFit: 'cover' }}
            alt={`${title} Image ${index}`}
            onMouseEnter={() => handleMouseEnter(title+index)}
            onMouseLeave={() => handleMouseLeave}
          /> */}
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '6px' }}>
        <p1
          style={{
            top:"5px"
          }}
        >{image.title}</p1>
        <div
        style={{
          marginTop: "6px"
        }}
        >
        <p1>Från 149kr </p1><span style={{ textDecoration: 'line-through', color: 'red' }}>200kr</span>
        </div>
        
      </div>

        </motion.div>
      ) : (


        <motion.div
        whileHover={{ opacity: 0.90, scale: 1.003 }}
        whileTap={{ scale: 0.98 }}
        style={{ position: 'relative' }}
        onClick={()=>{
          props.setDisplayPage('dashboard')
          props.setDisplayStyle(image.style)
        }}
      >
        <Link to={`/${image.style}/`} id="link">
        <img
        loading="lazy"
          src={image.imageUrl}
          height={300 / heightScale}
          style={{ width: '100%', objectFit: 'cover', borderRadius: "8px", marginTop: show === 3 && index ===0 ? "40px":0}}
          alt={`${image.alt} Image ${index}`}
        />

        
        
        <Button
          variant="contained"
          color="primary"
          align="center"
          style={{
            fontWeight: "bold",
            position: 'absolute',
            top: '78%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1, width: '150px', height: '60px', fontSize: '18px', background: 'black', borderRadius: "5px",
            boxShadow: '0px 1px 1px 1px rgba(70, 70, 70, 0.1)',
            width: show === 0 && isMobileView ? '92px' : '150px', 
            fontSize: show === 0 && isMobileView ? '13.3px' : "17px"
          }}
          onClick={() => {
            // handle button click here
          }}
        >
          {show === 2 && index ===0 ?  "Abstrakt" : null}
          {show === 2 && index ===1 ?  "Fotografi" : null}
          {show === 3 && index ===0 ?  "Illustration" : null}
          {show === 0 && index ===0 ?  "Fantasy" : null}
          {show === 0 && index ===1 ?  "Pop-art" : null}
          {show === 0 && index ===2 ?  "Cyberpunk" : null}
        </Button>
        </Link>
      </motion.div>
  

      )}
            </motion.Card>
          </Grid>
        ))}
      </Grid>
    </>
  );



    return (
      <div 
      style={{
        position: "relative",
        left: "0px",
      }}
      className={classes.root}>
      <>
      
      <Box 
      onMouseEnter={handleDropdownOpen}
      onMouseLeave={handleDropdownClose}
      className={classes.customDropdown}
      style={{visibility:openDropDownDivPoster}}
      >


      </Box>
    </>
    <Link to={`/rabatt`}>
    <div 
    
    onClick={
      ()=>{
        navigate("/rabatt")

      }
    }
    className={classes.saleDiv} style={{ display: "flex", flexDirection: "column", top: isMobileView?"27px":"27px" }}>
      
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <h3
              style={{
                top: isMobileView?"32px":"25px",
                position: "relative",
                fontSize:isMobileView?"21px":"20px",
                fontWeight: "bold"

              }}
            >{props.discountPercentage*100}% rabatt på posters</h3>
          </Grid>
        </Grid>
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <p
            style={{
              top: "-4px",
              position: "relative"
            }}
            >*Gäller t.o.m. 16/02 </p>
          </Grid>
        </Grid>
        <Grid container justify="center" alignItems="center">
          <Grid 
          style={{
            position: "relative",
            top: "-30px"
          }}
          item>
            <Countdown endDate={"2024-02-16"}/>
          </Grid>
        </Grid>
      </div>
      </Link>



    <Container maxWidth="xl" disableGutters className={classes.container}
    style={{ marginBottom: "80px" }}
    >
        <Grid container spacing={0} justifyContent="center">
          <Grid item xs={12}>
            <Card
            style={{
              border: 'none',        // Added this line
              boxShadow: 'none',
            }}
            >
              <div style={{ position: 'relative', width: "100%" }}>
                <CardMedia
                  className={classes.cardMedia}
                  component="img"
                  alt="Main Image"
                  image={ImageMain}
                  title="Main Image"
                  style={{
                    height: isMobileView ? '600px' : '600px',
                    left:  isMobileView ? '0px' : '0px',
                    position: "relative",
                    marginTop: "116px",
                    borderRadius: "20px"
                  }}
                />
                <Typography variant="h4" 
                    style={{
                        fontSize: isMobileView ? '7.2vw' : '42px',
                        fontWeight: 'bolder',
                        position: 'absolute',
                        top: isMobileView ? '50%' : '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        zIndex: 100, // Increased the zIndex value
                        width: isMobileView ? '90%' : 'auto',
                        textAlign: 'center',
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)', // Added text shadow for better visibility
                    }}
                >
                    SKAPA DIN UNIKA STIL
                </Typography>

                <Link
                
                to={`/topplista/`} id="link">
                
                <Button
                  onClick={()=>{
                      props.togglePage()
                  }}
                  variant="contained" 
                  color="primary"
                  style={{ 
                      top: isMobileView ? '400px' : '380px',
                      position: 'absolute',  
                      left: '50%', 
                      transform: 'translate(-50%, -50%)', 
                      zIndex: 100, // Increased the zIndex value
                      width: '200px', 
                      height: '60px', 
                      fontSize: '20px', 
                      background: 'black',
                      color: 'white', // Ensuring the text color contrasts well with the black background
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)', // Added shadow around the buttons
                      borderRadius: '5px' // Rounded corners can sometimes make the button look more clickable
                  }}
              >
                  SHOPPA NU
              </Button>

                </Link>
                <ArrowAnimation/>
              </div>
            </Card>
          </Grid>
        </Grid>
    </Container>

    <Box>
      <Container>

      </Container>
    </Box>
    <Box mt={6}>
      <Container style={{ marginBottom: '70px' }}>

      {
    isMobileView 
    ? <Carousel title={'Topplista'} images={topplista} />
    : renderImageSection('Topplista', topplista.slice(0, 6), 1, isMobileView ? 2 : 6)
    }
      </Container>
    </Box>
    <Box mt={6}>
      <Container style={{ marginBottom: '70px' }}>
        {renderImageSection('Fantasy', middleSectionImages, 0.70, isMobileView ? 3 : 3, 0)}
      </Container>
    </Box>
    <Box mt={6}>
      <Container style={{ marginBottom: '80px' }}>
      {
    isMobileView 
    ? <Carousel title={'Nyheter'} images={nyheter} />
    : renderImageSection('Nyheter', nyheter.slice(0, 6), 1, isMobileView ? 2 : 6)
    }
      </Container>
    </Box>
    <Box mt={6}>
      <Container style={{ marginBottom: '10px' }}>
        {renderImageSection('New Section', newSectionImages, 0.5, isMobileView ? 1 : 2, 2)}
      </Container>
    </Box>
    {!isMobileView?<Box mt={-6}>
      <Container style={{ marginBottom: '10px' }}>
        {renderImageSection('New Section', newSectionImageLarge, 0.53, 1, 3)}
      </Container>
    </Box>:null}

    <Box mt={6}>
    <Container maxWidth="xl" disableGutters className={classes.container} style={{ marginBottom: "px" }}>
        <Typography variant="h4" align="center" gutterBottom className={classes.heading}>
        POSTERS ONLINE HOS POSTERTOPIA
        </Typography>
      </Container>
    <Container maxWidth="md" style={{ marginBottom: '0px' }}>
      {renderParagraphs()}
    </Container>
    </Box>



    <div
    style={{
      position: "relative",
    }}
    >
    <SubSectionsCard
    isMobileView={isMobileView}/>
    {isMobileView?<BottomSectionMobile setCookieConsentGiven={props.setCookieConsentGiven} setOpenCookieBar={props.setOpenCookieBar} />:<BottomSection setCookieConsentGiven={props.setCookieConsentGiven} setOpenCookieBar={props.setOpenCookieBar}
    isMobileView={isMobileView}
    />}

    </div>
    <ScrollToTopButton />
  </div>
);


};

export default LandingPage;
