import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Container } from '@material-ui/core';
import FreeShippingIcon from '@material-ui/icons/LocalShipping';
import FastDeliveryIcon from '@material-ui/icons/LocalShipping';
import PaymentIcon from '@material-ui/icons/Payment';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: "70px",
    justifyContent: 'space-around',
    marginBottom: "20px",
    position: "relative"
  },
  root: {
    margin: theme.spacing(1),
    borderRadius: 0,
    border: 'none',
    boxShadow: 'none',
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: '2.0rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',  // Change this size to whatever you want for mobile screens
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.9rem',
    marginLeft: "10%"
  },
  sectionTitleMobile: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    flexDirection: 'column',
  },
}));

const SubSectionsCard = ({isMobileView}) => {
  const classes = useStyles();

  return (
    <Container className={classes.cardContainer} style={{display: 'flex', flexWrap: isMobileView ? 'wrap' : 'nowrap'}}>
      <Card className={classes.root} style={{flexBasis: isMobileView ? '45%' : '24%', maxWidth: isMobileView ? '45%' : '24%', paddingLeft: "0px"}}>
        <CardContent>
          <Typography variant="h6" className={isMobileView ? classes.sectionTitleMobile : classes.sectionTitle}>
            <FreeShippingIcon className={classes.icon} />
            Fraktfritt över 349 kr
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.root} style={{flexBasis: isMobileView ? '45%' : '24%', maxWidth: isMobileView ? '45%' : '24%'}}>
        <CardContent>
          <Typography variant="h6" className={isMobileView ? classes.sectionTitleMobile : classes.sectionTitle}>
            <FastDeliveryIcon className={classes.icon} />
            Snabb leverans 1-3 dagar
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.root} style={{flexBasis: isMobileView ? '45%' : '24%', maxWidth: isMobileView ? '45%' : '24%'}}>
        <CardContent>
          <Typography variant="h6" className={isMobileView ? classes.sectionTitleMobile : classes.sectionTitle}>
            <PaymentIcon className={classes.icon} />
            Säkra betalningar med Klarna
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.root} style={{flexBasis: isMobileView ? '45%' : '24%', maxWidth: isMobileView ? '45%' : '24%'}}>
        <CardContent>
          <Typography variant="h6" className={isMobileView ? classes.sectionTitleMobile : classes.sectionTitle}>
            <NewReleasesIcon className={classes.icon} />
            Nyheter varje vecka
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SubSectionsCard;
