import React, { useState } from 'react';
import { Button, IconButton } from '@material-ui/core';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const colors = [
  { name: 'svart', code: '#000000' },
  { name: 'vit', code: '#FFFFFF' },
  { name: 'grå', code: '#808080' },
  { name: 'röd', code: '#FF0000' },
  { name: 'blå', code: '#0000FF' },
  { name: 'gul', code: '#FFFF00' },
  { name: 'grön', code: '#008000' },
  { name: 'orange', code: '#FFA500' },
  { name: 'lila', code: '#800080' },
  { name: 'brun', code: '#A52A2A' },
  { name: 'rosa', code: '#FFC0CB' },
  { name: 'turkos', code: '#40E0D0' },
  { name: 'guld', code: '#FFD700' },
  { name: 'silver', code: '#C0C0C0' },
];

const ColorFilter = (props) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedColor, setSelectedColor] = useState('');

  const handleColorClick = (colorName) => {
    if (colorName === selectedColor) {
      setSelectedColor('');  // If the current color is already selected, unselect it
      props.setSelectedColor('')
    } else {
      setSelectedColor(colorName);  // If it's a different color, select the new one
      props.setSelectedColor(colorName);
    }
    // Add your filtering logic here
  };

  return (
    <div
    style={{
      width: isMobileView ? "100%" : "100%"
    }}
    >
      <h4
      style={{
        fontSize: isMobileView? "22px": "20px",
        fontWeight: "bold"
      }}
      >Färg</h4>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '10px',
        }}
      >
        {colors.map((color) => (
          <Button
            key={color.name}
            variant={props.selectedColor === color.name ? 'contained' : 'outlined'}
            color={props.selectedColor === color.name ? 'primary' : 'default'}
            onClick={() => handleColorClick(color.name)}
            style={{
              borderRadius: "10px",
              width: isMobileView? "85px":"110px",
              fontSize: isMobileView? "15px":"15px"
            }}
          >
            <IconButton
              style={{
                color: color.code,
                padding: 0,
                margin: isMobileView?"-3px":'0 7px',
              }}
            >
              <FiberManualRecordIcon />
            </IconButton>
            {color.name}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default ColorFilter;
