import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Typography, Grid, Divider,   Accordion,
  AccordionSummary,
  AccordionDetails,
  ExpandMoreIcon, } from '@material-ui/core';
import IconVisa from './static/svg/visa_landingpage.png'
import IconMastercard from './static/svg/mastercard_landing.png'
import IconSwish from './static/svg/swish_landing.png'
import IconKlarna from './static/svg/klarna_landing.png'
import useMediaQuery from '@mui/material/useMediaQuery';

import { Link as RouterLink } from "react-router-dom"; 


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1f2029',
    padding: theme.spacing(4, 0),
    marginTop: "-10px",
  },

  IconsBottom: {
    marginTop: "16px",
    marginLeft: "8px",
    marginRight: "8px",
    marginBottom: "0px",
    width: "50px"
  },
  bottomIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
  },
  clickableDiv: {
    textAlign: 'center', 
    cursor: 'pointer',
    fontSize: '15px',

    '&:hover': {
      textDecoration: 'underline',
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    fontSize: '15px',
    '&:hover': {
      textDecoration: 'underline',
    },

  },
  list: {
    listStyleType: 'none',
  },
  linkSection: {
    color: "#ededed",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Changed from 'flex-start' to 'center'
    [theme.breakpoints.down(600)]: {
      position: "relative",
      left: "-6%",
    },

  },
  title:{
    position: "relative",
    left: "20px",
    fontWeight: "bold",
    color: "#ededed"
  },
}));

const BottomSection = ({isMobileView,setOpenCookieBar,setCookieConsentGiven}) => {
  const classes = useStyles();
  const isTabletView = useMediaQuery('(max-width:1200px)');

  return (
    <Box className={classes.root}>
      <Container 
        style={{
          maxWidth: isTabletView ? "500px" : "700px",
        }}
      >
        <Divider style={{ marginBottom: "40px" }} />
        <Grid container justifyContent="space-between" spacing={3}>

  
          <Grid item className={classes.linkSection} xs={6} sm={3}>
            <Typography variant="h6" className={classes.title}>Om oss</Typography>
            <ul className={classes.list}>
              <li>
                <RouterLink to="/1-om-oss" className={classes.link}>Om oss</RouterLink>
              </li>
              <li>
                <RouterLink to="/1-posters" className={classes.link}>Posters</RouterLink>
              </li>
            </ul>
          </Grid>
  
          <Grid item className={classes.linkSection} xs={6} sm={3}>
            <Typography variant="h6" className={classes.title}>Sociala media</Typography>
            <ul className={classes.list}>
              <li><a href="https://www.instagram.com/postertopiase/" className={classes.link}>Instagram</a></li>
              <li><a href="https://www.facebook.com/people/PosterTopia/100092724335980/" className={classes.link}>Facebook</a></li>
              <li><a href="https://twitter.com/postertopia" className={classes.link}>Twitter</a></li>
            </ul>
          </Grid>
  
          <Grid item className={classes.linkSection} xs={6} sm={3}>
            <Typography variant="h6" className={classes.title}>Kundservice</Typography>
            <ul className={classes.list}>
              <li>
                <RouterLink to="/1-kontakta-oss" className={classes.link}>Kontakta oss</RouterLink>
              </li>
              <li>
                <RouterLink to="/1-vanliga-fragor" className={classes.link}>Vanliga frågor</RouterLink>
              </li>
              <li>
                <RouterLink to="/1-kopvillkor" className={classes.link}>Köpvillkor</RouterLink>
              </li>
              <li>
                <RouterLink to="/1-leverans" className={classes.link}>Leverans</RouterLink>
              </li>
              <li>
                <RouterLink to="/1-cookiepolicy" className={classes.link}>Cookiepolicy</RouterLink>
              </li>
              <li>
                <RouterLink to="/1-integritetspolicy" className={classes.link}>Integritetspolicy</RouterLink>
              </li>
              <div 
              className={classes.clickableDiv}
              onClick={()=>
              {setCookieConsentGiven(false)}}>
               Hantera cookies
              </div>

            </ul>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: "40px", backgroundColor: "#cfcaca" }} />
      </Container>
  
      <Container>
        <Grid item>
          <Typography style={{ marginTop: "25px", fontWeight: "bold", color: "#ededed" }}>
            Copyright © 2023, PosterTopia AB
          </Typography>
          <div className={classes.bottomIconContainer}>
            <img src={IconVisa} className={classes.IconsBottom} alt="Visa" />
            <img src={IconMastercard} className={classes.IconsBottom} alt="Mastercard" />
            <img src={IconKlarna} className={classes.IconsBottom} alt="Klarna" />
            <img src={IconSwish} className={classes.IconsBottom} alt="Swish" />
          </div>
        </Grid>
      </Container>
    </Box>
  );
  


};

export default BottomSection;
