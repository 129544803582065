import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Switch,
    FormControlLabel,
    Typography,
    Grid,
    Divider
} from '@material-ui/core';
import CookieIcon from '@mui/icons-material/Cookie';
import useMediaQuery from '@mui/material/useMediaQuery';

const CookieConsent = ({ cookieConsentGiven, setCookieConsentGiven, analyticsConsent, setAnalyticsConsent, isConsentDialogOpen, setIsConsentDialogOpen }) => {
    const isMobileView = useMediaQuery('(max-width:800px)');

    const handleAccept = () => {

        setIsConsentDialogOpen(false);
    };


    return (
        <Dialog
            open={isConsentDialogOpen}
            onClose={handleAccept}
            aria-labelledby="cookie-consent-title"
            fullWidth
            style={{
                maxWidth:isMobileView?"95%": "40%",
                left: isMobileView?"2%": "30%"
            }}
        >
            <DialogTitle id="cookie-consent-title"
            style={{
                
            }}
            >Cookie Samtycke</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    Vi använder cookies för att förbättra din upplevelse på vår webbplats.
                </Typography>
                <Divider style={{ margin: "20px 0" }} />

                <Typography variant="h6">Nödvändiga cookies</Typography>
                <Typography variant="body2">
                    Dessa cookies är nödvändiga för att webbplatsen ska fungera korrekt och går inte att stänga av.
                </Typography>
                <Grid container spacing={3} alignItems="center" style={{ marginTop: "10px" }}>
                    <Grid item>
                        <CookieIcon />
                    </Grid>
                    <Grid item xs>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={true}
                                    disabled={true}
                                />
                            }
                            label="På"
                        />
                    </Grid>
                </Grid>

                <Divider style={{ margin: "20px 0" }} />

                <Typography variant="h6">Analys cookies</Typography>
                <Typography variant="body2">
                    Dessa cookies hjälper oss att förstå hur besökare interagerar med vår webbplats, upptäcka problem och förbättra användarupplevelsen.
                </Typography>
                <Grid container spacing={3} alignItems="center" style={{ marginTop: "10px" }}>
                    <Grid item>
                        <CookieIcon />
                    </Grid>
                    <Grid item xs>
                        <FormControlLabel
                            control={<Switch checked={analyticsConsent} onChange={() => setAnalyticsConsent(prev => !prev)} />}
                            label={analyticsConsent ? "På" : "Av"}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAccept} color="black" variant="contained">
                    Fortsätt
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CookieConsent;
