import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import ImageRectangleHorizontal from '../images/gallery/rektangel_liggande.jpg'
import ImageSquare from '../images/gallery/kvadrat.jpg'
import ImageRectangleVertical from '../images/gallery/rektangel_staende.jpg'



const shapes = [
  { name: 'Liggande', id: '3:2', image: ImageRectangleHorizontal },
  { name: 'Stående', id: '2:3', image: ImageRectangleVertical },
  { name: 'Kvadrat', id: '2:2', image: ImageSquare },
];

const ShapeFilter = (props) => {
  const [selectedShape, setSelectedShape] = useState('');

  const handleShapeClick = (shapeId) => {
    if (shapeId === selectedShape) {
      setSelectedShape('');  // If the current shape is already selected, unselect it
      props.setSelectedShape('')
    } else {
      setSelectedShape(shapeId);  // If it's a different shape, select the new one
      props.setSelectedShape(shapeId)
    }
    // Add your filtering logic here
  };

  return (
    <div>
      <h4>Format</h4>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
        }}
      >
        {shapes.map((shape) => (
          <Button
  key={shape.id}
  variant={props.selectedShape === shape.id ? 'contained' : 'outlined'}
  color={props.selectedShape === shape.id ? 'primary' : 'default'}
  onClick={() => handleShapeClick(shape.id)}
  style={{
    borderRadius: "15px"
  }}
>
  <div style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

  }}>
    <img src={shape.image} alt={shape.name} width="60" style={{
      marginBottom: "5px",
    }} />
    {shape.name}
  </div>
</Button>


        ))}
      </div>
    </div>
  );
};

export default ShapeFilter;
