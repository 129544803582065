/* global fbq */
import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/Gallery.css";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import { motion } from "framer-motion";
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageWithFrame from "./ImageWithFrame";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';



const useStyles = makeStyles({
  blackButton: {
    width: "150px",
    borderRadius: "12px",
    fontSize: "16px",
    height: "50px",
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: 'gray',
    },
  },
});

function selectImages(signedUrls, imageGroupId){
  
  
  if (Array.isArray(signedUrls)) {
    const filteredByCategory = signedUrls.filter(
      (item) => item.imageGroupId === imageGroupId
    );
    
    return filteredByCategory
  }
}


function Gallery({ signedUrls, selectedStyle, selectedCategory,toggleState,discountPercentage, searchValue, setScrollPosition,handleScrollPosition, imageDisplayUrl, imageDisplayUrls,searchResult, loadCount, setLoadCount, ...props }) {
  const isMobileView = useMediaQuery('(max-width:750px)');
  const isTabletView = useMediaQuery('(max-width:810px)');
  const [displayImages, setDisplayImages] = useState([]);
  //const [loadCount, setLoadCount] = useState(25);
  const navigate = useNavigate();


  const handleContextMenu = (event) => {
    event.preventDefault(); // prevent the default context menu from appearing
  };
  const classes = useStyles();

  
  function filterOnTags(data, searchText){
    if (data!==undefined){
      let filteredData = data.filter(item => item.tags.includes(searchText));
      return filteredData
    }

  }

  useEffect(() => {
    
    
    if (searchResult && Array.isArray(searchResult) && searchResult.length>0){
      setDisplayImages(searchResult)
    }
  }, [searchResult]);

  // useEffect(() => {
  //   setDisplayImages(imageDisplayUrl)
  //   scrollToTopSmooth()
  
  // }, [imageDisplayUrl]);

  useEffect(() => {
    
    
    
    
    const styleType =selectedStyle.slice(-2)
    if (selectedStyle === "" && selectedCategory === "") {
      
      if (Array.isArray(signedUrls)) {
        const filteredByCategory = signedUrls.filter(
          (item) => item.imageType === "main"
        );
        setDisplayImages(filteredByCategory);
      }
    }


    const tiltTransition = {
      type: "spring",
      stiffness: 100,
      damping: 20,
    };

    const tiltMotionVariants = {
      hover: {
        rotateX: [-rotatingPower, rotatingPower],
        rotateY: [rotatingPower, -rotatingPower],
      },
      rest: {
        rotateX: 0,
        rotateY: 0,
      },
    };
    
    if (selectedStyle !== "" && selectedCategory === "") {
      
      
      if (Array.isArray(signedUrls)) {
        if(styleType === "-1"){
          
          const filteredByCategory = signedUrls.filter(
            (item) => item.category === selectedStyle.slice(0,-2) && item.imageType === "main"
          );
          setDisplayImages(filteredByCategory);
        }
        else{
          const filteredByCategory = signedUrls.filter(
            (item) => item.style === selectedStyle && item.imageType === "main"
          );
          setDisplayImages(filteredByCategory);
        }
      }
    }
    if (selectedStyle !== "" && selectedCategory !== "") {
      
      if (Array.isArray(signedUrls)) {
        const filteredByCategory = signedUrls.filter(
          (item) =>
            item.style === selectedStyle &&
            item.category === selectedCategory &&
            item.imageType === "main"
        );
        setDisplayImages(filteredByCategory);
      }
    }
    if (selectedStyle === "" && selectedCategory !== "") {
      
      if (Array.isArray(signedUrls)) {
        const filteredByCategory = signedUrls.filter(
          (item) => item.category === selectedCategory && item.imageType === "main"
        );
        setDisplayImages(filteredByCategory);
      }
    }
    if (selectedStyle === "topplista") {
      
      if (Array.isArray(signedUrls)) {
        const favoritesImages = signedUrls.filter(
          (item) => item.favorites === true && item.imageType === "main"
        );
        setDisplayImages(favoritesImages);
      }}
    if (selectedStyle === "nyheter") {
      if (Array.isArray(signedUrls)) {
        const newImages = signedUrls
          .filter((item) => item.imageType === "main")
          .slice(0, 50); // this selects the first 50 items in the filtered array
        setDisplayImages(newImages);
      }
    }
    if (selectedStyle === "rabatt") {
      console.log("rabatt items")
      
      if (Array.isArray(signedUrls)) {
        const discountImages = signedUrls.filter(
          (item) => item.isDiscount === true && item.imageType === "main"
        );
        console.log(discountImages)
        setDisplayImages(discountImages);
      }}
      
    
  }, [selectedCategory, selectedStyle, signedUrls]);
  
  const tiltTransition = {
    type: "spring",
    stiffness: 90,
    damping: 10,
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  const scrollToTopSmooth = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}

  const rotatingPower = 3;
  const tiltMotionVariants = {
    hover: {
      rotateX: [-rotatingPower, rotatingPower],
      rotateY: [rotatingPower, -rotatingPower],
    },
    rest: {
      rotateX: 0,
      rotateY: 0,
    },
  };

function findImageById(array, imageId) {
    return array.find(obj => obj.imageId === imageId);
  }
  
const [imageSize, setImageSize] = useState({ width: 'auto', height: 'auto' });
  const handleShowMore = () => {
    setLoadCount(loadCount + 25);
  };
  
  const cardWidth = isMobileView ? 'calc(50% + 80px)' : '25vw';
  const [mousePositions, setMousePositions] = useState({});

  const handleMouseMove = (event, index) => {
    const rect = {
      left: event.currentTarget.getBoundingClientRect().left,
      top: event.currentTarget.getBoundingClientRect().top,
      width: event.currentTarget.offsetWidth,
      height: event.currentTarget.offsetHeight,
    };
  
    const x = -(rect.width / 2 - event.clientX + rect.left);
    const y = -(rect.height / 2 - event.clientY + rect.top);
  
    setMousePositions((prevMousePositions) => ({
      ...prevMousePositions,
      [index]: { x, y },
    }));
  };
  
  function aspectRationWidth(aspectRatio){
    if (isMobileView){
      if (aspectRatio==="2:2"){
        return "90%"
      }
      if (aspectRatio==="2:3"){
        return "95%"
      }
      if (aspectRatio==="3:2"){
        return "70%"
      }
    }
    else{
      if (aspectRatio==="2:2"){
        return "125%"
      }
      if (aspectRatio==="2:3"){
        return "125%"
      }
      if (aspectRatio==="3:2"){
        return "125%"
      }
    }

    
  }

  function aspectRationHeight(aspectRatio){
    if (isMobileView){
      if (aspectRatio==="2:2"){
        return "100%"
      }
      if (aspectRatio==="2:3"){
        return "125%"
      }
      if (aspectRatio==="3:2"){
        return "100%"
      }
    }
    else{
      if (aspectRatio==="2:2"){
        return "100%"
      }
      if (aspectRatio==="2:3"){
        return "125%"
      }
      if (aspectRatio==="3:2"){
        return "100%"
      }
    }
  }

return (
  <div
    style={{
      marginLeft: isTabletView ? '4vw': '200px',
      position: "relative",
      left: isMobileView ? '0vw' :"2%",
      width: "75vw"
    }}
  >
    <Grid container spacing={isMobileView?10:5}>

    { displayImages === undefined ? 
        // Render CircularProgress if displayImages is undefined
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',position: "relative" }}>
          <CircularProgress />
        </div>
        : displayImages.slice(0, loadCount).map((imageData, index) => {

        const rect = { width: parseFloat(cardWidth), height: 200 };
        const aspectRatio = rect.width / rect.height;

        const tiltX = (mousePositions[index]?.y ?? 0) / rect.height * 3;
        const tiltY = -(mousePositions[index]?.x ?? 0) / rect.width * 3;

        return (
          
          <Grid 
          style={{
            position: "relative",
            left: isMobileView? "0%": "3%",
            pointerEvents: 'none'

          }}
          item xs={isMobileView ? 6 : 12} sm={isMobileView? 6:6} md={isMobileView? 4:4} key={index} className="cardWrapper">
          
          <Card

            style={{
              width: cardWidth,
              perspective: '1200px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: "14px",
              marginTop: isMobileView?"-10px":"10px",
              marginBottom: isMobileView?"-10px":"10px",
              width: isMobileView?"45vw":"80%",
              marginLeft: isMobileView?"0px":"50px",
              border: 'none',        // Added this line
              boxShadow: 'none', 
              pointerEvents: 'auto'



              
            }}
            onMouseMove={(event) => handleMouseMove(event, index)}
            onMouseEnter={(event) => handleMouseMove(event, index)}
            onMouseLeave={() => setMousePositions({ ...mousePositions, [index]: { x: 0, y: 0 } })}
          >
                  
                  <motion.div
                    style={{
                      marginTop: "23px",
                      width: isMobileView ? '100%' : '65%',
                      height: "98%",
                      position: "relative",
                      left: isMobileView?"-25%":"-26px",
                    }}
                    initial={{ scale: 1, opacity: 0, tiltX: 1, tiltY: 1 }}
                    whileHover={{
                      scale: 1.099,
                    }}
                    animate={{
                      opacity: 1,
                      rotateX: tiltX * 3.3,
                      rotateY: tiltY * 1.6,
                      boxShadow: `25px 20px 30px rgba(0, 0, 0, ${Math.abs(tiltX + tiltY) / 100})`,
                      z: -30, // Move the element closer to the viewer in the z-axis
                    }}
                    exit={{ scale: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <img

                      onLoad={(event) => {
                        const aspectRatio = event.target.naturalHeight / event.target.naturalWidth;
                        

                        // Store the aspect ratio in state or a variable for later use
                      }}
                      style={{
                        maxHeight: "370px",
                        height: isMobileView ? aspectRationHeight(imageData.aspectRatio) : aspectRationHeight(imageData.aspectRatio),
                        width: isMobileView ? aspectRationWidth(imageData.aspectRatio) : aspectRationWidth(imageData.aspectRatio),
                        objectFit: 'contain',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: isMobileView ? null :'0px 4px 10px rgba(0, 0, 0, 0.4)',
                        position: "relative",
                        left: (isMobileView && imageData.aspectRatio=="2:2")? "30%":(isMobileView && imageData.aspectRatio=="2:3")? "27%":10,

                      }}
                      onContextMenu={handleContextMenu}
                      value={imageData.imageGroupId}
                      src={toggleState ? imageData.framedImageUrls[props.frameIndex] : imageData.signedUrl}
                      alt="Image"
                      onClick={() => {
                        const currentScrollPos = window.scrollY;
                        setScrollPosition(currentScrollPos)
                        if (window.gtag) {
                          window.gtag('event', 'image_click', {
                              'style': imageData.style,
                              'category': imageData.category,
                              'imageId': imageData.imageId
                          });
                          if (window.fbq) {
                            fbq('track', 'ViewContent', {
                                content_name: imageData.title,
                                content_category: imageData.style,
                                content_ids: [imageData.imageId],
                                content_type: imageData.category,
                                value: 149,
                                currency: 'SEK'
                            });
                        }
                        
                      }
                        navigate(`/${imageData.style}/${imageData.category}/${imageData.imageId}`)
                        scrollToTop()
                      }
                      
                      }
                    />

                
              </motion.div>
              <CardContent style={{ backgroundColor: 'transparent' }}>

                <Typography
                            onClick={(e) => {
                              handleScrollPosition()
                            const displayImagesBig = selectImages(
                                signedUrls,
                                e.target.getAttribute('value'),
                              );
                              props.setImageDisplayUrls(displayImagesBig);
                              
                            }}
                  variant="h6" color="text.secondary" className="truncate" style={{ fontSize: isMobileView?15:19, marginBottom: isMobileView?"10px":"0px",}}>
                    {imageData.title}
                  </Typography>

                  <Typography
                    variant="body1"
                    color="text.secondary"
                    style={{ marginTop: '2px', marginBottom: "-5px", fontSize: isMobileView?"14px":"15px", left:imageData.isDiscount?"-10px":0, position: 'relative' }}
                  >
                    <p1>{imageData.isDiscount}</p1>
                    {imageData.isDiscount ? (
                    <>
                        <p1>Från {Math.round(149 * (1 - discountPercentage))} kr </p1>
                        <span style={{ textDecoration: 'line-through', color: 'red' }}>149kr</span>
                    </>
                ) : <p1>Från {149} kr </p1>}

                    </Typography>
      </CardContent>
      <CardActions 
      style={{
        position: "absolute",
        bottom: "-5px",
        right: isMobileView?'0%':"10%"
      }}
      disableSpacing>
      <IconButton
      style={{
        zIndex: 1000
      }}
      aria-label="add to favorites"
      onClick={() => {
        if (props.favoritePoster.some(poster => poster.imageId === imageData.imageId)) {
          props.setFavoritePoster(props.favoritePoster.filter(poster => poster.imageId !== imageData.imageId));
        } else {
          props.setFavoritePoster([...props.favoritePoster, imageData]);
        }
      }}
        >
    {props.favoritePoster.some(poster => poster.imageId === imageData.imageId) ? 
      <FavoriteIcon style={{ color: '#eb3477' }} /> : 
      <FavoriteBorderIcon  />
    }
    </IconButton>
    </CardActions>
    </Card>
    </Grid>


          );
        }) }
      </Grid>
      {displayImages ? loadCount < displayImages.length && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleShowMore}
          style={{ marginTop: '40px', marginBottom: "40px", left: isMobileView? "15%":0,

        }}
        className={classes.blackButton}
        >
          Visa mer
        </Button>
      ): null}
    </div>
  );
  





}
export default Gallery;
