import React from 'react';
import { Container, Typography } from '@material-ui/core';
import ConstructionIcon from '@material-ui/icons/Build';  // Construction-like icon from Material-UI

export default function PageUnderConstruction() {
    return (
        <Container style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '40vh', marginBottom: "200px"}}>
            <ConstructionIcon style={{fontSize: '100px'}}/>
            <Typography variant="h4" align="center">
                Denna sida är under konstruktion
            </Typography>
            <Typography variant="body1" align="center">
                Vi ber om ursäkt för besväret. Var vänlig och besök en annan sida.
            </Typography>
        </Container>
    );
}
