/* global fbq */

import './App.css';
import LandingPage from './LandingPage';
import Dashboard from './Dashboard';
import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button,Link, Popover,Drawer, TextField,Tooltip,Typography, AppBar, Toolbar, Divider, InputBase,Grid,Card, CardMedia, CardContent,  List, ListItem, ListItemText, Box  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AmarilloRegular from './styles/DancingScript-VariableFont_wght.ttf';
import { motion } from "framer-motion";
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconSwe from './static/svg/Flag_of_Sweden.png'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from "@mui/icons-material/Favorite";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { AnimatePresence } from 'framer-motion';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ChatBox from './ChatBox';
import FavoritePosters from './FavoritePosters';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import clsx from 'clsx';
import Checkout from './components/Checkout';
import { Link as RouterLink } from "react-router-dom";
import { initializeAnalytics } from './analytics';
import ImageDropdown1 from "./images/dropdown_image.png"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { width } from '@mui/system';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import CookieConsent from './CookieConsent';
import CookieSnackbar from './CookieSnackbar';

// animation settings
const floatVariant = {
  hidden: { y: 10 },
  visible: {
    y: -10,
    transition: {
      duration: 1,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatType: 'reverse',
    },
  },
};


const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'New Font, sans-serif',
  },
  appBar: {
    zIndex:theme.zIndex.drawer + 1, 
    height: 90,
    backgroundColor: '#ffffff !important',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  appBar2: {
    zIndex:theme.zIndex.drawer + 1, 
    height: 45,
    backgroundColor: '#ffffff !important',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
  },
  appBarLinks: {
    top: "26px",
    right: "0vw"
  },

  container: {
    marginTop: 64,
  },
  cardMedia: {
    width: '100%',
    height: '100%',
  },
  heading: {
    fontSize:"30px",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  sectionDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  paragraph: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  searchInput: {
    top:"2px",
    height:"30px",
    right:"31vw",
    width: "260px",
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius, // Use theme's default border radius

    // Optional: Add padding if you want some space between the border and the text
    padding: theme.spacing(1),
  },
  typography: {
    position: "absolute",
    bottom:"14px",
    left:"60px",
    fontSize: "28px",
    fontFamily: [
      'Dancing Script',
      'cursive'
    ].join(','),
  },
  veckansTitle: {
    position:"relative",
    marginTop: "50px",
    fontSize: '26px', // Adjust this value to set the desired font size
    marginBottom: theme.spacing(2), 
  },
  linkButton: {
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
  customDropdown: {
    position: 'fixed',
    top: '90px',
    left: '0',
    background: 'white',
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2),
    zIndex: theme.zIndex.appBar + 10,
    width: '100%',

  },
  customDropdownVisible: {
    display: 'block',
  },
  popper: {
    zIndex: theme.zIndex.appBar + 1,
    width: '100%',
  },
  fullWidthGrid: {
    width: '100%',
  },
  appBarDivider: {
    borderTop: '1px solid #ccc', // Change the color and thickness as desired
    width: '100%',
  },
  appBarTextInfo: {
    fontSize: "12px",
    color: "black",

  },
  link: {
    color: 'black',
    textAlign: 'right',  // Change to the color you want
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  list: {
    textAlign: 'left', 
    listStyleType: 'none',
    columnCount: 2,  // Number of columns
    columnGap: '0.8em',  // Gap between columns 
    maxHeight: '280px',  // Maximum height of the list

    overflow: 'auto',  // Scroll if content exceeds maxHeight
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [AmarilloRegular],
      },
    },
  },
}));


function App() {
  const classes = useStyles();
  
  const [displayPage, setDisplayPage] = useState('landing');
  const [signedUrls, setSignedUrls] = useState();
  const isMobileView = useMediaQuery('(max-width:800px)');
  const isTabletView = useMediaQuery('(max-width:1200px)');
  const [menuOpen, setMenuOpen] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);

  const [openOverlayLoad, setOpenOverlayLoad] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [openAIChat, setOpenAIChat] = useState(false);
  const iconVariants = {
    openMenu: { rotate: 0, opacity: 1 },
    closedMenu: { rotate: 180, opacity: 0 },
    openClose: { rotate: 360, opacity: 1 },
    closedClose: { rotate: 180, opacity: 0 },
  };

  const discountPercentage = 0.2

  const [existingWords, setExistingWords] = useState([
    "katt",
    "hund",
    "gorilla",
    //... your words
  ])
  const [cookieConsentGiven, setCookieConsentGiven] = useState(localStorage.getItem('cookieConsentGiven') === 'true');

  const [analyticsConsent, setAnalyticsConsent] = useState(
    localStorage.getItem('cookieAnalyticsConsent') ? localStorage.getItem('cookieAnalyticsConsent') === 'true' : true
  );


   // or fetch from localStorage if needed
  const [isConsentDialogOpen, setIsConsentDialogOpen] = useState(false);
  const [openCookieBar, setOpenCookieBar] = useState(true);

  const scrollToPosition = (scrollPosition) => {

    window.scrollTo(0, scrollPosition);
  };


  const [openShoppingCart, setOpenShoppingCart] = useState(false);
  
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleOpenPopover = (event) => {
    anchorRef.current = event.currentTarget;
    setIsOpen(true);
  };

  const handleClosePopover = () => {
    
    setIsOpen(false);
  };



  const [openBasketDrawer, setOpenBasketDrawer] = useState(false);

  const openDrawerBasket = () => {
    setOpenBasketDrawer(true);
    
  };

  const closeDrawerBassket = () => {
    setOpenBasketDrawer(false);
  };

  function calculateColumns(linksLength, maxRows) {
    if (linksLength <= 4) return 1;
    return Math.ceil(linksLength / maxRows);
}


  const handleRemoveItem = (itemId, itemSize) => {
    setItemBasket((prevBasket) =>
      prevBasket.filter(
        (item) => item.id !== itemId || item.size !== itemSize
      )
    );
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  async function fetchImageUrls() {
    const primaryEndpoint = 'https://backend-photo-gallery-hfzyyicqga-lz.a.run.app/signed-url'; 


    let data = null;


    try {
      const response = await axios.get(primaryEndpoint);
      console.log(response.status)
      data = response.data.signed_urls;
    } catch (primaryError) {
      console.error("Error with primary endpoint:", primaryError);
    }

    if (data) {
      shuffleArray(data);
      const aspectRatioOrder = ["2:3", "2:2", "3:2"];
      console.log(data)

      // Sort the data based on the defined order
      data.sort((a, b) => {
        return aspectRatioOrder.indexOf(a.aspectRatio) - aspectRatioOrder.indexOf(b.aspectRatio);
      });

      setSignedUrls(data);

      const existingWords = [...data.reduce((allTags, currentObject) => {
        const { tags } = currentObject;
        const lowerCaseTags = tags.map(tag => tag.toLowerCase());
        return new Set([...allTags, ...lowerCaseTags]);
      }, new Set())];
      
      setExistingWords(existingWords);
    }
  }
  useEffect(() => {
    document.title = 'PosterTopia | Unika Svenska Posters Online';

   

    fetchImageUrls();
}, []);

const [backButtonClicked, setBackButtonClicked] = useState(false)

useEffect(() => {
  const handleBackButtonEvent = (e) => {
    console.log("scrollPosition", scrollPosition)
    e.preventDefault();
    // The back button was clicked
    console.log("Back button was clicked!");
    if(scrollPosition!==0){
      console.log("Start rescroll")
      setBackButtonClicked(true)
      scrollToPosition(scrollPosition)
    }
  };
  
  window.addEventListener("popstate", handleBackButtonEvent);

  return () => {
    // Remove the listener when the component is unmounted
    window.removeEventListener("popstate", handleBackButtonEvent);
  };
}, []);
  
  const [openDropDownDivPoster, setOpenDropDownDivPoster] = useState("hidden")

  let closeTimer;

  const handleDropdownOpen = () => {
    if (closeTimer) clearTimeout(closeTimer);  // clear any existing close delay
    setOpenDropDownDivPoster("visible");
  };
  
  const handleDropdownClose = () => {
    closeTimer = setTimeout(() => {
      setOpenDropDownDivPoster("hidden");
    }, 400);  // 300ms delay
  };
  useEffect(() => {
    return () => {
      if (closeTimer) clearTimeout(closeTimer);
    };
  }, []);
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [favoritePoster, setFavoritePoster] = useState(() => {
    const storedData = localStorage.getItem('favoritePoster');
    return storedData ? JSON.parse(storedData) : [];
});

  const [openFavoritePopover, setOpenFavoritePopover] = useState(false)

  const handleClickFavorite = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFavorite = () => {
    setAnchorEl(null);
    
  };
  const [open, setOpen] = React.useState(false);
  const [openBasket, setOpenBasket] = React.useState(false);
  
  const openFavorite = Boolean(anchorEl);
  const id = openFavorite ? 'simple-popover' : undefined;
  

  const togglePage = () => {
    setDisplayPage(displayPage === 'landing' ? 'dashboard' : 'landing');
  }
  const [displayStyle, setDisplayStyle] = useState("")
  const [openDrawer, setOpenDrawer] = useState(true)
  const [searchValue, setSearchValue] = useState('');
  const pageToDisplay = displayPage === 'landing' ? <LandingPage discountPercentage={discountPercentage} setCookieConsentGiven={setCookieConsentGiven} setOpenCookieBar={setOpenCookieBar} openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} togglePage={togglePage} setDisplayPage={setDisplayPage} setDisplayStyle={setDisplayStyle} /> : <Dashboard  searchValue={searchValue} discountPercentage={discountPercentage} setScrollPosition={setScrollPosition} scrollPosition={scrollPosition} setMenuOpen={setMenuOpen} openSidebar={openSidebar} openDrawerBasket={openDrawerBasket}  favoritePoster={favoritePoster} setFavoritePoster={setFavoritePoster} displayStyle={displayStyle} openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} signedUrls={signedUrls} togglePage={togglePage} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>;
  const [inputValue, setInputValue] = useState('');

  const [checkout, setCheckout] = useState(false);
  const [itemBasket, setItemBasket] = useState(() => {
    const storedData = localStorage.getItem('itemBasket');
    return storedData ? JSON.parse(storedData) : [];
});

useEffect(() => {
    // Convert the array to a string and store it in a cookie:
    try {
      
      localStorage.setItem('favoritePoster', JSON.stringify(favoritePoster));

  } catch (e) {
      console.error("Failed to save favoritePoster to localStorage", e);
  }
  
}, [favoritePoster]);

useEffect(() => {
  if (itemBasket.length > 0) {
      localStorage.setItem('itemBasket', JSON.stringify(itemBasket));
  }
  // Optionally, if you want to clear the localStorage entry when the basket is empty:
  else {
      localStorage.removeItem('itemBasket');
  }
}, [itemBasket]);


const [utmParams, setUtmParams] = useState({});

useEffect(() => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  // Extract UTM parameters
  const utmSource = urlParams.get('utm_source');
  const utmMedium = urlParams.get('utm_medium');
  const utmCampaign = urlParams.get('utm_campaign');

  // Check if UTM parameters exist in the URL
  if (utmSource || utmMedium || utmCampaign) {
    setUtmParams({
      utmSource,
      utmMedium,
      utmCampaign
    });
    console.log({
      utmSource,
      utmMedium,
      utmCampaign
    })
  }

}, [window.location.search]);

useEffect(() => {

  // if(analyticsConsent && analyticsConsent===true && cookieConsentGiven===true ){
  //   console.log("start: Analytics")
  //   initializeAnalytics();
  // }else{
  //   console.log("stop: Analytics")
  // }
  
  
  try {
    const storedData = localStorage.getItem('favoritePoster');
    const retrievedArray = storedData ? JSON.parse(storedData) : [];
    
    setFavoritePoster(retrievedArray)
} catch (e) {
    console.error("Failed to retrieve favoritePoster from localStorage", e);
}
}, []);

useEffect(() => {

  if(analyticsConsent && analyticsConsent===true && cookieConsentGiven===true ){
    console.log("start: Analytics")
    initializeAnalytics();
  }else{
    console.log("stop: Analytics")
  }
  
}, [analyticsConsent]);



  const sections = [
    {
      title: 'POSTERS',
      links: [
        { name: 'Topplistan', route: '/topplista' },
        { name: 'Nyheter', route: '/nyheter' },
        { name: 'Rabatt', route: '/rabatt' },
      ],
    },
    {
      title: 'KATEGORIER',
      links: [
        { name: 'Djur', route: '/animals-1' },
        { name: 'Illustrationer', route: '/illustration' },
        { name: 'Abstrakt', route: '/abstract' },
        { name: 'Natur', route: '/nature-1' },
        { name: 'Fotokonst', route: '/photography' },
        { name: 'Framtid', route: '/futuristic' },
      ],
    },
    {
      title: 'MOTIV',
      links: [
        { name: 'Hund', route: '/search/hund' },
        { name: 'Katt', route: '/search/katt' },
        { name: 'Tiger', route: '/search/tiger' },
        { name: 'Groda', route: '/search/groda' },
        { name: 'Frukt', route: '/search/banan+jordgubbar+blåbär+vattenmelon+persika+citron+apelsin' },
        { name: 'Häst', route: '/search/häst' },
        { name: 'Capybara', route: '/search/capybara' },
        { name: 'Katt', route: '/search/katt' },
        { name: 'Får', route: '/search/får' },
        { name: 'Kvinna', route: '/search/kvinna' },
        { name: 'Hamster', route: '/search/hamster' },
        { name: 'Björn', route: '/search/björn' },
      ],
    },
  ];
  

  const currentSections = isTabletView ? sections : sections;

  const handleTooltipClose = () => {
    setOpen(false);
  };
  
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleBasketTooltipClose = () => {
    setOpenBasket(false);
  };
  
  const handleBasketTooltipOpen = () => {
    setOpenBasket(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    
    <div className="App"
    style={{
      position:"relative",
      left: isMobileView?"0%":"0px",
      marginRight: isMobileView?"0px":"0px"

    }}
    >
      <Router>
      <Box>

    <AppBar position="fixed" className={classes.appBar}>
    {!checkout && (


    <Toolbar className={classes.appBarLinks}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between">
      <Grid 
      style={{
        position: "relative",
        top: "30px"
      }}
      item>
        <Box display="flex">

          {!isMobileView && (
            <>
            <RouterLink to="/topplista" variant="body2">
          <Button
            style={{
              position:"relative",
              marginLeft: isMobileView ? "0px":"-0px",
              left: isMobileView ? "-50%":"0px",
              height: "40px"
            }}
            onMouseEnter={handleDropdownOpen}
            onMouseLeave={handleDropdownClose}
            className={classes.linkButton}
            onClick={()=>{

            }}
          >
            POSTERS
          </Button>
          </RouterLink>
            </>
          )}

            <RouterLink to="/topplista" variant="body2">
                <Button 
                style={{
                  left: isMobileView ? "-74%":"0px",
                }}
                className={classes.linkButton}>TOPPLISTAN</Button>
            </RouterLink>
            <RouterLink to="/nyheter" variant="body2">
                <Button
                 style={{
                  left: isMobileView ? "-96%":"0px",
                }}
                className={classes.linkButton}>NYHETER</Button>
              </RouterLink>


          {!isMobileView && (
            <>

            <RouterLink to="/posterpaket" variant="body2">
                <Button className={classes.linkButton}>POSTERPAKET</Button>
            </RouterLink>
            </>
          )}


        </Box>

        
        <Box sx={{ marginRight: 2 }}>








      <Toolbar>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{ 
          position: "relative",
          top: isMobileView ? "-50px" :isTabletView? "-50px": "-50px",
          left: isMobileView ? "26vw" : isTabletView? "28vw": "32vw",
       }}
      >
    <Grid item>
      <Box
        sx={{ marginLeft: isMobileView? 0:2  }}
        onClick={() => {
          setOpenAIChat(!openAIChat);
        }}
      >
        <motion.div
          whileHover={{ scale: 1.12, transition: { duration: 0.3 } }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            setOpenAIChat(!openAIChat);
          }}
        >
          <SupportAgentIcon
            sx={{ width: isMobileView ? "50px" : "65px", color: "#161825", position: "relative", top: isMobileView?'3px':"0px" }}
          />
        </motion.div>
      </Box>
    </Grid>

    <Grid item>
      <Tooltip
        title="My tooltip text"
        classes={{ tooltip: clsx(classes.tooltip) }}
        open={open}
        onClose={handleTooltipClose}
        title={
          <div
            style={{
              width: '260px',
              maxHeight: "500px",
              minHeight: "250px",
              overflow: 'auto'
            }}
          >
            <div></div>
            {favoritePoster.length === 0 ? (
              <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%' // adjust this as per your need
              }}>
                <FavoriteBorderIcon style={{ fontSize: 40, color: "black", position: "relative", marginTop: "55px",marginBottom: "20px" }} />
                <p
                  style={{
                    fontSize: "17px"
                  }}
                >Du har inga favoriter än.</p>
              </div>
            ) : null}
            {favoritePoster.map((poster) => (
              <div style={{width: "245px",}}>
                <Card key={poster.id} style={{margin: "10px"}}>

                  <Grid container>
                    <Grid item xs={4}>
                      <CardMedia
                        component="img"
                        image={poster.signedUrl}
                        alt={poster.title}
                        style={{
                          width: "66px",
                          position: "relative",
                          left: "20px",
                          top: "0px",
                          marginBottom: "8px",
                          marginTop: "6px"
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <CardContent
                        style={{
                          position: "relative",
                          left: "10px"
                        }}
                      >
                        <IconButton
                        onClick={() => {
                          const updatedFavorites = favoritePoster.filter(favPoster => favPoster.imageId !== poster.imageId);
                          setFavoritePoster(updatedFavorites);
  
                        
                        }}
                        color="secondary"
                        style={{
                          position: "absolute",
                          top: isMobileView?2:0,
                          right:  isMobileView?8:6,
                          margin: 1,
                          width: isMobileView ? '30px' : '30px',   // Adjust these values as needed
                          height: isMobileView ? '30px' : '30px',  // Adjust these values as needed
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                        <Typography gutterBottom variant="subtitle2" component="div">
                        <li style={{listStyleType: "none",  color: "black"}}>
                          <RouterLink to={"/"+poster.category+"/"+poster.category+"/"+poster.imageId} variant="body2">
                          {poster.title}
                          </RouterLink>
                          </li>
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Från 149kr
                        </Typography>
                        <FavoriteIcon style={{ fontSize: 20, position: "relative", top:"10px" , color: '#eb3477' }}/>
                      </CardContent>
                    </Grid>
                  </Grid>
                </Card>
              </div>
            ))}
          </div>
        }
        arrow
        interactive
        leaveDelay={300}
      >
        <Button
          onMouseEnter={handleTooltipOpen}
          style={{ width: "40px", height: "40px", zIndex:9 }}
        >
          {favoritePoster.length > 0 ?
            <FavoriteIcon sx={{
              color: '#161825', 
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }} /> 
            :
            <FavoriteBorderIcon sx={{
              color: "black",
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }} /> 
          }
        </Button>
      </Tooltip>
    </Grid>

    <Grid item>
      <Box
        sx={{ marginLeft: 2 }}
        onClick={() => {
          
        }}
      >
        <motion.div
          whileHover={{ scale: 1.12, transition: { duration: 0.3 } }}
          whileTap={{ scale: 0.9 }}
          onClick={handleOpenPopover}
        >
          <Button
            onClick={openDrawerBasket}
            style={{ width: "20px" }}
          >
            <ShoppingCartIcon
              sx={{
                color: "#161825",
                width: isMobileView ? "50px" : "55px",
              }}
            />
            <span
              style={{
                position: "relative",
                left: "-15px",
                top: "6px",
                color: "black",
                fontSize: "14px",
                fontWeight: "bolder",
              }}
            >
              {Array.isArray(itemBasket) && itemBasket.length > 0
                ? itemBasket.length
                : null}
            </span>
          </Button>
        </motion.div>
      </Box>
    </Grid>
  </Grid>
</Toolbar>

          </Box>
        </Grid>
        </Grid>
        </Toolbar>
        )}
          </AppBar>

          <Box
  onClick={isMobileView ? handleDropdownClose : null}
  onMouseEnter={!isMobileView ? handleDropdownOpen : handleDropdownOpen}
  onMouseLeave={!isMobileView ? handleDropdownClose : null}
  className={classes.customDropdown}
  style={{
    visibility: openDropDownDivPoster,
    opacity: openDropDownDivPoster ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',
    position: 'fixed',
    top: '70px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#f2f3f5',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    overflow: 'hidden',
    height:185,
    zIndex: 1000,
  }}
>
  <div className="gradient-shift" style={{ padding: '1rem' }}>  
    <Grid container spacing={4} justify="center"> 
      {currentSections.map((section, index) => {
        const columnCount = Math.ceil(section.links.length / 5); // example: 5 links per column
        return (
          <Grid item xs={12} sm={4} md={3} lg={2} key={index} style={{ marginBottom: '1rem' }}> 
            <Typography 
              style={{
                textAlign: 'center',
                fontSize: '1rem',
                marginBottom: '0.5rem',
                fontWeight: 'bold',
                marginRight: section.title === "POSTERS" ? "8%": section.title === "KATEGORIER"? "15%":null,
                
              }}
              variant="h6" 
              gutterBottom
            >
              {section.title}
            </Typography>
            <ul className={classes.list} style={{
              textAlign: 'left', 
              listStyleType: 'none',
              columnCount: columnCount,
              columnGap: '1.5rem',
              padding: 0,
            }}>
              {section.links.map((link, linkIndex) => (
                <li key={linkIndex} style={{ marginBottom: '0.5rem' }}>
                  <RouterLink 
                    to={link.route} 
                    className={classes.link} 
                    style={{
                      color: '#333',
                      textDecoration: 'none',
                      marginLeft: section.title == "POSTERS" ? "30%":null,
                      fontWeight: '500',
                      '&:hover': {
                        textDecoration: 'underline',
                      }
                    }}
                  >
                    {link.name}
                  </RouterLink>
                </li>
              ))}
            </ul>
          </Grid>
        );
      })}
    </Grid>
  </div>
</Box>





          <AppBar position="fixed"
            className={classes.appBar2} elevation={0}>
              
            <Divider />
            <Box className={classes.appBarDivider} />
            <Toolbar>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  
                  <Box display="flex" alignItems="center" position="relative"  alignItems="center" // Align items vertically (default is 'stretch')
                justifyContent="space-between" // Align items horizontally (can also use 'center', 'flex-start', 'flex-end', 'space-around', etc.)
                  
                  style={{
                    position: "absolute",
                    left: isMobileView? "88%":"-28vw",
                    top: isMobileView? "16px":"16px",
                  }}
                  >
                    { (
                           <>
                        <form 
                          onSubmit={(e) => {
                            e.preventDefault();
                            setSearchValue(inputValue);

                            if (window.gtag) {
                              window.gtag('event', 'search_input', {
                                'input': inputValue,
                              });
                              fbq('track', 'search_input', {'input': inputValue})
                          }
       
                          }}
                        >
                          <Autocomplete
                            options={existingWords}
                            onInputChange={(event, newInputValue) => {
                              setInputValue(newInputValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label=""
                                size="small"
                                
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
            
                                    </InputAdornment>
                                  ),
                                  style: {
                                    position: "relative",
                                    height: '35px',  // or whatever height you prefer
                                    width: isMobileView? "33vw": isTabletView ? "190px":"250px",
                                    top: isMobileView?"-4px":"-2px"

                                  },
                                }}
                                style={{
 
                                }}
                              />
                            )}
                          />
                        </form>


                         </>
                         
                    )}
                    {isMobileView && (
                           <>
                           <div
                           
                           style={{
                            position: "absolute",
                            right: "83vw",
                            top:"-13px",
                            display: "flex"
                           }}
                           >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={() => {

                                    setMenuOpen(!menuOpen);
                                    setOpenSidebar(!openSidebar);
                                   
                                }}
                            >

                      <AnimatePresence>
                      {/* {
                      !menuOpen && window.location.pathname === '/' && (
                        <RouterLink 
                          to={'/topplista'} 
                          className={'in'} 
                          variant="body2"
                        >
                          <motion.div
                            initial="closedMenu"
                            animate="openMenu"
                            exit="closedMenu"
                            variants={iconVariants}
                          >
                            <MenuIcon style={{ fontSize: 25 , color: '#161F25', }} />
                          </motion.div>
                        </RouterLink>
                      )
                    } */}
                                   {
                      !menuOpen && window.location.pathname !== '/' && (
          
                          <motion.div
                            initial="closedMenu"
                            animate="openMenu"
                            exit="closedMenu"
                            variants={iconVariants}
                          >
                            <MenuIcon style={{ fontSize: 25 , color: '#161F25', }} />
                          </motion.div>

                      )
                    }
                        {menuOpen && window.location.pathname !== '/' &&(
                          <motion.div
                            initial="closedClose"
                            animate="openClose"
                            exit="closedClose"
                            variants={iconVariants}
                          >
                            <CloseIcon style={{ fontSize:25, color: '#161F25' }} />
                          </motion.div>
                        )}
                      </AnimatePresence>
                      </IconButton>
          
                           </div>

                         </>
                    )}
                  </Box>
                </Grid>




                <Grid item xs>
                  <Box 
                  onClick={()=>{
                    
                    setDisplayPage("landing")
                  }}
                  
                  display="flex" alignItems="center" justifyContent="center">
                    
                    <RouterLink to={`/`} id="link">
                    <Typography
                      variant="h6"
                      className={classes.typography}
                      style={{ 
                        
                        color: '#161825', fontWeight: 'bolder',
                        position: "relative",
                        left: isMobileView ? "-11px": "0%",
                        top: "-2px",
                        fontSize: isMobileView?"32px":"38px"
                      
                      }}
                      onClick={()=>{

                        
                      }}
                    >
                      PosterTopia
                    </Typography>
                    </RouterLink>
                  </Box>
                </Grid>


                <Drawer anchor="right" open={openBasketDrawer} onClose={closeDrawerBassket}>
                  <div
                    style={{
                      position: "relative",
                      width: isMobileView?"65vw":"350px",
                      height: "200px",  // add some height  // add a background color
                    }}
                  >
                  <Checkout
                  
                    onRemoveItem={handleRemoveItem}
                    itemBasket={itemBasket}
                    setCheckout={setCheckout}
                    setOpenOverlayLoad={setOpenOverlayLoad}
                    setOpenBasketDrawer={setOpenBasketDrawer}
                  />
                  </div>
                </Drawer>
                {!isMobileView && (
                  <>
                    <Grid
                      style={{
                        position: "relative",
                        left: "28vw",
                        top: "-15px"

                      }}
                      item
                    >
                      <Box display="flex" alignItems="center" position="absolute" right="0">
                        <Typography className={classes.appBarTextInfo}
                        
                        style={
                          {
                            height: "20px",
                            width: "160px"
                          }
                        }
                        >
                          Leverans 1-3 arbetsdagar
                          Fraktfritt över 349 kr
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      style={{

                      }}
                      item
                    >
                      <Box display="flex" alignItems="center" position="absolute" right="0">
                        <motion.img
                          className='SweIcon'
                          whileHover={{ scale: 1.16 }}
                          whileTap={{ scale: 0.98 }}
                          style={{
                            width: "25px",
                            position: "absolute",
                            right: "-29vw",
                            top:"-8px"
                          }}
                          src={IconSwe}
                        ></motion.img>
                      </Box>
                    </Grid>
                  </>
                )}
              <CookieConsent 
                  cookieConsentGiven={cookieConsentGiven} 
                  setCookieConsentGiven={setCookieConsentGiven}
                  analyticsConsent={analyticsConsent}
                  setAnalyticsConsent={setAnalyticsConsent}
                  isConsentDialogOpen={isConsentDialogOpen}
                  setIsConsentDialogOpen={setIsConsentDialogOpen}
              />
              <CookieSnackbar
                  setOpenCookieBar={setOpenCookieBar}
                  openCookieBar={openCookieBar}
                  analyticsConsent={analyticsConsent}
                  cookieConsentGiven={cookieConsentGiven} 
                  setAnalyticsConsent={setAnalyticsConsent}
                  setCookieConsentGiven={setCookieConsentGiven}
                  setIsConsentDialogOpen={setIsConsentDialogOpen}
              />

              </Grid>
            </Toolbar>
            <div
            style={{
              visibility: openAIChat? "visible": "hidden"
            }}
            >
              <ChatBox
              setOpenChat={setOpenAIChat}
              isMobileView={isMobileView}
              />
            </div>
            
          </AppBar>
          <Routes
          >
            <Route path="/" element={<LandingPage discountPercentage={discountPercentage} setCookieConsentGiven={setCookieConsentGiven} setOpenCookieBar={setOpenCookieBar} openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} togglePage={togglePage} setDisplayPage={setDisplayPage} setDisplayStyle={setDisplayStyle} />} />
            <Route path="/search/:searchTerms/*" element={<Dashboard discountPercentage={discountPercentage} setScrollPosition={setScrollPosition} scrollPosition={scrollPosition} setCookieConsentGiven={setCookieConsentGiven} setOpenCookieBar={setOpenCookieBar} setMenuOpen={setMenuOpen} menuOpen={menuOpen} openDrawerBasket={openDrawerBasket}  itemBasket={itemBasket} setItemBasket={setItemBasket} setCheckout={setCheckout} checkout={checkout} searchValue={searchValue} setSearchValue={setSearchValue} favoritePoster={favoritePoster} setFavoritePoster={setFavoritePoster} displayStyle={displayStyle} openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} signedUrls={signedUrls} togglePage={togglePage} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>} />
            <Route path="/:style/:category?/:imageId?/*" element={<Dashboard discountPercentage={discountPercentage} setScrollPosition={setScrollPosition} scrollPosition={scrollPosition} setCookieConsentGiven={setCookieConsentGiven} setOpenCookieBar={setOpenCookieBar} setMenuOpen={setMenuOpen} menuOpen={menuOpen} openDrawerBasket={openDrawerBasket}  itemBasket={itemBasket} setItemBasket={setItemBasket} setCheckout={setCheckout} checkout={checkout} searchValue={searchValue} setSearchValue={setSearchValue} favoritePoster={favoritePoster} setFavoritePoster={setFavoritePoster} displayStyle={displayStyle} openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} signedUrls={signedUrls} togglePage={togglePage} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>} />

          </Routes>
      </Box>
      </Router>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openOverlayLoad}
          onClick={null}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <motion.div
              variants={floatVariant}
              initial="hidden"
              animate="visible"
              style={{ marginBottom: '40px' }}
            >
              <MonetizationOnIcon style={{ fontSize: 90 }} />
            </motion.div>
            <CircularProgress color="inherit" />
          </Box>
        </Backdrop>
    <div>

    </div>

    </div>
  );
}



export default App;
