import React, { useState } from 'react';
import { Radio, FormControlLabel, FormControl } from '@material-ui/core';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import StarIcon from '@mui/icons-material/Star';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const sortingOptions = [
  { name: 'Shoppa Favoriter', id: 'favorites', icon: <StarIcon /> },
  { name: 'Lägst Pris', id: 'lowestPrice', icon: <TrendingDownIcon /> },
  { name: 'Högst Pris', id: 'highestPrice', icon: <TrendingUpIcon /> },
  { name: 'Publiceringsdatum', id: 'publishDate', icon: <CalendarMonthIcon /> },
];

const SortingFilter = (props) => {
  const [selectedSorting, setSelectedSorting] = useState('');

  const handleSortingChange = (event) => {
    setSelectedSorting(event.target.value);
    // Add your sorting logic here
  };

  return (
    <div>
      <h4>Sortera efter</h4>
      <FormControl component="fieldset">
        {sortingOptions.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.id}
            control={<Radio />}
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {option.icon}
                <span style={{ marginLeft: '4px' }}>{option.name}</span>
              </div>
            }
            checked={selectedSorting === option.id}
            onChange={handleSortingChange}
          />
        ))}
      </FormControl>
    </div>
  );
};

export default SortingFilter;
