import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles';

const fadeOut = {
    initial: { opacity: 1 },
    exit: { opacity: 0 }
};

const useStyles = makeStyles((theme) => ({
    loadingOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255,255,255,0.9)', // white with slight transparency
        zIndex: 1000, // ensure it's above other items
    },
}));

const LoadingOverlay = ({ duration }) => {
    const classes = useStyles();
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsVisible(false);
        }, duration);

        return () => clearTimeout(timeout);
    }, [duration]);

    return (
        <>
            {isVisible && (
                <motion.div
                    initial="initial"
                    exit="exit"
                    transition={{ duration: duration / 1000,
                                ease: "easeInOut" }} // Convert duration from ms to seconds
                    variants={fadeOut}
                    className={classes.loadingOverlay}
                ></motion.div>
            )}
        </>
    );
}

export default LoadingOverlay;
