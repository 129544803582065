import React from 'react';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '65vw',
    margin: '0 auto',
    padding: theme.spacing(2),
    [theme.breakpoints.down(600)]: {
      width: '88vw',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function CommonQuestions() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
      style={{
        marginBottom: "50px",
      }}
      variant="h4" gutterBottom>
        Vanliga Frågor
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            VARFÖR HAR JAG INTE FÅTT EN ORDERBEKRÄFTELSE VIA E-POST?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Om du har gjort en beställning hos Postertopia men inte mottagit din orderbekräftelse kan det bero på att det har blivit något fel med din e-postadress. Det kan också vara så att din orderbekräftelse har hamnat i ditt skräppostfilter. Om du inte hittar den där kan du kontakta vår kundtjänst så hjälper vi dig.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
        <Typography className={classes.heading}>
          NÄR DRAS PENGARNA FRÅN MITT KONTO?
        </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Pengarna dras från ditt konto så snart ditt köp har genomförts hos Postertopia. Om du gör ditt köp före kl. 13 samma dag kommer din beställning att skickas samma dag.
          </Typography>
        </AccordionDetails>
        </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
          VILKEN TYP AV PAPPER ANVÄNDER NI?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography>
        Vi på Postertopia värderar hög kvalitet och hållbarhet. Därför använder vi ett premium 200g/m² matt papper av fotokvalitet för alla våra affischer. Detta garanterar inte bara livfulla och klara färger utan även lång livslängd och motståndskraft mot blekning över tid.
      </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
          VILKEN LEVERANSTID HAR NI?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Leverans av poster sker vanligtvis mellan 1-3 arbetsdagar.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Add other questions here */}
    </div>
  );
}
