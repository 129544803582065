import React from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '60vw',
    margin: '0 auto',
    [theme.breakpoints.down(600)]: {
      maxWidth: '90vw',
    },
  },
}));

export default function AboutUs() {

    const classes = useStyles();

    return (
      <Container className={classes.root}>
        <Typography
        style={{
          marginBottom: "50px"
        }}
        variant="h4" align="center" gutterBottom>
        Om Postertopia
      </Typography>
      <Typography variant="body1" paragraph>
        Välkommen till Postertopia, den kreativa hjärtat av svensk posterkonst. Vi ligger i hjärtat av Sverige och har bidragit till landets rika affischdesign i flera år.
      </Typography>
      <Typography variant="body1" paragraph>
        Postertopia är inte bara en plattform för försäljning av affischer, utan också en hyllning till kreativitet. Här kan begåvade svenska konstnärer visa upp sitt arbete, och affischentusiaster hittar unika, tilltalande designer som ger en extra elegant touch till vilket utrymme som helst.
      </Typography>
      <Typography variant="body1" paragraph>
        Vi har en omfattande samling av affischer inom en mängd olika stilar och kategorier, inklusive abstrakt, pop-art, fotografi, fantasi, djur, människor, natur, varelser, arkitektur och fordon. Oavsett om du är intresserad av astronauter eller zebraf, stränder eller skyskrapor, bilar eller katter, har vi något för dig.
      </Typography>
      <Typography variant="body1" paragraph>
        Vår styrka ligger i vår tro på att stödja lokala konstnärer och bidra till den dynamiska svenska konstscenen. Genom Postertopia hoppas vi kunna ge dessa talangfulla individer en plattform för att dela sina kreationer med världen.
      </Typography>
      <Typography variant="body1" paragraph>
        Vi använder oss också av den senaste tekniken för att förbättra din upplevelse. Vår AI-chattbot kan hjälpa dig att hitta den perfekta postern baserat på dina beskrivningar. Genom att lära sig av dina preferenser och val kan vår AI hjälpa till att skräddarsy en mer personlig och tillfredsställande shoppingupplevelse.
      </Typography>
      <Typography variant="body1" paragraph>
        Vi välkomnar dig att utforska vår mångfacetterade samling av affischer. Fördjupa dig i Postertopias värld, där konstnärskap möter fantasi.
      </Typography>
    </Container>
  );
}
