import { motion } from 'framer-motion';
import { FaArrowDown } from 'react-icons/fa';
import { IconButton } from '@material-ui/core';

const arrowVariants = {
  initial: { scale: 1 },
  animate: { scale: 1.2 },
  transition: { duration: 0.5, yoyo: Infinity },
};

const arrowStyles = {
  position: 'absolute',
  top: '82%', // Adjust this value to move the arrow up or down
  left: '50%',
  transform: 'translate(-50%, 0)',
};

export function ArrowAnimation() {
  return (
    <motion.div variants={arrowVariants} initial="initial" animate="animate" transition="transition">
      <IconButton style={arrowStyles}>
        <FaArrowDown />
      </IconButton>
    </motion.div>
  );
}
