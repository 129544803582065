import React from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';
import CelebrationIcon from '@mui/icons-material/Celebration';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '60vw',
    margin: '100px auto',
    padding: theme.spacing(4),
    border: '2px solid #e0e0e0',
    borderRadius: '15px',
    backgroundColor: '#f5f5f5',
    [theme.breakpoints.down(600)]: {
      maxWidth: '90vw',
    },
  },
  header: {
    display: 'flex',          // Added flexbox for header
    alignItems: 'center',     // Align items vertically center
    justifyContent: 'center', // Align items horizontally center
    marginBottom: theme.spacing(3),
  },
  icon: {
    fontSize: "20px",
    marginRight: theme.spacing(1),  // Space between icon and text
  }
}));

export default function ThankYou() {
    const classes = useStyles();

    return (
      <Container className={classes.root}>
        <div className={classes.header}>
          <CelebrationIcon
          sx={{
            fontSize: "35px",
            position: "relative",
            top: "-5px"
          }}
          className={classes.icon} />
          <Typography variant="h4" gutterBottom>
            Tack!
          </Typography>
        </div>
        <Typography variant="h6" align="center" paragraph>
          Din betalning har genomförts.
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          Vi kommer att skicka ett orderbekreftelse-mail till dig inom kort med mer information.
        </Typography>
      </Container>
    );
}
