import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import AmarilloRegular from '../styles/DancingScript-VariableFont_wght.ttf';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@mui/material/ListSubheader';
import Collapse from '@mui/material/Collapse';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';

import { useTheme } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import BrushIcon from '@mui/icons-material/Brush';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PetsIcon from '@mui/icons-material/Pets';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ForestIcon from '@mui/icons-material/Forest';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import MonochromePhotosIcon from '@mui/icons-material/MonochromePhotos';
import PaletteIcon from '@mui/icons-material/Palette';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import GestureIcon from '@mui/icons-material/Gesture';
import { Button, Typography, Grid, AppBar } from '@material-ui/core';
import useMediaQuery from '@mui/material/useMediaQuery';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import "../styles/ResponsiveDrawer.css";
import IconSwe from '../static/svg/Flag_of_Sweden.png'
import FavoriteIcon from '@mui/icons-material/Favorite';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SpaIcon from '@mui/icons-material/Spa';


const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'New Font, sans-serif',
  },
  appBar: {
    zIndex:theme.zIndex.drawer + 1, 
    height: 90,
    backgroundColor: '#E0E8EA !important',
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  appBar2: {
    zIndex:theme.zIndex.drawer + 1, 
    height: 45,
    backgroundColor: '#E0E8EA !important',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
  },
  appBarLinks: {
    top: "26px",
    right: "0vw"
  },
  container: {
    marginTop: 64,
  },
  cardMedia: {
    width: '100%',
    height: '100%',
  },
  heading: {
    fontSize:"30px",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  sectionDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  paragraph: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  searchInput: {
    top:"-2px",
    height:"30px",
    right:"340px",
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius, // Use theme's default border radius

    // Optional: Add padding if you want some space between the border and the text
    padding: theme.spacing(1),
  },
  typography: {
    position: "absolute",
    bottom:"14px",
    left:"60px",
    fontSize: "24px",
    fontFamily: [
      'Dancing Script',
      'cursive'
    ].join(','),
  },
  typography: {
    position: "absolute",
    bottom:"14px",
    left:"60px",
    fontSize: "24px",
    fontFamily: [
      'Dancing Script',
      'cursive'
    ].join(','),
  },
  veckansTitle: {
    position:"relative",
    marginTop: "50px",
    fontSize: '26px', // Adjust this value to set the desired font size
    marginBottom: theme.spacing(2), 
  },
  linkButton: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  customDropdown: {
    position: 'fixed',
    top: '90px',
    left: '0',
    background: 'white',
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2),
    zIndex: theme.zIndex.appBar + 1,
    width: '100vw',
  },
  customDropdownVisible: {
    display: 'block',
  },
  popper: {
    zIndex: theme.zIndex.appBar + 1,
    width: '100%',
  },
  fullWidthGrid: {
    width: '100%',
  },
  appBarDivider: {
    borderTop: '1px solid #ccc', // Change the color and thickness as desired
    width: '100%',
  },
  appBarTextInfo: {
    position:"absolute",
    top:"20px",
    fontSize: "13px",
    color: "black"
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [AmarilloRegular],
      },
    },
  },
}));

function ResponsiveDrawer(props) {
  
  const isMobileView = useMediaQuery('(max-width:660px)');
  const isTabletView = useMediaQuery('(max-width:810px)');
  const classes = useStyles();
  const theme = useTheme();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [openPhotography, setOpenPhotography] = React.useState(false);
  const [openAbstract, setOpenAbstract] = React.useState(false);
  const [openFantasy, setOpenFantasy] = React.useState(false);
  const [openIllustration, setOpenIllustration] = React.useState(false);
  const [openFuturistic, setOpenFuturistic] = React.useState(false);
  const [openPopArt, setOpenPopArt] = React.useState(false);
  const [openBohemian, setOpenBohemian] = React.useState(false);
  const [openDropDownDivPoster, setOpenDropDownDivPoster] = useState("hidden")


  const location = useLocation();
  const activeLinkStyle = { fontWeight: "bold" };
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };
  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  // useEffect(() => {
  //   scrollToTop();
  // }, [location]);

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };



  const handleDropdownOpen = () => {
    
    setOpenDropDownDivPoster("visible")
  
  };
  
  const handleDropdownClose = () => {
    
    setOpenDropDownDivPoster("hidden")
  };
  const handleClickPhotography = () => {
    setOpenPhotography(!openPhotography);
  };
  
  const handleClickBohemian = () => {
    setOpenBohemian(!openBohemian);
  }

  const handleClickIllustration = () => {
    setOpenIllustration(!openIllustration);
  };


  const handleClickAbstract = () => {
    setOpenAbstract(!openAbstract);


  };
  
  const handleClickFuturistic = () => {
    setOpenFuturistic(!openFuturistic);
  };

  const handleClickPopArt = () => {
    setOpenPopArt(!openPopArt);
  };
  
  const [open, setOpen] = React.useState(true);
  const [searchInput, setSearchInput] = React.useState('');
  const handleSearchInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      
    }
  };
  

  const handleClick = () => {
    setOpen(!open);
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    props.setMenuOpen(!props.openSidebar)
    props.setOpenSidebar(!props.openSidebar)
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setSnackbarOpen(false);
  };
  const navigate = useNavigate()
  const buttonColor = '#59364e';
  const buttonColor2 = '#59364e';

  const scrollUp = () =>{
    window.scrollTo(0, 0);
  }


  useEffect(() => {
    // This effect will run whenever props.displayStyle changes

    // Perform any actions or logic based on the changed value of props.displayStyle
    

    // You can also perform conditional logic based on the new value
    if (props.displayStyle === 'abstract') {
      
      navigate('/abstract/');
      // Do something when displayStyle is 'grid'
    } else if (props.displayStyle === 'fantasy') {
      // Do something when displayStyle is 'list'
      navigate('/fantasy/');
    }else if (props.displayStyle === 'illustration') {
    // Do something when displayStyle is 'list'
    navigate('/illustration/');
  }else if (props.displayStyle === 'futuristic') {
    // Do something when displayStyle is 'list'
    navigate('/futuristic/');
  }else if (props.displayStyle === 'pop-art') {
    // Do something when displayStyle is 'list'
    navigate('/pop-art/');
  }

    // Clean up any resources if needed
    return () => {
      // Cleanup logic goes here (if any)
    };
  }, [props.displayStyle]);




  //navbar
  const fontSizeDrawerLinks = "0.90rem"
  const lineHeightDrawerLinks = "1.0"
  const fontSizeDrawerButtons = "1.3rem"


  const drawer = (
   <div
    sx={{
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      msOverflowStyle: 'none',  /* IE and Edge */
      scrollbarWidth: 'none',  /* Firefox */
    }}
  >
      <Toolbar />
      <Divider />
      <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginTop:"30px" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader"
        sx={{ fontSize: isMobileView?"23px":"25px" ,
        fontFamily: [
          'Dancing Script',
          'cursive'
        ].join(','),
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)', color: '#161823', position: 'relative', top: '0px', fontWeight: "bold", left: isMobileView?"40px":"-8px", fontSize: isMobileView?"30px":"25px",
      
      }}
        >
          Kategorier
        </ListSubheader>
      }
    >

            {/* Start of Style 1*/}
            <Link to={`/abstract/`} id="link">
      <ListItemButton onClick={()=>{
        props.setLoadCount(25)
        props.setScrollPosition(0)
        scrollToTop();
        setOpenPhotography(false)
        setOpenFantasy(false)
        setOpen(false)
        setOpenBohemian(false)
        setOpenPopArt(false)
        setOpenIllustration(false)
        setOpenFuturistic(false)
        props.setPageFilter1("abstract");
        props.setPageFilter2("");
        props.setImageDisplayUrls([])
        props.setCheckout(false)
        handleClickAbstract();
      }}>
        <ListItemIcon>
          <PaletteIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 

        </ListItemIcon>
        <ListItemText primary="Abstrakt" primaryTypographyProps={{ style: { fontSize: fontSizeDrawerLinks, lineHeight: lineHeightDrawerLinks} }}
 />
        {openAbstract ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      </Link>
      <Collapse in={openAbstract} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to={`/abstract/animals`} id="link">
          <ListItemButton sx={{ pl: 3.2, fontWeight: location.pathname === '/abstract/animals' ? activeLinkStyle.fontWeight : undefined }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setPageFilter1("abstract")
            props.setPageFilter2("animals")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <PetsIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor2 }} />
            </ListItemIcon>
            <ListItemText primary="Djur" primaryTypographyProps={{ style: { fontSize: fontSizeDrawerLinks, lineHeight: lineHeightDrawerLinks} }}
/>
          </ListItemButton>
          </Link>
          <Link to={`/abstract/humans`} id="link">
          <ListItemButton sx={{ pl: 3.2, color: location.pathname === '/abstract/humans' ? activeLinkStyle.color : undefined }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("abstract")
            props.setPageFilter2("humans")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <EmojiPeopleIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor2 }} />
            </ListItemIcon>
            <ListItemText primary="Människor" />
          </ListItemButton>
          </Link>
          <Link to={`/abstract/nature`} id="link">

          
          <ListItemButton sx={{ pl: 3.2, color: location.pathname === '/abstract/nature' ? activeLinkStyle.color : undefined }}
          onClick={()=>{

            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("abstract")
            props.setPageFilter2("nature")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <ForestIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor2 }} />
            </ListItemIcon>
            <ListItemText primary="Natur" />
          </ListItemButton>

          </Link>



          <Link to={`/abstract/creatures`} id="link">
          <ListItemButton sx={{ pl: 3.2, color: location.pathname === '/abstract/creatures' ? activeLinkStyle.color : undefined }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("abstract")
            props.setPageFilter2("creatures")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <SmartToyIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor2 }} />
            </ListItemIcon>
            <ListItemText primary="Varelser" />
          </ListItemButton>

          </Link>

          <Link to={`/abstract/architecture`} id="link">

          
          <ListItemButton sx={{ pl: 3.2, color: location.pathname === '/abstract/architecture' ? activeLinkStyle.color : undefined }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("abstract")
            props.setPageFilter2("architecture")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <ApartmentIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor2 }} />
            </ListItemIcon>
            <ListItemText primary="Arkitektur" />
          </ListItemButton>
          </Link>
          
        </List>
      </Collapse>

    {/* Start of Style 1*/}


    {/* Start of Style 2*/}
      <Link to={`/futuristic/`} id="link">
      <ListItemButton onClick={()=>{
        props.setLoadCount(25)
        props.setScrollPosition(0)
        setOpenPhotography(false)
        setOpenBohemian(false)
        setOpenFantasy(false)
        setOpen(false)
        setOpenPopArt(false)
        setOpenAbstract(false)
        setOpenIllustration(false)
        props.setPageFilter1("futuristic");
        props.setPageFilter2("");
        props.setImageDisplayUrls([])
        props.setCheckout(false)
        handleClickFuturistic();
      }}>
        <ListItemIcon>
          <PrecisionManufacturingIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 
        </ListItemIcon>
        <ListItemText primary="Futuristisk" primaryTypographyProps={{ style: { fontSize: fontSizeDrawerLinks, lineHeight: lineHeightDrawerLinks} }} />
        {openFuturistic ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      </Link>
      <Collapse in={openFuturistic} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to={`/futuristic/animals`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("futuristic")
            props.setPageFilter2("animals")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <PetsIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Djur" />
          </ListItemButton>
          </Link>
          <Link to={`/futuristic/humans`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("futuristic")
            props.setPageFilter2("humans")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <EmojiPeopleIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Människor" />
          </ListItemButton>
          </Link>

          <Link to={`/futuristic/nature`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("futuristic")
            props.setPageFilter2("humans")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <ForestIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Natur" />
          </ListItemButton>
          </Link>

          <Link to={`/futuristic/creatures`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("futuristic")
            props.setPageFilter2("creatures")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <SmartToyIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />  
            </ListItemIcon>
            <ListItemText primary="Varelser" />
          </ListItemButton>
          </Link>

          <Link to={`/futuristic/architecture`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("futuristic")
            props.setPageFilter2("architecture")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <ApartmentIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Arkitektur" />
          </ListItemButton>
          </Link>
          
        </List>
      </Collapse>

    {/* Start of Style 1*/}





            {/* Start of Style 3*/}


            <Link to={`/pop-art/`} id="link">
      <ListItemButton onClick={()=>{
        scrollToTop();
        props.setLoadCount(25)
        props.setScrollPosition(0)
        setOpenFuturistic(false)
        setOpenPhotography(false)
        setOpenFantasy(false)
        setOpenBohemian(false)
        setOpen(false)
        setOpenAbstract(false)
        setOpenIllustration(false)
        props.setPageFilter1("abstract");
        props.setPageFilter2("");
        props.setImageDisplayUrls([])
        props.setCheckout(false)
        handleClickPopArt();
      }}>
        <ListItemIcon>
          <BrushIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 
 
        </ListItemIcon>
        <ListItemText primary="Pop-art" primaryTypographyProps={{ style: { fontSize: fontSizeDrawerLinks, lineHeight: lineHeightDrawerLinks} }} />
        {openPopArt ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      </Link>
      <Collapse in={openPopArt} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to={`/pop-art/animals`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("pop-art")
            props.setPageFilter2("animals")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <PetsIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 
 
            </ListItemIcon>
            <ListItemText primary="Djur" />
          </ListItemButton>
          </Link>
          <Link to={`/pop-art/humans`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("pop-art")
            props.setPageFilter2("humans")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <EmojiPeopleIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 
 
            </ListItemIcon>
            <ListItemText primary="Människor" />
          </ListItemButton>
          </Link>

          <Link to={`/pop-art/nature`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("pop-art")
            props.setPageFilter2("nature")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <ForestIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 
 
            </ListItemIcon>
            <ListItemText primary="Natur" />
          </ListItemButton>
          </Link>

          <Link to={`/pop-art/creatures`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("pop-art")
            props.setPageFilter2("creatures")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <SmartToyIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 
  
            </ListItemIcon>
            <ListItemText primary="Varelser" />
          </ListItemButton>
          </Link>


          <Link to={`/pop-art/architecture`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("pop-art")
            props.setPageFilter2("architecture")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <ApartmentIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 
  
            </ListItemIcon>
            <ListItemText primary="Arkitektur" />
          </ListItemButton>
          </Link>
          
        </List>
      </Collapse>

    {/* Start of Style 1*/}






      {/* Start of Style 2*/}
      <Link to={`/photography/`} id="link">
      <ListItemButton onClick={()=>{
        props.setLoadCount(25)
        props.setScrollPosition(0)
        setOpenFuturistic(false)
        setOpenPopArt(false)
        setOpenFantasy(false)
        setOpenAbstract(false)
        setOpenBohemian(false)
        setOpenIllustration(false)
        setOpen(false)
        props.setPageFilter1("photography");
        props.setPageFilter2("");
        props.setImageDisplayUrls([])
        props.setCheckout(false)
        handleClickPhotography();
      }}>
        <ListItemIcon>
          <PhotoCameraIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 

        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ style: { fontSize: fontSizeDrawerLinks, lineHeight: lineHeightDrawerLinks} }} primary="Fotografi" />
        {openPhotography ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      </Link>
      <Collapse in={openPhotography} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to={`/photography/animals`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setPageFilter1("photography")
            props.setPageFilter2("animals")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <PetsIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Djur" />
          </ListItemButton>
          </Link>
          <Link to={`/photography/humans`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setPageFilter1("photography")
            props.setPageFilter2("humans")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <EmojiPeopleIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Människor" />
          </ListItemButton>
          </Link>

          <Link to={`/photography/nature`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("photography")
            props.setPageFilter2("nature")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <ForestIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Natur" />
          </ListItemButton>
          </Link>

          <Link to={`/photography/creatures`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("photography")
            props.setPageFilter2("creatures")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <SmartToyIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Varelser" />
          </ListItemButton>
          </Link>

          <Link to={`/photography/architecture`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("photography")
            props.setPageFilter2("architecture")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <ApartmentIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Arkitektur" />
          </ListItemButton>
          </Link>
        </List>
      </Collapse>



      {/* Start of Style 2*/}
      <Link to={`/illustration/`} id="link">
      <ListItemButton onClick={()=>{
        props.setLoadCount(25)
        props.setScrollPosition(0)
        setOpenFuturistic(false)
        setOpenPopArt(false)
        setOpenFantasy(false)
        setOpenAbstract(false)
        setOpen(false)
        setOpenBohemian(false)
        setOpenPhotography(false)
        props.setPageFilter1("illustration");
        props.setPageFilter2("");
        props.setImageDisplayUrls([])
        props.setCheckout(false)
        handleClickIllustration();
        props.setLoadCount(25)
      }}>
        <ListItemIcon>
          <GestureIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 

        </ListItemIcon>
        <ListItemText primary="Illustration"primaryTypographyProps={{ style: { fontSize: fontSizeDrawerLinks, lineHeight: lineHeightDrawerLinks} }}/>
        {openIllustration ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      </Link>
      <Collapse in={openIllustration} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to={`/illustration/animals`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("illustration")
            props.setPageFilter2("animals")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <PetsIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />  
            </ListItemIcon>
            <ListItemText primary="Djur" primaryTypographyProps={{ style: { fontSize: fontSizeDrawerLinks, lineHeight: lineHeightDrawerLinks} }}
 />
          </ListItemButton>
          </Link>
          <Link to={`/illustration/humans`} id="link">
          <ListItemButton sx={{ pl: 4, backgroundColor: 'transparent' }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("illustration")
            props.setPageFilter2("humans")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <EmojiPeopleIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 
            </ListItemIcon>
            <ListItemText primary="Människor" />
          </ListItemButton>
          </Link>

          <Link to={`/illustration/nature`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("illustration")
            props.setPageFilter2("nature")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <ForestIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Natur" />
          </ListItemButton>
          </Link>

          <Link to={`/illustration/creatures`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("illustration")
            props.setPageFilter2("creatures")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <SmartToyIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Varelser" />
          </ListItemButton>
          </Link>

          <Link to={`/illustration/architecture`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("illustration")
            props.setPageFilter2("architecture")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <ApartmentIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Arkitektur" />
          </ListItemButton>
          </Link>
          
        </List>
      </Collapse>


     {/* Start of Style 2*/}
     <Link to={`/bohemian/`} id="link">
      <ListItemButton onClick={()=>{
        props.setLoadCount(25)
        props.setScrollPosition(0)
        setOpenFuturistic(false)
        setOpenPopArt(false)
        setOpenFantasy(false)
        setOpenAbstract(false)
        setOpen(false)
        setOpenIllustration(false)
        setOpenPhotography(false)

        props.setPageFilter1("bohemian");
        props.setPageFilter2("");
        props.setImageDisplayUrls([])
        props.setCheckout(false)
        handleClickBohemian();
      }}>
        <ListItemIcon>
          <SpaIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ style: { fontSize: fontSizeDrawerLinks, lineHeight: lineHeightDrawerLinks} }} primary="Bohemian" />
        {openBohemian ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      </Link>
      <Collapse in={openBohemian} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to={`/bohemian/animals`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("bohemian")
            props.setPageFilter2("animals")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <PetsIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Djur" />
          </ListItemButton>
          </Link>
          <Link to={`/bohemian/humans`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("bohemian")
            props.setPageFilter2("humans")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <EmojiPeopleIcon  sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Människor" />
          </ListItemButton>
          </Link>

          <Link to={`/bohemian/nature`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("bohemian")
            props.setPageFilter2("nature")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
            props.setLoadCount(25)
          }}
          >
            <ListItemIcon>
              <ForestIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Natur" />
          </ListItemButton>

          <Link to={`/bohemian/creatures`} id="link">
          </Link>
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("bohemian")
            props.setPageFilter2("creatures")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <SmartToyIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Varelser" />
          </ListItemButton>
          </Link>

          <Link to={`/bohemian/architecture`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("bohemian")
            props.setPageFilter2("architecture")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <ApartmentIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Arkitektur" />
          </ListItemButton>
          </Link>
        </List>
      </Collapse>




    {/* Start of Style 3*/}

    {/* Start of Style 3*/}
    
      <Link to={`/fantasy/`} id="link">
      <ListItemButton onClick={()=>{
        props.setLoadCount(25)
        props.setScrollPosition(0)
        setOpenFuturistic(false)
        setOpenPopArt(false)
        setOpenAbstract(false)
        setOpenIllustration(false)
        setOpenPhotography(false)
        setOpenBohemian(false)
        
        props.setPageFilter1("fantasy")
        props.setPageFilter2("")
        props.setImageDisplayUrl("")
        props.setCheckout(false)
        props.setImageDisplayUrls([])
        handleClick()
      }}>
        <ListItemIcon>
          <AutoAwesomeIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 
        </ListItemIcon>
        <ListItemText primary="Fantasy" primaryTypographyProps={{ style: { fontSize: fontSizeDrawerLinks, lineHeight: lineHeightDrawerLinks} }}/>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      </Link>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to={`/fantasy/animals`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("fantasy")
            props.setPageFilter2("animals")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <PetsIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Djur" />
          </ListItemButton>
          </Link>
          <Link to={`/fantasy/humans`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
            props.setPageFilter1("fantasy")
            props.setPageFilter2("humans")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
          }}
          >
            <ListItemIcon>
              <EmojiPeopleIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Människor" />
          </ListItemButton>
          </Link>

          <Link to={`/fantasy/nature`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
          }}
          >
            <ListItemIcon>
              <ForestIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Natur" />
          </ListItemButton>
          </Link>

          <Link to={`/fantasy/creatures`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
          }}
          >
            <ListItemIcon>
              <SmartToyIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Varelser" />
          </ListItemButton>
          </Link>

          <Link to={`/fantasy/architecture`} id="link">
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            props.setLoadCount(25)
            props.setScrollPosition(0)
            props.setOpenSidebar(false)
          }}
          >
            <ListItemIcon>
              <ApartmentIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} />
            </ListItemIcon>
            <ListItemText primary="Arkitektur" />
          </ListItemButton>
          </Link> 
          
        </List>
      </Collapse>
    </List>
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader"
        sx={{  fontSize: isMobileView?"23px":"25px" ,
        fontFamily: [
          'Dancing Script',
          'cursive'
        ].join(','),
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)', color: '#161823', position: "relative", left: isMobileView?"40px":"-23px", fontSize: isMobileView?"30px":"25px",fontWeight: "bold"}}
        >
          Motiv
        </ListSubheader>
      }
    >
      <Link to={`/animals-1/`} id="link">
      <ListItemButton
      onClick={()=>{
        props.setLoadCount(25)
        props.setScrollPosition(0)
        props.setPageFilter1("");
        props.setPageFilter2("");
        props.setOpenSidebar(false)
        
      }}
      >
        <ListItemIcon>
          <PetsIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 
        </ListItemIcon>
        <ListItemText primary="Djur" primaryTypographyProps={{ style: { fontSize: fontSizeDrawerLinks, lineHeight: lineHeightDrawerLinks} }}/>
      </ListItemButton>
      </Link>
      
      <Link to={`/humans-1/`} id="link">
      <ListItemButton
            onClick={()=>{
              props.setLoadCount(25)
              props.setScrollPosition(0)
              props.setPageFilter1("");
              props.setPageFilter2("");
              props.setOpenSidebar(false)
              
            }}
            >

        <ListItemIcon>
          <EmojiPeopleIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 
        </ListItemIcon>
        <ListItemText primary="Människor" primaryTypographyProps={{ style: { fontSize: fontSizeDrawerLinks, lineHeight: lineHeightDrawerLinks} }}/>
      </ListItemButton>
      </Link>

      <Link to={`/vehicle-1/`} id="link">
      <ListItemButton
      onClick={()=>{
        props.setLoadCount(25)
        props.setScrollPosition(0)
        props.setPageFilter1("");
        props.setPageFilter2("");
        props.setOpenSidebar(false)
        
      }}
      >
        <ListItemIcon>
          <TwoWheelerIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 
        </ListItemIcon>
        <ListItemText primary="Fordon" primaryTypographyProps={{ style: { fontSize: fontSizeDrawerLinks, lineHeight: lineHeightDrawerLinks} }}/>
      </ListItemButton>
      </Link>

      <Link to={`/nature-1/`} id="link">
      <ListItemButton
      onClick={()=>{
        props.setLoadCount(25)
        props.setScrollPosition(0)
        props.setPageFilter1("");
        props.setPageFilter2("");
        props.setOpenSidebar(false)
        
      }}
      >
        <ListItemIcon>
          <ForestIcon sx={{ fontSize: fontSizeDrawerButtons, color: buttonColor }} /> 
        </ListItemIcon>
        <ListItemText primary="Natur" primaryTypographyProps={{ style: { fontSize: fontSizeDrawerLinks, lineHeight: lineHeightDrawerLinks} }}/>
      </ListItemButton>
      </Link>
  


      <Collapse in={false} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItemButton sx={{ pl: 4 }}>
          <ListItemIcon onClick={(e) => {
            
            
            props.setPageFilter1("")
            props.setPageFilter2("animal")
            props.setCheckout(false)
            props.setImageDisplayUrls([])
            e.stopPropagation();
            
          }}>
            <StarBorder />
          </ListItemIcon>
          <ListItemText primary="Animals" onClick={(e) => {
            
            e.stopPropagation();
        }} />
        </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}
          onClick={()=>{
            
          }}
          >
            <ListItemIcon

          >
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Humans" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ForestIcon />
            </ListItemIcon>
            <ListItemText primary="Nature" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Creatures" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Architecture" />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
      <Box sx={{ display: 'flex' }}>
      <CssBaseline />


  <Box 
  onMouseEnter={handleDropdownOpen}
  onMouseLeave={handleDropdownClose}
  className={classes.customDropdown}
  style={{visibility:openDropDownDivPoster}}
  >
    {/* Add your custom content here */}
    <div>Your custom content222</div>
  </Box>



      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={props.openSidebar}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            style: { zIndex: 1 + 1 }
          }}
          sx={{

            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }, zIndex: 1
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
        variant="permanent"
        sx={{
          display: isTabletView?'none':'block',
          '& .MuiDrawer-paper': { 
            boxSizing: 'border-box', 
            width: drawerWidth,
            zIndex: 1,
            overflow: 'auto', 
            scrollbarWidth: 'none', 
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        }}
        open
      >
        {drawer}
      </Drawer>

      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
      </Box>
      <Snackbar
        open={props.snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ top: '94px' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Poster tillagd i varukorgen!
        </Alert>
      </Snackbar>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;