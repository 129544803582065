import React from 'react';
import { Container, Typography, Link } from '@material-ui/core';



export default function CookiePolicy() {
  return (
    <Container
    style={{

        maxWidth: '60vw',
        margin: '0 auto',
    }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Cookiepolicy för Postertopia
      </Typography>
      <Typography paragraph>
        En cookie är en liten textfil som sparas på din dator när du besöker en webbplats. Postertopia använder informationen för statistikändmål, följa vad besökaren gör på webbplatsen samt för att förbättra och personalisera upplevelsen av webbplatsen. Det finns två typer av cookies – permanenta och sessionscookies.
      </Typography>
      <Typography paragraph>
        <strong>Permanenta cookies</strong> ligger kvar på besökarens dator under en bestämd tid. 
        <strong>Sessionscookie</strong> lagras på din dator så länge du är inne på en webbsida. Så fort du stänger ner webbläsaren försvinner sessionscookien.
      </Typography>
      <Typography paragraph>
        Postertopia använder cookies för att förbättra och anpassa webbplatsen efter dig som besökare. Exempel på funktioner som påverkas av cookies är: hantering av köp, inlogg till ditt kundkonto och varukorgen. Cookies hjälper också till att lagra viss information som anpassar reklam och erbjudanden för att ge dig som besökare en mer relevant upplevelse.
      </Typography>
      {/* Please continue with the rest of the content here */}
      <Typography paragraph>
        Om du inte accepterar att cookies används kan du stänga av det under säkerhetsinställningarna i din webbläsare. Observera att det när som helst går att manuellt radera cookies från din hårddisk. Du kan lära dig mer om hur du gör detta på webbplatserna för de olika webbläsarna: 
        <Link href="https://support.google.com/chrome/answer/95647">Google Chrome</Link>,
        <Link href="https://support.microsoft.com/sv-se/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</Link>,
        <Link href="https://support.apple.com/sv-se/guide/safari/sfri11471/mac">Safari</Link>,
        <Link href="https://support.mozilla.org/sv/kb/ta-bort-kakor-som-lagrats-pa-datorn">Mozilla Firefox</Link>,
        <Link href="https://www.opera.com/help/tutorials/security/cookies/">Opera</Link> och 
        <Link href="https://www.adobe.com/privacy/opt-out.html">Flash cookies</Link>.
      </Typography>
    </Container>
  );
}
