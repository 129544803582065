/* global fbq */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Radio,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CloseIcon from "@material-ui/icons/Close";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CustomFormCard from "./CustomCardForm";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentButtons from './PaymentButtons';
import ParentCard from "./ParentCard";
import { height } from "@mui/system";
import { useMediaQuery } from '@material-ui/core';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import axios from "axios";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ProgressBar from "./ProgressBar";

const stripePromise = loadStripe('pk_test_51M71CKGZy4Gv0AZCDZBwOTShGtawhOdfXwqRL9AECRjp6iUvkqmlBwE9kIQwdkeWQgPEn75Gcf75XWYSd1ppV4Zm00ufktoMQX');



const Checkout = (props) => {
  const [paymentOption, setPaymentOption] = useState("credit");
  const isMobileView = useMediaQuery('(max-width:600px)');
  const handlePaymentChange = (event) => {
    setPaymentOption(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  // Count the number of times the same item has been added itemId + itemSize and add as a quantity parameter
  const basketItemsWithQuantity = props.itemBasket.reduce((acc, item) => {
    const index = acc.findIndex((existingItem) => existingItem.id === item.id && existingItem.size === item.size);
    if (index !== -1) {
      acc[index].quantity += 1;
    } else {
      acc.push({ ...item, quantity: 1 });
    }
    return acc;
  }, []);
  const initialQuantities = basketItemsWithQuantity.reduce(
    (acc, item) => ({ ...acc, [`${item.id}-${item.size}`]: item.quantity }),
    {}
  );
  const [itemQuantities, setItemQuantities] = useState(initialQuantities);


  // Handle change of quantity for a specific item
  const handleQuantityChange = (itemId, itemSize, delta) => {
    const key = `${itemId}-${itemSize}`;
    setItemQuantities((prevState) => ({
      ...prevState,
      [key]: Math.max(prevState[key] + delta, 1),
    }));
  };
  
  // Get total price for all items in basket
  const getTotalPrice = (items) => {
    return items.reduce(
      (total, item) =>
        total + item.price * itemQuantities[`${item.id}-${item.size}`],
      0
    );
  };

  const [formattedItems, setFormattedItems] = useState([]);

  useEffect(() => {
    const updatedFormattedItems = basketItemsWithQuantity.map((item) => ({
      price: item.price * 100,
      quantity: itemQuantities[`${item.id}-${item.size}`],
      description: item.name + ". Storlek: " + item.size,
      image_url: item.urlFrame,
    }));
  
    setFormattedItems(updatedFormattedItems);
  }, [basketItemsWithQuantity, itemQuantities]);
  
  const handleRemoveItem = (itemId, itemSize) => {
    props.onRemoveItem(itemId, itemSize);
    const key = `${itemId}-${itemSize}`;
    setItemQuantities((prevState) => {
      const newQuantities = { ...prevState };
      delete newQuantities[key];
      return newQuantities;
    });
  };

  if (props.itemBasket.length === 0) {
    return (
      <div style={{ height: '80vh' }}>
        <Grid container alignItems="center" justify="center" style={{ height: '100%' }} spacing={1}>
          <Grid item>
            <Typography variant="h5">Varukorgen är tom</Typography>
          </Grid>
          <Grid item>
            <ShoppingCartIcon />
          </Grid>
        </Grid>
      </div>
    );
  }


  const useStyles = makeStyles({
    customTypography: {
      fontSize: "20px",
    },
    kassaButton: {
      backgroundColor: 'black',
      color: 'white', // text color
    },
  });
  
/*   const formattedItems = basketItemsWithQuantity.map(item => ({
    price: item.price*100,
    quantity: item.quantity,
    description: item.name+". Storlek: "+item.size,
    image_url: item.urlFrame,
    }));
 */
    const handleCheckoutClick = () => {
      
      axios.post('https://backend-photo-gallery-hfzyyicqga-lz.a.run.app/create_payment_link', formattedItems)
          .then(response => {
              
              window.location.href = response.data.url;
          })
          .catch(error => {
              console.error(error);
          });
  };
  
  // This is an example of how the input basketItemsWithQuantity schema looks like after the reduce function above
  // const basketItems = [
  //   { id: 1, name: "Product 1", price: 344, quantity: 2, size: "20cm x 20cm", url:"https://url_to_image" },
  //   { id: 2, name: "Product 2", price: 344, quantity: 1,size: "20cm x 20cm", url:"https://url_to_image" },
  // ];

  return (
    <Paper 
    style={{
      position: "relative",
      border: 'none',        // Added this line
      boxShadow: 'none',


    }}
    >
      <Grid container direction="column" spacing={2} alignItems="center">
      <Grid
  container
  direction="row"
  alignItems="center"
  justify="center"
  style={{
    position: "absolute",
    marginBottom: "30px",
    marginTop: "30px",
    left: 0,
    right: 0,
  }}
>
  <Typography style={{ fontWeight: "bold", marginRight: "10px" }} variant="h5">
    Kundvagn
  </Typography>

  <ShoppingBasketIcon />
</Grid>



        
<Box
  style={{
    position: "relative",
    marginTop: "80px",
    marginBottom: "100px",
  }}
>
  {basketItemsWithQuantity.map((item) => (
    <Card
      key={item.id + item.size}
      style={{
        position: "relative",
        marginBottom: "10px",
        marginTop: "10px",
        maxWidth:isMobileView?"60vw":"700px",
        width:isMobileView?"60vw":"310px",
        left: "0vw",
        


      }}
    >
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column", // Makes the card content align vertically
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={() => {
            handleRemoveItem(item.id, item.size)


          
          }}
          color="secondary"
          style={{
            position: "absolute",
            top: isMobileView?-14:-8,
            right:  isMobileView?-14:-8,
            margin: 4,
          }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={item.url}
            alt={item.name}
            style={{ width: 80, marginRight: "10px", marginTop: "10px" }}
          />
          <div>
            <Typography variant="h6"
              style={{ fontSize: isMobileView?"12px":"14px", width: isMobileView?"50%":"150px", position: "relative", top: "-28px", fontWeight: "bold" }}
            >{item.name}</Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "10px"
              }}
            >
              {/* <Typography className={useStyles.customTypography} {...props}
                style={{
                  position: "absolute",
                  top: "64px",
                  right: "16px",
                  fontSize: "14.7px",

                }}
              >
                <p>{item.size}</p>
              </Typography> */}
              <Typography variant="body1"
                style={{ 
                  fontSize: isMobileView?'13px':'14px',
                  position: "absolute",
                  top: "52%",


              }}
              >
                Pris: {item.price} kr  <br />
                Storlek: {item.size}
              </Typography>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", position: "absolute", top: isMobileView?"75%":"77%", right:isMobileView ? "3%":"15%"}}>
          <IconButton
            onClick={() => handleQuantityChange(item.id, item.size, -1)}
            color="primary"
            style={{ marginTop: "-10px" }}
          >
            <RemoveIcon />
          </IconButton>
          <div style={{ textAlign: "center", margin: "0 8px" }}>
            <Typography variant="subtitle1">
              {itemQuantities[`${item.id}-${item.size}`]}
            </Typography>
          </div>
          <IconButton
            onClick={() => handleQuantityChange(item.id, item.size, 1)}
            color="primary"
            style={{ marginTop: "-10px" }}
          >
            <AddIcon />
          </IconButton>
        </div>
      </CardContent>
    </Card>
  ))}
</Box>







    
{/* Bottom fixed div */}
<div
  style={{
    position: "fixed",
    bottom: 10,
    backgroundColor: "white",
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    width: isMobileView? "55vw":"300px",
    height: "125px",
    flexDirection: "column", // Set flex direction to column
    alignItems: "center", // Center align items horizontally
  }}
>

  <ProgressBar isMobileView={isMobileView} total={getTotalPrice(basketItemsWithQuantity)} threshold={349} />

  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    }}
  >
    
    <Typography variant="h6"
      style={{
        position: "relative",
        marginBottom: "20px",
        left: isMobileView?"0px":"15px",
        top: "16px",
        fontWeight: "bold",
        fontSize: isMobileView?"18px":"18px"

      }}
    >
      DELSUMMA
    </Typography>
    <Typography variant="h6"
      style={{
        position: "relative",
        marginBottom: "20px",
        right: isMobileView?"0px":"20px",
        top: "16px",
        fontWeight: "bold",
        fontSize:  isMobileView?"18px":"18px"
      }}
    >
      {getTotalPrice(basketItemsWithQuantity)} kr
    </Typography>
  </div>
  <Button
    variant="contained"
    color="primary"
    onClick={()=>{
      props.setOpenBasketDrawer(false)
      props.setOpenOverlayLoad(true)
      handleCheckoutClick()
      if (window.fbq) {
        window.fbq('track', 'InitiateCheckout', {
          content_name: "Basket Checkout",
          content_type: 'product',
          value: getTotalPrice(basketItemsWithQuantity),
          currency: 'SEK', // Change to your currency if it's not SEK
          // You can add more properties if needed.
        });
      }
    
      // Google Analytics Event for InitiateCheckout
      if (window.gtag) {
        window.gtag('event', 'initiate_checkout', {
          'items': formattedItems,  // Send the list of items being bought
          'value': getTotalPrice(basketItemsWithQuantity),  // Send the total value
          'currency': 'SEK',  // Change to your currency if it's not SEK
          // Add any other GA event parameters you want here
        });
      }
    }}
    style={{ backgroundColor: "black", width: isMobileView?"50vw":"290px", height: "45px", borderRadius: "8px", fontWeight: "bold"}}
  >
    TILL KASSAN
  </Button>
</div>


</Grid>
</Paper>
);

<Backdrop
sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
open={open}
onClick={handleClose}
>
<CircularProgress color="inherit" />
</Backdrop>
  
};

export default Checkout;
