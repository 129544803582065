import { LinearProgress, Typography, Box } from '@material-ui/core';

function ProgressBar({total, threshold, isMobileView}) {

  const progress = (total / threshold) * 100;

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box style={{
        width: isMobileView? "200px":"300px"
      }} mr={1}>
        <LinearProgress
        sx={{color: "black"}}
        style={{ height: '10px', borderRadius: "4px" }}
        variant="determinate" value={Math.min(progress, 100)} />
      </Box>
      <Box>
        {total < threshold && (
          <Typography
          style={{
            fontSize: "14px"
          }}
          variant="caption">{`${threshold - total} kr kvar till fri frakt`}</Typography>
        )}
        {total > threshold && (
          <Typography
          style={{
            fontSize: "14px"
          }}
          variant="caption">{`Du har nu fri frakt`}</Typography>
        )}
      </Box>
    </Box>
  );
}

export default ProgressBar;
