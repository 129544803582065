import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import KeyboardDoubleArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles((theme) => ({
  scrollToTop: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
  },
  scrollToTopIcon: {
    fontSize: '48px', // Set the desired font size for the icon
  },
}));

const ScrollToTopButton = () => {
  const classes = useStyles();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <IconButton
      className={classes.scrollToTop}
      color="primary"
      onClick={scrollToTop}
    >
      <KeyboardDoubleArrowUpIcon className={classes.scrollToTopIcon} />
    </IconButton>
  );
};

export default ScrollToTopButton;
