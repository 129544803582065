import React from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '60vw',
    margin: '100px auto',
    padding: theme.spacing(4),
    border: '2px solid #e0e0e0',
    borderRadius: '15px',
    backgroundColor: '#f5f5f5',
    [theme.breakpoints.down(600)]: {
      maxWidth: '90vw',
    },
  },
  header: {
    display: 'flex',          
    alignItems: 'center',     
    justifyContent: 'center', 
    marginBottom: theme.spacing(3),
  },
  icon: {
    color: theme.palette.error.main, // Make the icon red to signify error
    marginRight: theme.spacing(1),  
  }
}));

export default function PaymentFailed() {
    const classes = useStyles();

    return (
      <Container className={classes.root}>
        <div className={classes.header}>
          <ErrorIcon className={classes.icon} 
          sx={{
            fontSize: "35px",
            position: "relative",
            top: "-8px"
          }}
          />
          <Typography variant="h4" gutterBottom>
            Betalning Misslyckades
          </Typography>
        </div>
        <Typography variant="h6" align="center" paragraph>
          Något gick fel med din betalning.
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          Var god och försök igen eller kontakta kundtjänst för hjälp.
        </Typography>
      </Container>
    );
}
