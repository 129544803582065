/* global fbq */
import React, { useState, useEffect } from 'react';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import { Card, CardMedia, CardContent, Typography, Grid } from '@material-ui/core';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import ImageSliderComponent from './ImageSliderComponent';
import Button from '@mui/material/Button';
import "../styles/PosterCardLargeInformation.css";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@material-ui/icons/Check';
import useMediaQuery from '@mui/material/useMediaQuery';

const availableSizes = [1, 2];


const sizesMap = {
  '2:2': [
    { price: 149, label: '21cm x 21cm', shortLabel: "20x20cm" , size:1},
    { price: 219, label: '30cm x 30cm', shortLabel: "40x40cm", size:2 },
    { price: 299, label: '42cm x 42cm', shortLabel: "50x50cm", size:3 },
  ],
  '2:3': [
    { price: 299, label: '42cm x 60cm', shortLabel: "40x60cm", size:3 }, // A2-like
    { price: 219, label: '30cm x 42cm', shortLabel: "30x42cm", size:2 }, // A3-like
    { price: 149, label: '20cm x 30cm', shortLabel: "20x30cm", size:1 }, // A4-like
    // add sizes for '2:3' ratio
  ],
  '3:2': [
    { price: 299, label: '60cm x 42cm', shortLabel: "60x40cm",size:3 }, // A2-like
    { price: 219, label: '42cm x 30cm', shortLabel: "42x30cm",size:2 }, // A3-like
    { price: 149, label: '30cm x 20cm', shortLabel: "30x20cm", size:1 }, // A4-like
    // add sizes for '3:2' ratio
  ],
};


export default function PosterCardLargeInformation(props) {
  const isMobileView = useMediaQuery('(max-width:600px)');
  const isTabletView = useMediaQuery('(max-width:900px)');
  
  const [selectedSizePrice, setSelectedSizePrice] = useState(149);
  

  // fetch size options based on aspect ratio
  const sizeOptions = sizesMap[props.imageDisplayUrls[0].aspectRatio] || [];
  const [posterSize, setPosterSize] = useState(props.imageDisplayUrls[0].aspectRatio==="2:2"?"20x20cm":props.imageDisplayUrls[0].aspectRatio==="2:3"?"20x30cm":"30x20cm");
  const handleSizeChange = (event) => {
    setSelectedSizePrice(event.target.value);

    const selectedOption = sizeOptions.find(
      (option) => option.price === Number(event.target.value)
    );

    setPosterSize(selectedOption ? selectedOption.shortLabel : '');
  };
  
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  
  // Scroll to top when the component is mounted (rendered)
  useEffect(() => {
    scrollToTop();
  }, []); // Empty dependency array to make sure it only runs once when the component mounts
  
  return (
    <div>
      <Card style={{ 
        position: isTabletView?"absolute":isMobileView?"absolute" :"relative",
        top: isTabletView?"52vh":"0px",
        width: isMobileView ? '80%':isTabletView?"70%":"30vw",
        maxWidth: isMobileView ? '80%':isTabletView? '700px':"600px",
        marginLeft: isTabletView? "15%": isMobileView?'0%': 0,
        left: isMobileView? "-3%": "0px",
        border: 'none',        // Added this line
        boxShadow: 'none',

    
    }}>
        <CardContent
        style={{
          border:"none"
        }}
        >
          <Box>
            <Typography variant="h4" color="text.secondary">
              {props.imageDisplayUrls[0].title}
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography variant="body1" color="text.secondary">
              {props.imageDisplayUrls[0].description}
            </Typography>
          </Box>
          <Box mt={3}>            
            <Typography variant="h4" color="text.secondary">
                {props.imageDisplayUrls[0].isDiscount ? (
                    <>
                        {Math.round(selectedSizePrice * (1 - props.discountPercentage))}kr 
                        <span style={{ textDecoration: 'line-through', color: 'red' }}>
                            {selectedSizePrice}kr
                        </span>
                    </>
                ) : (
                    <>
                        {selectedSizePrice}kr
                    </>
                )}
            </Typography>

          </Box>
          <Box sx={{ minWidth: 100 }} style={{ marginTop: '50px' }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Poster Storlek:
              </InputLabel>
              <NativeSelect
                value={selectedSizePrice}
                onChange={handleSizeChange}
                inputProps={{
                  name: 'size',
                  id: 'uncontrolled-native',
                }}
                style={{ height: '50px' }}
              >
                {sizeOptions.map((option, index) => (
                  <option 
                    key={index} 
                    value={option.price} 
                    style={availableSizes.includes(option.size) ? {} : {textDecoration: 'line-through', color: 'red'}}
                    disabled={!availableSizes.includes(option.size)}
                  >
                    {option.label}
                  </option>
                ))}
              </NativeSelect>

            </FormControl>
            <Box mt={5} flexDirection="column">
              <Button
                variant="contained"
                color="primary"
                className="custom-button"
                endIcon={<AddIcon />}
                style={{ backgroundColor: "black", maxWidth: "300px",minWidth:isMobileView?"80%":"300px", height: "45px" }}

                onClick={() => {
                  if (window.gtag) {
                    window.gtag('event', 'add_item', {
                      'style': props.imageDisplayUrls[0].style,
                      'category': props.imageDisplayUrls[0].category,
                      'imageId': props.imageDisplayUrls[0].imageId,
                    });
                    if (window.fbq) {  // Ensure fbq is defined
                      window.fbq('track', 'AddToCart', {
                          content_name: props.imageDisplayUrls[0].title,
                          content_category: props.imageDisplayUrls[0].style,
                          content_ids: [props.imageDisplayUrls[0].imageId],
                          content_type: 'product',   // Assuming it's a product you're adding to cart
                          value: props.imageDisplayUrls[0].isDiscount?Math.round(selectedSizePrice * (1 - props.discountPercentage)):selectedSizePrice,
                          currency: 'SEK'  // Assuming SEK as your currency
                      });
                  }
                }

                  props.setSnackbarOpen(true);
                  const itemBasketData = {
                    id: props.imageDisplayUrls[0].imageId,
                    name: props.imageDisplayUrls[0].title,
                    price: props.imageDisplayUrls[0].isDiscount?Math.round(selectedSizePrice * (1 - props.discountPercentage)):selectedSizePrice,
                    url:  props.imageDisplayUrls[0].signedUrl,
                    urlFrame: props.imageDisplayUrls[0].framedImageUrls[1],
                    size: posterSize
                  }
                  props.setItemBasket((prevItemBasket) => [...prevItemBasket, itemBasketData]);
                }}
              >
                Lägg i varukorgen
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="custom-button"
                endIcon={<ShoppingCartIcon />}
                style={{ backgroundColor: "black", maxWidth: "300px", height: "45px",minWidth:isMobileView?"80%":"300px", marginTop: "20px" }}
                onClick={()=>{
                  props.openDrawerBasket()
                }}
                
                >
                  Gå till kassan
                </Button>
            </Box>
            <Box mt={7} display="flex" justifyContent="center">
              <Box display="flex" alignItems="center"  sx={{ gap: '8px' }}>
                <CheckIcon />
                <Typography variant="body1" color="text.secondary"
                >
                  Frakt upp till 45kr
                </Typography>
              </Box>
            </Box>
            <Box mt={3} display="flex" justifyContent="center" >
              <Box display="flex" alignItems="center"  sx={{ gap: '8px' }}>
                <CheckIcon />
                <Typography variant="body1" color="text.secondary">
                  Leverans 1-3 dagar
                </Typography>
              </Box>
            </Box>
            <Box mt={3} display="flex" justifyContent="center" >
              <Box display="flex" alignItems="center"  sx={{ gap: '8px' }}>
                <CheckIcon />
                <Typography variant="body1" color="text.secondary">
                  15 dagar öppet köp
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
        <CardActions disableSpacing>
        </CardActions>
      </Card>
    </div>
  );
  
}
