// CustomOverlayDrawer.js
import React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Typography, Box } from '@material-ui/core';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';

import ImageMargin from '../images/frame/type/margin.jpg';
import ImageNoMargin from '../images/frame/type/no_margin.jpg';

import ImageFrame1 from '../images/frame/colors/f1.jpg';
import ImageFrame2 from '../images/frame/colors/f2.jpg';
import ImageFrame3 from '../images/frame/colors/f3.jpg';
import ImageFrame4 from '../images/frame/colors/f4.jpg';
import ImageFrame5 from '../images/frame/colors/f5.jpg';
import ImageFrame6 from '../images/frame/colors/f6.jpg';
import ImageFrame7 from '../images/frame/colors/f7.jpg';
import ImageFrame8 from '../images/frame/colors/f8.jpg';
import { motion } from "framer-motion";
import {useMediaQuery} from '@material-ui/core';

const CustomOverlayDrawer = ({  ...props }) => {
  const [open, setOpen] = React.useState(false);
  const isMobileView = useMediaQuery('(max-width:600px)');
  const isTabletView = useMediaQuery('(max-width:900px)');
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const CustomDrawer = styled(Drawer)`
    .MuiDrawer-paper {
      ${isMobileView ? `
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250px;
        max-height: 80vh; // Change the max-height for mobile view
        border-radius: 8px;
        padding: 16px;
      `: isTabletView?
      `
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        max-height: 80vh;
        border-radius: 8px;
        padding: 16px;
      ` : `
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        max-height: 80vh;
        border-radius: 8px;
        padding: 16px;
      `}
    }
  `;

  const ImageContainer = styled(Paper)`
  ${props => props.isMobileView ? `
    width: 40px; // Square size for mobile
    height: 40px; // Square size for mobile
  ` : `
    width: 60px; // Square size for desktop
    height: 60px; // Square size for desktop
  `}
  background-size: cover;
  background-position: center;
  position: relative;
`;



  const Image = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;

    @media (max-width: 600px) {
      

    }
  `;

  const LargeImageContainer = styled(ImageContainer)`
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.isMobileView ? `
    width: 80px;
    height: 80px;
    left: 13%;
  ` : props.isTabletView ? `
    width: 100px;  // or whatever width you want for tablet view
    height: 100px;  // or whatever height you want for tablet view
    left: 20%; 
  ` : `
    width: 100px;  // default desktop view width
    height: 100px;  // default desktop view height
    left: 10%;
  `}
`;






  const frameType = [
    {
      value: 'margin',
      image: ImageMargin,
    },
    {
      value: 'no_margin',
      image: ImageNoMargin,
    },
  ];



  const frameColor = [
    {
      value: 'f1',
      image: ImageFrame1,
    },
    {
      value: 'f2',
      image: ImageFrame2,
    },
    {
      value: 'f3',
      image: ImageFrame3,
    },
    {
      value: 'f4',
      image: ImageFrame4,
    },
    {
      value: 'f5',
      image: ImageFrame5,
    },
    {
      value: 'f6',
      image: ImageFrame6,
    },
    {
      value: 'f7',
      image: ImageFrame7,
    },
    {
      value: 'f8',
      image: ImageFrame8,
    },
  ];

  const imageVariants = {
    hover: {
      scale: 1.1,
      transition: {
        duration: 0.3,
      },
    },
    tap: {
      scale: 0.9,
      transition: {
        duration: 0.2,
      },
    },
  };


  return (
    <div>
      <CustomDrawer open={props.openFrameMenu} onClose={props.handleToggleChange} anchor="top">
        <Grid container direction="column" wrap="nowrap">
          <Typography variant="h5" align="center" gutterBottom style={{ fontWeight: 'bold', marginBottom: '15px' }}>
            Färg
          </Typography>

          <Grid container spacing={2}>
            {frameColor.map((imageData, index) => (
              <Grid item xs={3} sm={3} key={index} style={{ marginBottom: '20px' }}>
                <ImageContainer
                  isMobileView={isMobileView}
                  onClick={() => {
                    
                    props.setFrameCode(frameColor[index].value);
                    props.setToggleState(true);
                    props.handleToggleChange();
                  }}
                  elevation={3}
                >
                  <Image style={{ backgroundImage: `url('${imageData.image}')` }} />
                </ImageContainer>
              </Grid>
            ))}
          </Grid>

          <Typography variant="h5" align="center" gutterBottom style={{ fontWeight: 'bold', marginBottom: '10px' }}>
            Ram
          </Typography>
          
          <Grid container justifyContent="center" spacing={2}>
            {frameType.map((imageData, index) => (
              <Grid item xs={6} sm={6} md={4} key={index + 8}>
                <Box display="flex" justifyContent="center">
                  <LargeImageContainer
                    isMobileView={isMobileView}
                    isTabletView={isTabletView}
                    onClick={() => {
                      props.setFrameType(frameType[index].value);
                      props.setToggleState(true);
                      props.handleToggleChange();
                    }}
                    elevation={3}
                  >
                    <Image style={{ backgroundImage: `url('${imageData.image}')` }} />
                  </LargeImageContainer>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Button onClick={props.handleToggleChange}>Stäng</Button>
      </CustomDrawer>
    </div>
  );


};

export default CustomOverlayDrawer;