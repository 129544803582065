import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Grid from '@mui/material/Grid';

export default function PosterCardSmall(props) {
  const imageUrls = [props.imageDisplayUrls[0].signedUrl, ...props.imageDisplayUrls[0].imageWallUrls];
  const [currentImage, setCurrentImage] = useState(0);

  const imageStyle = {
    height: '40vh',
    position: 'relative',
  };

  const transition = {
    duration: 1.5, // Reduce the duration to make the transition faster
    ease: [0.63, 0.13, 0.23, 0.96],
  };

  const imageVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition,
    },
    exit: { opacity: 0 }, // Added exit transition
  };

  const changeImage = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % imageUrls.length);
  };

  useEffect(() => {
    const timer = setInterval(changeImage, 6500); // Adjust the interval time (in ms) for the auto-scroll
    return () => clearInterval(timer);
  }, []);

  return (
    <div style={{ position: 'relative', height: '300px' }}>
      <Grid
        container
        sx={{
          position: 'absolute',
          top: 0,
          left: "50vw",
          right: 0,
          bottom: 0,
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item onClick={changeImage}>
          <AnimatePresence mode="wait">
            <motion.img
              key={currentImage}
              src={imageUrls[currentImage]}
              alt={`Slide ${currentImage + 1}`}
              style={{ ...imageStyle, display: 'block' }}
              variants={imageVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              top={0}
              left={0}
            />
          </AnimatePresence>
        </Grid>
      </Grid>
    </div>
  );
}
