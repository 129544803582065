/* global fbq */
import React, { useEffect, useState } from "react";
import "./App.css";
import { Grid } from '@material-ui/core';
import Gallery from "./components/Gallery";
import ResponsiveDrawer from "./components/ResponsiveDrawer";
import axios from "axios";
import PosterCardLarge from "./components/PosterCardLarge";
import PosterCardLargeInformation from "./components/PosterCardLargeInformation";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GalleryPage from "./components/GalleryPage";
import Checkout from "./components/Checkout";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from "@material-ui/icons/Home";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import PosterCardSmall from "./components/PosterCardSmall";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import { styled, keyframes } from '@mui/system';


const hoverAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

const AnimatedWallpaperIcon = styled(WallpaperIcon)`
  font-size: 7rem;  /* Adjust for desired size */
  animation: ${hoverAnimation} 1s infinite;
  margin-bottom: 20px;
`;


const useStyles = makeStyles((theme) => ({
  homeButton: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function Dashboard(props) {
  const navigate = useNavigate();
  const isMobileView = useMediaQuery('(max-width:700px)');
  const isTabletView = useMediaQuery('(max-width:890px)');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [imageDisplayUrls, setImageDisplayUrls] = useState([]);

  const [loadCount, setLoadCount] = useState(25);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const [checkout, setCheckout] = useState(false);


  const [imageDisplayUrl, setImageDisplayUrl] = useState("");

  const [pageFilter1, setPageFilter1] = useState("photography");
  const [pageFilter2, setPageFilter2] = useState("animal");

  const [signedUrls, setSignedUrls] = useState({});
  const classes = useStyles();


  const [searchResult, setSearchResult] = useState([]);

  const [selectedImageId, setSelectedImageId] = useState(null);

// existing code in between...



  const handleHomeClick = () => {
    props.togglePage()
    
    // Add logic here to navigate to the desired location when the home button is clicked
  };
  const handleRemoveItem = (itemId, itemSize) => {
    props.setItemBasket((prevBasket) =>
      prevBasket.filter(
        (item) => item.id !== itemId || item.size !== itemSize
      )
    );
  };
  function selectImages(signedUrls, imageId) {
    
    
    
    // check if signedUrls is an array before trying to filter it
    if (Array.isArray(signedUrls)) {
      return signedUrls.filter(url => url.imageId === imageId);
    } else {
      // return a sensible fallback value such as an empty array
      return [];
    }
  }
  const { style, category, imageId, searchTerms } = useParams();
  function findImageById(array, imageId) {
    return array.find(obj => obj.imageId === imageId);
  }

  function filterOnTags(data, searchArray) {
    if (data !== undefined && searchArray !== undefined) {
        return data.filter(item => {
            const itemTags = item.tags;  // Directly using item.tags since it's already an array

            // Check if any of the search terms are included in the item tags
            return searchArray.some(searchTerm => itemTags.includes(searchTerm.trim()));
        });
    }
    return [];
}

  useEffect(() => {
    // This code will run whenever style, category, or imageId changes
    

    if (!imageId){
      props.setCheckout(false)
      setImageDisplayUrls([])
    }

    }, [style, category, imageId]);

    useEffect(() => {
      // Delaying the execution by 300ms
      const timeout = setTimeout(() => {
          console.log("searchTerms", searchTerms)
  
          if (searchTerms !== undefined) {
              console.log("searchTerms trigger")
              const searchArray = searchTerms.split('+');
              
              // Filter data based on searchArray and update state
              const filteredData = filterOnTags(props.signedUrls, searchArray);
              console.log("filteres_data", filteredData)
              setImageDisplayUrl(filteredData);
              setSearchResult(filteredData);
          }
      }, 10);
  
      // Cleanup function to clear the timeout when component is unmounted or before running the effect again
      return () => clearTimeout(timeout);
  
  }, [searchTerms, props.signedUrls]);


  useEffect(() => {
    if (imageId) {
      
      // search for the image URLs based on imageId, e.g.:
      const displayImagesBig = selectImages(props.signedUrls, imageId);
      
      
      setImageDisplayUrls(displayImagesBig);
      
    }
  }, [imageId, props.signedUrls]);

  
  useEffect(() => {
    // This code will run whenever style, category, or imageId changes
    console.log(props.scrollPosition)
    

  }, [props.scrollPosition]);


  useEffect(() => {
    console.log("set search value", props.searchValue)
    // This code will run whenever style, category, or imageId changes
    console.log(props.searchValue)
    if(props.searchValue===""){

    }
    else{
      const searchValueForURL = props.searchValue.split(' ').join('+');
      navigate(`/search/${searchValueForURL}`);
      
    }

    

  }, [props.searchValue]);


  const [lastStyle, setLastStyle] = useState('')
  const [lastCategory, setLastCategory] = useState('')
  
  useEffect(() => {

    if (lastStyle !== '' && lastCategory !== ''){
      setLastStyle(style)
      setLastCategory(category)
      if (window.gtag) {
        window.gtag('event', 'link_click', {
          'style': style,
          'category': category,
        });
        fbq('track', 'link_click', {
          'style': style,
          'category': category,
        });

    }

    }

  }, [style, category]);

  useEffect(() => {
    console.log('style')

  }, [ props.menuOpen]);

  return (
    <div className="App">

      {!props.checkout && (
            <ResponsiveDrawer
              setLoadCount={setLoadCount}
              setScrollPosition={props.setScrollPosition}
              setMenuOpen={props.setMenuOpen}
              displayStyle={props.displayStyle}
              openSidebar={props.openSidebar}
              setOpenSidebar={props.setOpenSidebar}
              snackbarOpen={snackbarOpen}
              setSnackbarOpen={setSnackbarOpen}
              itemBasket={props.itemBasket}
              setImageDisplayUrls={setImageDisplayUrls}
              setImageDisplayUrl={setImageDisplayUrl}
              setPageFilter1={setPageFilter1}
              setPageFilter2={setPageFilter2}
              pageFilter1={pageFilter1}
              pageFilter2={pageFilter2}
              setCheckout={props.setCheckout} // Pass the setCheckout function to the ResponsiveDrawer
            />
          )}
      <div id="mainDisplayDiv">
        {props.checkout ? (
          <Checkout
            onRemoveItem={handleRemoveItem}
            itemBasket={props.itemBasket}
            setCheckout={props.setCheckout}
          />
        ): props.signedUrls === undefined ? (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
          <AnimatedWallpaperIcon />
          <Typography variant="h6" style={{ marginBottom: '50px', fontSize: isMobileView?"20px":"24px" }}>
            Laddar...
          </Typography>
          <CircularProgress size={isMobileView?60:60} />  {/* Increase the size to make it larger */}
        </div>
        ) 
        
        : 
        imageDisplayUrls.length === 0 ? (
          <Routes>
            
            <Route
              path="/:style?/:category?/"
              element={
                <GalleryPage

                  loadCount={loadCount}
                  setLoadCount={setLoadCount}

                  discountPercentage={props.discountPercentage}
                  setCookieConsentGiven={props.setCookieConsentGiven}
                  setOpenCookieBar={props.setOpenCookieBar}
                  scrollPosition={props.scrollPosition}
                  setScrollPosition={props.setScrollPosition}
                  searchResult={searchResult}
                  imageDisplayUrls={imageDisplayUrls}
                  imageDisplayUrl={imageDisplayUrl}
                  searchValue={props.searchValue}
                  favoritePoster={props.favoritePoster} 
                  setFavoritePoster={props.setFavoritePoster}
                  isMobileView={isMobileView}
                  isTabletView={isTabletView}
                  setCheckout={props.setCheckout}
                  signedUrls={props.signedUrls}
                  setImageDisplayUrls={setImageDisplayUrls}
                  selectedStyle={pageFilter1}
                  selectedCategory={pageFilter2}
                  setOpenSidebar={props.setOpenSidebar}
                />
              }
            />
            
          </Routes>
        ) 
        :
         (
          <div>
            <Routes>
              <Route
                path="/:style?/:category?/:imageId?"
                element={
                  <Grid container spacing={2}>
                    <Grid item>
                      {isTabletView ? (
                        <PosterCardSmall
                          imageDisplayUrls={imageDisplayUrls}
                          setCheckout={props.setCheckout}
                        />
                      ) : (
                        <PosterCardLarge
                          imageDisplayUrls={imageDisplayUrls}
                          setCheckout={props.setCheckout}
                        />
                      )}
                    </Grid> 
                    <Grid item>
                      <PosterCardLargeInformation
                        discountPercentage={props.discountPercentage}
                        openDrawerBasket={props.openDrawerBasket}
                        setOpenDrawer={props.setOpenDrawer}
                        openSidebar={props.openSidebar}
                        setOpenSidebar={props.setOpenSidebar}
                        setCheckout={props.setCheckout}
                        snackbarOpen={snackbarOpen}
                        setSnackbarOpen={setSnackbarOpen}
                        itemBasket={props.itemBasket}
                        setItemBasket={props.setItemBasket}
                        imageDisplayUrls={imageDisplayUrls}
                      />

                    </Grid>
                  </Grid>
                }
              />
            <Route
              path="/:style?/:category?/"
              element={
                <GalleryPage

                  loadCount={loadCount}
                  setLoadCount={setLoadCount}


                  setCookieConsentGiven={props.setCookieConsentGiven}
                  setOpenCookieBar={props.setOpenCookieBar}
                  scrollPosition={props.scrollPosition}
                  setScrollPosition={props.setScrollPosition}
                  searchResult={searchResult}
                  imageDisplayUrls={imageDisplayUrls}
                  imageDisplayUrl={imageDisplayUrl}
                  searchValue={props.searchValue}
                  favoritePoster={props.favoritePoster} 
                  setFavoritePoster={props.setFavoritePoster}
                  isMobileView={isMobileView}
                  isTabletView={isTabletView}
                  setCheckout={props.setCheckout}
                  signedUrls={props.signedUrls}
                  setImageDisplayUrls={setImageDisplayUrls}
                  selectedStyle={pageFilter1}
                  selectedCategory={pageFilter2}
                  setOpenSidebar={props.setOpenSidebar}
                />
              }
            />
            
            </Routes>
          </div>
        )}
      </div>
    </div>
  );
  


}

export default Dashboard;
