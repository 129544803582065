import React from 'react'
import { Container, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '60vw',
    margin: '0 auto',
    [theme.breakpoints.down(600)]: {
      maxWidth: '100vw',
    },
  },
}));

export default function PurchaseConditions() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Typography 
      style={{
              marginBottom: "50px",
            }}
      variant="h4" gutterBottom>
        Köpvillkor
      </Typography>
      <Typography variant="h6" gutterBottom>
        1. Allmänt
      </Typography>
      <Typography variant="body1" paragraph>
        1.1
        Dessa allmänna villkor (”Villkoren”) gäller när du som kund (”Du”) gör en beställning via www.postertopia.se, och där tillhörande sidor, (”Hemsidan”). Avtal ingås mellan dig och PosterTopia AB, organisationsnummer 556763-0693 (”PosterTopia AB”). Övrig information om PosterTopia samt kontaktuppgifter framgår på Hemsidan. Villkoren gäller dig som är konsument och som beställer via Hemsidan.
      </Typography>
      <Typography variant="body1" paragraph>
        1.2
        PosterTopia accepterar, enligt svensk lag, inte kreditköp till personer under 18 år. För att kunna beställa på Hemsidan måste Du ha fyllt 18 år. PosterTopia förbehåller sig rätten att i enskilda fall neka eller ändra din beställning (exempelvis om Du uppgivit felaktiga personuppgifter och/eller har betalningsanmärkningar).
      </Typography>
      {/* ... Continue with the rest of your content ... */}
      <Typography variant="h6" gutterBottom>
        2. Beställning och avtal
      </Typography>
      <Typography variant="body1" paragraph>
        2.1
        För att genomföra ett köp på Hemsidan behöver du acceptera dessa villkor. Genom att acceptera villkoren åtar du dig att följa dem i sin helhet. Du bekräftar också att du har läst vår integritetspolicy och accepterar behandlingen av personuppgifter och cookies.
      </Typography>
      {/* ... more sections ... */}

      <Typography variant="h6" gutterBottom>
        3. Kunduppgifter och integritetspolicy
      </Typography>
      <Typography variant="body1" paragraph>
        3.1
        När du slutför din beställning kommer du att ombeds lämna vissa personuppgifter. Du intygar att de uppgifter du ger är korrekta och fullständiga och är ansvarig för felaktigt ifyllda uppgifter. Genom att placera en order hos PosterTopia accepterar du vår integritetspolicy, där du kan hitta mer information om hur vi hanterar personuppgifter.
      </Typography>
      {/* ... more sections ... */}

      <Typography variant="h6" gutterBottom>
        4. Betalning, avgifter och priser
      </Typography>
      <Typography variant="body1" paragraph>
        4.1
        Priserna som anges på Hemsidan gäller vid beställning. Priserna är i SEK och inkluderar moms. Priserna inkluderar inte betalnings- och fraktavgifter, dessa kommer att anges separat.
      </Typography>
      {/* ... more sections ... */}
      <Typography variant="h6" gutterBottom>
        6. Transport och leverans
      </Typography>
      <Typography variant="body1" paragraph>
        6.1
        Varor som finns i lager levereras normalt inom de antal arbetsdagar som anges på Hemsidan. Om inget särskilt har avtalats kommer leveransen att ske senast 30 arbetsdagar från det att PosterTopia skriftligen har bekräftat din beställning genom en orderbekräftelse.
      </Typography>
      {/* ... more sections ... */}
      <Typography variant="h6" gutterBottom>
        7. ÅNGERRÄTT
      </Typography>
      <Typography variant="body1" paragraph>
        7.1
        När du handlar varor på Hemsidan gäller en lagstadgad 14 dagars ångerrätt. Det innebär att du har rätt att ångra ditt köp förutsatt att du meddelar PosterTopia inom 14 dagar från den dag du eller ditt ombud mottog den beställda varan (ångerfristen).
      </Typography>
      {/* ... more sections ... */}
      <Typography variant="h6" gutterBottom>
        8. REKLAMATION OCH GARANTI
      </Typography>
      <Typography variant="body1" paragraph>
        8.1
        Du har en 3-årig reklamationsrätt på varor som du har köpt på Hemsidan. Reklamationsrätten täcker varor som är defekta enligt gällande konsumentlagstiftning. Om du har mottagit en skadad vara ska du kontakta PosterTopia så snart som möjligt efter att felet upptäckts genom att skicka bilder på både den/dem skadade varorna och det skadade paketet till info@postertopia.se.
      </Typography>
      {/* ... more sections ... */}
      <Typography variant="h6" gutterBottom>
        13. TVIST OCH TILLÄMPLIG LAG
      </Typography>
      <Typography variant="body1" paragraph>
        13.1
        Tvister ska i första hand lösas i samförstånd efter diskussion med PosterTopias kundtjänst.
      </Typography>
    </Container>
  );
}
