import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Typography, Grid, Divider, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconVisa from './static/svg/visa_landingpage.png';
import IconMastercard from './static/svg/mastercard_landing.png';
import IconSwish from './static/svg/swish_landing.png';
import IconKlarna from './static/svg/klarna_landing.png';
import { Link as RouterLink } from "react-router-dom"; 

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1f2029',
    padding: theme.spacing(4, 0),
    marginTop: "-10px",
  },
  IconsBottom: {
    marginTop: "16px",
    marginLeft: "8px",
    marginRight: "8px",
    marginBottom: "0px",
    width: "50px"
  },
  accordionButton: {
    color:"#ededed",
    backgroundColor: '#272833',  // replace 'YourDesiredColor' with your color code
    '&:hover': {
        backgroundColor: '#30313d',  // Optional: replace 'YourDesiredHoverColor' with a color code for hover state
    },
},
accordionDetails: {
  backgroundColor: '#a5aac2', // Replace 'YourDesiredColor' with your preferred color
  width: '100%',     // Ensures the container spans the full width
  padding: 0,        // Remove padding
},
  bottomIconContainer: {
    backgroundColor: '#1f2029',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clickableDiv: {
    textAlign: 'left', 
    cursor: 'pointer',
    color: 'blue', 
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  link: {
    
    textDecoration: 'none',
    color: 'inherit',
    fontSize: '20px',  // Increased font size for visibility
    display: 'block',  // Makes the link take up the full width of its parent
    width: '100%',     // Span the entire width of the parent
    padding: theme.spacing(1, 0),  // Added some padding for better appearance
    textAlign: 'left', // Align to the left
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',  // Divider between links
    },
    '&:focus': {
      outline: 'none',
    },
  },
  accordionDetails: {
    width: '100%',     // Ensures the container spans the full width
    padding: 0,        // Remove padding
  },
  list: {
    listStyleType: 'none',
  },
  linkSection: {
    width: '100%', // Make it span the entire width
  },
  
}));

const BottomSectionMobile = ({setOpenCookieBar,setCookieConsentGiven}) => {
  const classes = useStyles();

  const LinkSection = ({ title, links }) => (
    <Accordion fullWidth>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
        className={classes.accordionButton} 
      >
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
      <ul className={classes.list}>
        {links.map((link, index) => (
          <React.Fragment key={index}>
            {link.onClick ? (
              <div 
              className={classes.clickableDiv}
              onClick={()=>
              {link.onClick(false)}}>
                {link.name}
              </div>
            ) : (
              <RouterLink to={link.href}>
                {link.name}
              </RouterLink>
            )}
            {index !== links.length - 1 && <Divider />}  {/* Only add a divider if it's not the last link */}
          </React.Fragment>
        ))}
      </ul>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Divider style={{ marginBottom: "40px" }} />
        <Grid
        
        container spacing={3}>
          <Grid item xs={12} className={classes.linkSection}>
            <LinkSection 
              title="Om oss" 
              links={[
                { name: "Om oss", href: "/1-om-oss" },
                { name: "Posters", href: "/1-posters" }
              ]}
            />
          </Grid>
          <Grid item xs={12} className={classes.linkSection}>
            <LinkSection 
              title="Sociala media" 
              links={[
                { name: "Instagram", href: "https://www.instagram.com/postertopiase/" },
                { name: "Facebook", href: "https://www.facebook.com/people/PosterTopia/100092724335980/" },
                { name: "Twitter", href: "https://twitter.com/postertopia" }
              ]}
            />
          </Grid>
          <Grid item xs={12} className={classes.linkSection}>
            <LinkSection 
              title="Kundservice" 
              links={[
                { name: "Kontakta oss", href: "/1-kontakta-oss" },
                { name: "Vanliga frågor", href: "/1-vanliga-fragor" },
                { name: "Köpvillkor", href: "/1-kopvillkor" },
                { name: "Leverans", href: "/1-leverans" },
                { name: "Cookiepolicy", href: "/1-cookiepolicy" },
                { name: "Integritetspolicy", href: "/1-integritetspolicy" },
                { name: "Hantera cookies", onClick: setCookieConsentGiven  }
              ]}
            />
          </Grid>
        </Grid>
        <Divider style={{ marginTop: "40px",  backgroundColor: "#cfcaca" }} />
      </Container>
      <Container>
      <Typography style={{ marginTop: "25px", fontWeight: "bold", color: "white" }}>
          Copyright © 2023, PosterTopia AB
        </Typography>
        <div className={classes.bottomIconContainer}>
          <img src={IconVisa} className={classes.IconsBottom} alt="Visa" />
          <img src={IconMastercard} className={classes.IconsBottom} alt="Mastercard" />
          <img src={IconKlarna} className={classes.IconsBottom} alt="Klarna" />
          <img src={IconSwish} className={classes.IconsBottom} alt="Swish" />
        </div>
      </Container>
    </Box>
  );
};

export default BottomSectionMobile;
