import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Button, Typography, Paper, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@material-ui/core';
import { Send, Close } from '@material-ui/icons';
import SupportImage from './images/support_2.png';
import axios from 'axios';
import './styles/ChatBox.css'

const Typing = () => (
  <div className="typing">
    <div className="typing__dot"></div>
    <div className="typing__dot"></div>
    <div className="typing__dot"></div>
  </div>
);

const TypingMobile = () => (
  <div className="typing">
    <div className="typing__dot"></div>
    <div className="typing__dot"></div>
    <div className="typing__dot"></div>
  </div>
);


const ChatBox = ({ setOpenChat, isMobileView }) => {
  const [isTyping, setIsTyping] = useState(false);

  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);


  const [messages, setMessages] = useState([
    {
      sender: 'ai',
      text: 'Jag din AI Support🤖 för idag. Hur kan jag hjälpa dig? Välj ett alternativ eller skriv frågan nedan.',
      buttons: ['Jag vill ha poster tips', 'Vilken leveranstid har ni?','Vilka sorters posters erbjuder ni?'],
    },
  ]);

  const handleButtonClick = (option) => {
    setIsTyping(true);
    const userMessage = {
      sender: 'user',
      text: option,
    };
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, userMessage];
      handleSubmit(updatedMessages);
      return updatedMessages;
    });
    if (option === 'Vilken leveranstid har ni?') {
      setTimeout(() => {
        const aiMessage = {
          sender: 'ai',
          text: 'Leverans sker vanligtvis inom 1 till 3 arbetsdagar.', // replace this with your predefined response
        };
        setMessages((prevMessages) => [...prevMessages, aiMessage]);
        setIsTyping(false);
      }, 3000); // 2000 milliseconds is 2 seconds, you can adjust this as necessary
    }
    if (option === 'Vilka sorters posters erbjuder ni?') {
      setTimeout(() => {
        const aiMessage = {
          sender: 'ai',
          text: 'Vi har olika poster styles vi erbjuder som .', // replace this with your predefined response
        };
        setMessages((prevMessages) => [...prevMessages, aiMessage]);
        setIsTyping(false);
      }, 3000); // 2000 milliseconds is 2 seconds, you can adjust this as necessary
    }
    if (option === 'Jag vill ha poster tips') {
      setTimeout(() => {
        const aiMessage = {
          sender: 'ai',
          // text: 'Beskriv den stil eller motiv som du är ute efter. Du kan också beskriva ett rum så kan jag ge förslag.', // replace this with your predefined response
          text: 'Jag kommer inom kort kunna hjälpa kunder med detta.', // replace this with your predefined response
        };
        setMessages((prevMessages) => [...prevMessages, aiMessage]);
        setIsTyping(false);
      }, 3000); // 2000 milliseconds is 2 seconds, you can adjust this as necessary
    }
  };

  const handleSubmit = async (conversation) => {
    try {
      const response = await axios.post('http://127.0.0.1:8000/support', {
        conversation: conversation,
      });
      const aiResponse = response.data.response;
      const aiMessage = {
        sender: 'ai',
        text: aiResponse,
      };
      setMessages((prevMessages) => [...prevMessages, aiMessage]);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  const handleMessageSend = () => {
    if (!input.trim()) return;

    const userMessage = {
      sender: 'user',
      text: input,
    };

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, userMessage];
      handleSubmit(updatedMessages);
      return updatedMessages;
    });
    setInput('');
  };

  return (
    <Box
      style={{
        position: 'fixed',
        bottom: '10px',
        right: '10px',
        width: isMobileView ? '85vw' : '350px',
        background: 'white',
        borderRadius: '20px',
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)',
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" pt={1} pr={1}>
    <Box display="flex" alignItems="center">
        <Avatar src={SupportImage} style={{ width: '55px', height: '55px', margin: '5px', position: "absolute", left: "7px", top: "10px" }} />
        <Typography
          style={{
            color: 'black',
            marginBottom: '25px',
            fontSize: "20px",
            marginTop: "25px",
            position: "relative",
            left: "70px"
          }}
          variant="h5"
        >
          AI Support
        </Typography>
    </Box>
    <Button onClick={() => {
      setOpenChat(false)
    }} style={{ minWidth: 0, position: "absolute", top:"10px", right:"10px", borderRadius: "20px" }}>
      <Close />
    </Button>
</Box>

      <Paper elevation={3} style={{ height: isMobileView ? '40vh' : '400px', overflowY: 'auto', width: '100%' }}>
        <List>
        {messages.map((message, index) => (
  <ListItem key={index} style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
  <Box display="flex" alignItems="center" flexDirection={message.sender === 'ai' ? 'row' : 'row'}>
      <Box display="flex" alignItems="center">
        {message.sender === 'ai' && <img src={SupportImage} alt="AI Support" style={{ height: '34px', marginRight: '5px', position: "relative", top: "9px", marginTop: "-18px", left:"-10px" }} />}
        <Typography style={{ fontWeight: 'bold', maxWidth: '20px', position: "relative", left: message.sender === 'ai' ? "-11px": "-7px"  }} variant="body2">
          {message.sender === 'ai' ? 'AI' : 'Jag'}
        </Typography>
      </Box>

      <Box
        component={ListItemText}
        primary={message.text}
        style={{
          padding: '8px 12px',
          borderRadius: '12px',
          backgroundColor: message.sender === 'ai' ? '#3f51b5' : '#f1f1f1',
          color: message.sender === 'ai' ? '#ffffff' : '#000000',
          maxWidth: '80%',
          wordWrap: 'break-word',
        }}
      />
    </Box>
    {message.sender === 'ai' && message.buttons && (
      <Box mt={1}>
        {message.buttons.map((button, buttonIndex) => (
          <Button key={buttonIndex} onClick={() => handleButtonClick(button)} variant="outlined" color="primary" style={{ marginRight: '5px', borderRadius: "20px" }}>
            {button}
          </Button>
        ))}
      </Box>
    )}
  </ListItem>
))}


          
        </List>
        
        {isTyping && <div
        style={{
          position: "absolute",
          top: isMobileView?"78%":"82%",
          left: isMobileView?"72%":"68%",


        }}
        ><Typing /></div>}
        <div ref={messagesEndRef} />
        
      </Paper>
      
      <Box
        display="flex"
        alignItems="center"
        mt={1}
        width="100%"
        style={{
          zIndex: 10,
          marginTop: '-10px',
          marginLeft: "1px"
        }}
        px={2}
      >
        <TextField
          variant="outlined"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleMessageSend();
            }
          }}
          style={{ marginRight: '8px', flexGrow: 1, height: '65px' }}
          InputProps={{
            style: { height: '40px', position: 'relative', top: '19px', width: isMobileView?'90%':'100%', borderRadius: '14px' },
          }}
        />
        <Button variant="contained" color="primary" onClick={handleMessageSend} style={{ height: '40px', position: 'relative', top: '5px', borderRadius: '20px', width: isMobileView?'30px':'50px', flexGrow: 0, flexBasis: '50px' }}>
          <Send />
        </Button>
      </Box>
    </Box>
  );
}

export default ChatBox;
