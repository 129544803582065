import React, { useState } from 'react';
import { Button } from '@material-ui/core';


const sizes = [
  { name: '420 x 594 mm', id: '420x594' },
  { name: '420 x 420 mm', id: '420x420' },
  { name: '300 x 420 mm', id: '300x420' },
  { name: '300 x 300 mm', id: '300x300' },
  { name: '210 x 300 mm', id: '210x300' },
  { name: '210 x 210 mm', id: '210x210' },
];

const SizeFilter = (props) => {
  const [selectedSize, setSelectedSize] = useState('');

  const handleSizeClick = (sizeId) => {
    
    if (sizeId === selectedSize) {
      setSelectedSize('');  // If the current size is already selected, unselect it
      props.setSelectedSize('')
    } else {
      setSelectedSize(sizeId);  // If it's a different size, select the new one
      props.setSelectedSize(sizeId)
    }
    // Add your filtering logic here
  };

  return (
    <div>
      <h4>Storlek</h4>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '10px',
        }}
      >
        {sizes.map((size) => (
          <Button
            key={size.id}
            variant={props.selectedSize === size.id ? 'contained' : 'outlined'}
            color={props.selectedSize === size.id ? 'primary' : 'default'}
            onClick={() => handleSizeClick(size.id)}
          >
            {size.name}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default SizeFilter;
