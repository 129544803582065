import React from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '60vw',
    margin: '0 auto',
    [theme.breakpoints.down(600)]: {
      maxWidth: '100vw',
    },
  },
}));

export default function PosterInfo() {
    const classes = useStyles();

    return (
      <Container className={classes.root}>
        <Typography 
        style={{
                marginBottom: "50px",
              }} variant="h4" align="center" gutterBottom>
        Om Våra Posters
      </Typography>
      <Typography variant="body1" paragraph>
        Alla våra posters är skapade av begåvade svenska konstnärer som är passionerade om deras hantverk. Varje design är resultatet av otaliga timmar av hårt arbete och kreativitet, vilket ger en unik kvalitet och stil till varje poster.
      </Typography>
      <Typography variant="body1" paragraph>
        Kvaliteten på vår konst är endast lika bra som kvaliteten på dess medium. Därför använder vi oss av Canon Pro Premium Matt papper för alla våra tryck. Detta högkvalitativa papper är känt för sin förmåga att ge en jämn, matt finish som gör färger poppar utan att skapa bländning.
      </Typography>
      <Typography variant="body1" paragraph>
        Våra posters är inte bara konstverk, utan också en investering i hållbarhet. Canon Pro Premium Matt papper är slitstarkt och långvarigt, vilket säkerställer att din poster kommer att vara lika levande och imponerande om flera år som den är idag.
      </Typography>
      <Typography variant="body1" paragraph>
        Vidare tar vi på Postertopia stolthet i vår hängivenhet till miljön. Alla våra tryckningar sker i Stockholm, vilket minimerar transportavstånden och därmed minskar vårt koldioxidavtryck. Genom att välja oss, stödjer du en mer hållbar framtid.
      </Typography>
      <Typography variant="body1" paragraph>
        Vi hoppas att du kommer att älska våra posters lika mycket som vi älskar att skapa dem.
      </Typography>
    </Container>
  );
}
