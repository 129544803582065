// analytics.js

// This function initializes the Google Analytics script and tracking
export const initializeAnalytics = () => {
    // If the gtag.js script hasn't been added yet, add it
    if (!window.gtag) {
      const script = document.createElement('script');
      script.async = true;
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-Y0JGXXRQHD";
      document.head.appendChild(script);
  
      window.dataLayer = window.dataLayer || [];
      window.gtag = function() { window.dataLayer.push(arguments); };
      window.gtag('js', new Date());
      window.gtag('config', 'G-Y0JGXXRQHD');
    }
  };
  